import { BreakpointKey, Breakpoints, BreakpointsWithHelpers } from '../theme'

// Sorted ASC by size. That's important.
export const keys = ['xs', 'sm', 'md', 'lg', 'xl']
export const defaultValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

export const createBreakpoints = (
  options: Partial<Breakpoints>,
  unit,
): BreakpointsWithHelpers => {
  const {
    values = {},
    step = 1,
    mobileDown = 'sm',
    containerWidth = 1296,
  } = options

  const useValues = {
    ...defaultValues,
    ...values,
  }

  function up(key: BreakpointKey): string {
    const value = typeof key === 'number' ? key : useValues[key]

    return `@media (min-width:${value}px)`
  }

  function down(key: BreakpointKey): string {
    const value = typeof key === 'number' ? key : useValues[key]

    if (value === 0) {
      console.warn('Using max width 0 which will not apply to anything.')
      return `@media (max-width:0px)`
    }

    return `@media (max-width:${value - step / 100}px)`
  }

  function between(start: BreakpointKey, end: BreakpointKey): string {
    const endIndex = keys.indexOf(end as string)

    const startValue = typeof start === 'number' ? start : useValues[start]

    const endValue =
      typeof end === 'number' ? end : useValues[keys[endIndex]] - step / 100

    return (
      `@media (min-width:${startValue}px) and ` + `(max-width:${endValue}px)`
    )
  }

  function only(key: BreakpointKey): string {
    const keyIndex = keys.indexOf(key as string) + 1

    if (keyIndex < keys.length) {
      return between(key, keys[keyIndex] as BreakpointKey)
    }

    return up(key)
  }

  function mobile(): string {
    return down(mobileDown)
  }

  function width(key: BreakpointKey): number {
    return useValues[key]
  }

  return {
    keys,
    values: useValues,
    containerWidth,
    up,
    down,
    between,
    only,
    width,
    mobile,
  }
}
