import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { AllHTMLAttributes } from 'react'

export interface Props extends AllHTMLAttributes<any> {
  className?: string

  variant?:
    | 'body'
    | 'body2'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'caption'
    | 'inherit'

  component?: React.FunctionComponent<any> | string

  noGutters?: boolean

  gutters?: 'default' | 'top' | 'bottom'

  // transform?: 'default' | 'uppercase'

  fontWeight?: 'default' | 'light' | 'medium' | 'bold'

  color?:
    | 'default'
    | 'inherit'
    | 'text-primary'
    | 'primary'
    | 'secondary'
    | 'primary-dark'
    | 'error'
    | 'white'

  align?: 'default' | 'center'
}

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  body: 'p',
  body2: 'p',
  caption: 'p',
  inherit: 'span',
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontFamily: theme.typography.fontFamily,
    },

    h1: {
      color: theme.palette.text.heading,
      fontSize: theme.utils.getFontSize(36),
    },

    h2: {
      color: theme.palette.text.heading,
      fontSize: theme.utils.getFontSize(32),
    },

    h3: {
      color: theme.palette.text.heading,
      fontSize: theme.utils.getFontSize(28),
      lineHeight: theme.utils.getSize(32, { forceUnit: true }),
      fontWeight: theme.typography.fontWeightRegular,
    },

    h4: {
      color: theme.palette.text.heading,
      fontSize: theme.utils.getFontSize(20),
      lineHeight: theme.utils.getSize(22, { forceUnit: true }),
      fontWeight: theme.typography.fontWeightRegular,
    },

    h5: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.utils.getFontSize(16),
    },

    body: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.utils.getFontSize(16),
    },

    body2: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.utils.getFontSize(14),
    },

    caption: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.utils.getFontSize(12),
    },

    inherit: {},

    transformUppercase: {
      textTransform: 'uppercase',
    },

    noGutters: {
      margin: 0,
    },

    'gutters-bottom': {
      marginBottom: theme.spacing(),
    },

    'gutters-top': {
      marginTop: theme.spacing(),
    },

    'font-weight-default': {},

    'font-weight-light': {
      fontWeight: theme.typography.fontWeightLight,
    },

    'font-weight-medium': {
      fontWeight: theme.typography.fontWeightMedium,
    },

    'font-weight-bold': {
      fontWeight: theme.typography.fontWeightBold,
    },

    'color-inherit': {
      color: 'inherit',
    },

    'color-text-primary': {
      color: theme.palette.text.primary,
    },

    'color-primary': {
      color: theme.palette.primary.main,
    },

    'color-primary-dark': {
      color: theme.palette.primary.dark,
    },

    'color-secondary': {
      color: theme.palette.secondary.main,
    },

    'color-error': {
      color: theme.palette.error.main,
    },

    'color-white': {
      color: theme.palette.common.white,
    },

    'align-center': {
      textAlign: 'center',
    },
  }),
  { name: 'Typography' },
)

export const Typography: React.FC<Props> = ({
  children,
  className,
  variant = 'body',
  noGutters = false,
  fontWeight = 'default',
  align = 'default',
  color = 'default',
  gutters = 'default',
  component = null,
  ...rest
}) => {
  const classes = useStyles()

  const Component = component || variantMapping[variant]

  return (
    <Component
      className={clsx(
        classes.root,
        classes[variant],
        {
          [classes.noGutters]: noGutters,
          [classes[`font-weight-${String(fontWeight)}`]]:
            fontWeight !== 'default',
          [classes[`color-${String(color)}`]]: color !== 'default',
          [classes[`align-${String(align)}`]]: align !== 'default',
          [classes[`gutters-${String(gutters)}`]]: gutters !== 'default',
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Component>
  )
}

export default Typography
