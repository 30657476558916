import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@material-ui/styles'
import { createContext, Fragment, useMemo } from 'react'
import React from 'react'
import { IntlProvider } from 'react-intl'

import { Config, LocaleConfig } from './core.config.interface'
import { CssBaseline } from './core.css-baseline'
import { Features } from './core.features.interface'
import { getGraphqlClient } from './core.graphql'
import { Theme } from './theme'

export type Core = {
  theme: Theme
  config: Config
  localeConfig: LocaleConfig
  features: Features
  apiUrl: string
}

export const CoreContext = createContext<Core>({
  theme: null,
  config: null,
  localeConfig: null,
  features: null,
  apiUrl: '',
})

type Props = {
  children: JSX.Element
  core: Core
}

export const CoreProvider = ({ children, core }: Props) => {
  const apolloClient = useMemo(() => getGraphqlClient(core.config), [core])

  return (
    <CoreContext.Provider value={core}>
      <ApolloProvider client={apolloClient}>
        <IntlProvider
          key={core.localeConfig.locale}
          defaultLocale="en"
          locale={core.localeConfig.locale}
          messages={core.localeConfig.messages}
          textComponent={Fragment}
        >
          <ThemeProvider theme={core.theme}>
            <CssBaseline />

            {children}
          </ThemeProvider>
        </IntlProvider>
      </ApolloProvider>
    </CoreContext.Provider>
  )
}

export default CoreProvider
