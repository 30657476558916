import { gql } from '@apollo/client'

export const GET_PRODUCT_CATEGORIES = gql`
  query getProductCategories($skus: [String!], $locale: Locale!) {
    products(skus: $skus) {
      results {
        id
        masterData {
          current {
            categories {
              id
              name(locale: $locale)
              custom {
                customFieldsRaw(includeNames: ["isUpsellCategory"]) {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_RELATED_PRODUCTS_QUERY = gql`
  query getRelatedProducts(
    $where: String!
    $currency: Currency!
    $country: Country!
    $locale: Locale!
  ) {
    products(where: $where) {
      results {
        id
        masterData {
          current {
            nameAllLocales {
              locale
              value
            }
            categories {
              name(locale: $locale)
            }
            masterVariant {
              sku
              attributesRaw {
                name
                value
              }
              price(currency: $currency, country: $country) {
                value {
                  centAmount
                  currencyCode
                  fractionDigits
                  type
                }
                discounted {
                  value {
                    centAmount
                    currencyCode
                    fractionDigits
                    type
                  }
                }
              }
              assets {
                tags
                id
                nameAllLocales {
                  value
                }
                sources {
                  uri
                }
                custom {
                  customFieldsRaw(
                    includeNames: ["imagePosition"]
                  ) {
                    name
                    value
                  }
                }
              }
              images {
                url
              }
              availability {
                channels {
                  results {
                    channel {
                      id
                    }
                    availability {
                      availableQuantity
                      isOnStock
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
