import { createDebug } from '../core.debug'
import { aemAnalytics } from './core.aem.analytics'
import OldDataLayer from './core.old.datalayer'

export type Callback = (event: Event) => void

export interface Event {
  action: string

  /**
   * Default will be the events action
   */
  eventName?: string | number

  /**
   * Default will be the events action
   */
  eventAction?: string | number

  /**
   * Default will be the dataLayer.cartId
   */
  eventValue?: string | number

  // Allow everything
  [key: string]: unknown
}

export interface DataLayer {
  cartId: string
  store: string
  storeKey: string

  [key: string]: unknown
}

declare const window: {
  // New Analytics
  dtcDataLayerV2: Analytics

  // Old Analytics
  dtcDataLayer: {
    push: (event) => void
    listen: (listener) => void
  }

  addEventListener: (event: string, callback: () => void) => void
}

export class Analytics {
  public history: Event[] = []

  private queue: Event[] = []

  private listeners = []

  private log = createDebug('analytics')

  /**
   * Old datalayer from Uikit
   */
  private dtcDataLayer

  /**
   * Actual datalayer
   */
  private dataLayer: DataLayer = {
    cartId: null,
    store: null,
    storeKey: null,
  }

  constructor() {
    this.queue = (window.dtcDataLayerV2 || []) as Event[]

    // Register this class to window
    window.dtcDataLayerV2 = this
  }

  public init = (tracker, initialDataLayer: DataLayer) => {
    this.log('init', tracker)

    this.checkBackwardCompatibility()

    if (tracker === 'aem') {
      this.log('Enable %s tracker', tracker)
      this.addListener(aemAnalytics)
    }

    this.dataLayer = initialDataLayer

    this.executeQueue()
  }

  /**
   * Sets additional data in the datalayer
   */
  public setData = (data: { [key: string]: any }) => {
    // Merge the data together
    this.dataLayer = Object.assign(this.dataLayer, data)
  }

  public addListener(callback: Callback, skipHistory = false) {
    this.listeners.push(callback)

    // Send all history to it
    if (!skipHistory) {
      this.history.forEach((event) => callback(event))
    }
  }

  public push = (event: Event, oldEvent = false) => {
    let sendEvent = event

    if (!oldEvent) {
      sendEvent = {
        // Add the data from the data layer
        ...this.dataLayer,

        // The default eventValue is the cartId
        eventValue: this.dataLayer.cartId,
        eventName: event.action,
        eventAction: event.action,

        // Add the event date
        ...event,
        // Set that the event is send using the data layer v2
        __dtcDataLayerVersion: 2,
      }
    }

    this.emit(sendEvent, oldEvent)
    this.history.push(sendEvent)

    this.log('event send', sendEvent)
  }

  public pop = () => {
    this.history.pop()
  }

  /**
   * Execute the queue
   */
  private executeQueue = () => {
    if (this.queue && this.queue.length > 0) {
      const nextQueue = []

      this.log('execute queued items %s', nextQueue.length)
      // Emit all queued events
      this.queue.forEach((item) => {
        this.push(item)
      })

      // Requeue the track events
      this.queue = nextQueue
    }
  }

  private checkBackwardCompatibility() {
    this.log('Setup backward compatibility')
    if (!window.dtcDataLayer) {
      window.dtcDataLayer = new OldDataLayer()
      this.dtcDataLayer = window.dtcDataLayer
      this.addListenerToDtcDataLayer()

      // Wait for the dtcDataLayer to be defined
      window.addEventListener('dtcDataLayerLoaded', () => {
        this.dtcDataLayer = window.dtcDataLayer

        this.addListenerToDtcDataLayer()
      })
    } else {
      this.dtcDataLayer = window.dtcDataLayer

      this.addListenerToDtcDataLayer()
    }
  }

  private addListenerToDtcDataLayer = () => {
    // We add our push to the listen so when a event is fired on their side
    // we also send it
    this.dtcDataLayer.listen((event) => {
      // Only send it if the event comes from the old datalayer
      if (!event.__dtcDataLayerVersion) {
        this.push(event, true)
      }
    })
  }

  private emit = (event: Event, oldEvent: boolean): void => {
    this.listeners.forEach((callback) => callback(event))

    // Double check if the old dtcDatalayer exists
    if (this.dtcDataLayer && !oldEvent) {
      // Send the event
      this.dtcDataLayer.push(event)
    }
  }
}

export default new Analytics()
