import React from 'react'

import Icon, { Props } from '../icon'

export const CalendarIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 23 22" {...props}>
    <path
      d="M17.5,1.5 C18.0522847,1.5 18.5,1.94771525 18.5,2.5 L18.5,3 L21,3 C22.1045695,3 23,3.8954305 23,5 L23,20 C23,21.1045695 22.1045695,22 21,22 L3,22 C1.8954305,22 1,21.1045695 1,20 L1,5 C1,3.8954305 1.8954305,3 3,3 L6.5,3 L6.5,2.5 C6.5,1.94771525 6.94771525,1.5 7.5,1.5 C8.05228475,1.5 8.5,1.94771525 8.5,2.5 L8.5,3 L16.5,3 L16.5,2.5 C16.5,1.94771525 16.9477153,1.5 17.5,1.5 Z M20,10.5 L4,10.5 L4,19 L20,19 L20,10.5 Z M7.5,15.5 C8.05228475,15.5 8.5,15.9477153 8.5,16.5 C8.5,17.0522847 8.05228475,17.5 7.5,17.5 L6.5,17.5 C5.94771525,17.5 5.5,17.0522847 5.5,16.5 C5.5,15.9477153 5.94771525,15.5 6.5,15.5 L7.5,15.5 Z M12.5,15.5 C13.0522847,15.5 13.5,15.9477153 13.5,16.5 C13.5,17.0522847 13.0522847,17.5 12.5,17.5 L11.5,17.5 C10.9477153,17.5 10.5,17.0522847 10.5,16.5 C10.5,15.9477153 10.9477153,15.5 11.5,15.5 L12.5,15.5 Z M7.5,12.5 C8.05228475,12.5 8.5,12.9477153 8.5,13.5 C8.5,14.0522847 8.05228475,14.5 7.5,14.5 L6.5,14.5 C5.94771525,14.5 5.5,14.0522847 5.5,13.5 C5.5,12.9477153 5.94771525,12.5 6.5,12.5 L7.5,12.5 Z M12.5,12.5 C13.0522847,12.5 13.5,12.9477153 13.5,13.5 C13.5,14.0522847 13.0522847,14.5 12.5,14.5 L11.5,14.5 C10.9477153,14.5 10.5,14.0522847 10.5,13.5 C10.5,12.9477153 10.9477153,12.5 11.5,12.5 L12.5,12.5 Z M17.5,12.5 C18.0522847,12.5 18.5,12.9477153 18.5,13.5 C18.5,14.0522847 18.0522847,14.5 17.5,14.5 L16.5,14.5 C15.9477153,14.5 15.5,14.0522847 15.5,13.5 C15.5,12.9477153 15.9477153,12.5 16.5,12.5 L17.5,12.5 Z M20,6 L18.5,6 L18.5,6.62310563 C18.5,7.17539038 18.0522847,7.62310563 17.5,7.62310563 C16.9477153,7.62310563 16.5,7.17539038 16.5,6.62310563 L16.5,6 L8.5,6 L8.5,6.62310563 C8.5,7.17539038 8.05228475,7.62310563 7.5,7.62310563 C6.94771525,7.62310563 6.5,7.17539038 6.5,6.62310563 L6.5,6 L4,6 L4,8.5 L20,8.5 L20,6 Z"
      id="Combined-Shape"
    ></path>
  </Icon>
)

export default CalendarIcon
