import { StarIcon } from '@danone-global/internal/react/components/icons'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useState, useEffect } from 'react'

export interface Props {
  variant?: 'default' | 'small'
  rating: number
  className?: string
  onStarClick?: (index: number) => void
  disabled?: boolean
  starClasses?: string
  starActiveClasses?: string
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
    },

    button: {
      background: 'none',
      padding: 0,
      border: 0,
      margin: theme.spacing(0, 0.5, 0, 0),
      cursor: 'pointer',
    },

    star: {
      fill: '#c6c6c6',
    },

    starActive: {
      fill: '#fbb105',
    },

    clickable: {
      cursor: 'pointer',
    },
  }),
  { name: 'Rating' },
)

export const Rating: React.FC<Props> = ({
  variant = 'default',
  rating,
  className,
  onStarClick,
  disabled,
  starClasses = '',
  starActiveClasses = '',
}) => {
  const [count, setCount] = useState(rating)
  const [hovered, setHovered] = useState(0)
  const classes = useStyles()
  const handleOnClick = React.useCallback(
    (index) => () => {
      if (!disabled && onStarClick) {
        onStarClick(index)
        setCount(index)
        setHovered(0)
      }
    },
    [disabled, onStarClick],
  )
  useEffect(() => {
    if (rating !== undefined) {
      setCount(rating)
    }
  }, [rating])

  return (
    <div
      className={clsx('dtc-common-rating__root', classes.root, className)}
      data-testid={`rating-${variant}`}
      onMouseLeave={() => {
        setHovered(0)
      }}
    >
      {Array.from(new Array(5)).map((_, index) => (
        <button
          key={index}
          className={classes.button}
          onClick={handleOnClick(index + 1)}
          onMouseEnter={() => {
            setHovered(index + 1)
          }}
          type="button"
        >
          <StarIcon
            className={clsx(classes.star, starClasses, {
              [clsx(classes.starActive, starActiveClasses)]:
                !!onStarClick && hovered
                  ? hovered >= index + 1
                  : count >= index + 1,
              [classes.clickable]: !!onStarClick,
            })}
            data-testid={`rating-star-${index + 1}`}
            height={variant === 'default' ? 32 : 16}
            width={variant === 'default' ? 32 : 16}
          />
        </button>
      ))}
    </div>
  )
}

export default Rating
