import { MixinsWithHelpers, Spacing } from './'

export function createSpacing(
  utils: MixinsWithHelpers,
  spacingInput = 8,
): Spacing {
  return (...args): string => {
    if (args.length === 0) {
      return utils.getSize(spacingInput, { forceUnit: true }) as string
    }

    if (args.length === 1) {
      return utils.getSize(spacingInput * args[0], {
        forceUnit: true,
      }) as string
    }

    return args
      .map((argument) =>
        utils.getSize(argument * spacingInput, { forceUnit: true }),
      )
      .join(' ')
  }
}
