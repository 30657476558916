import React from 'react'

import Icon, { Props } from '../icon'

export const CheckIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 12 09" {...props}>
    <path
      d="m34.7879464 32.90625c.1785715 0 .3303572-.0625.4553572-.1875l.9107143-.9107143 4.8482142-4.8482143c.125-.125.1875-.2767857.1875-.4553571s-.0625-.3303572-.1875-.4553572l-.9107142-.9107142c-.125-.125-.2767858-.1875-.4553572-.1875s-.3303571.0625-.4553571.1875l-4.3928572 4.3995535-1.96875-1.9754464c-.125-.125-.2767857-.1875-.4553571-.1875s-.3303572.0625-.4553572.1875l-.9107142.9107143c-.125.125-.1875.2767857-.1875.4553571 0 .1785715.0625.3303572.1875.4553572l2.4241071 2.4241071.9107143.9107143c.125.125.2767857.1875.4553571.1875z"
      fill="inherit"
      transform="translate(-30 -24)"
    />
  </Icon>
)

export default CheckIcon
