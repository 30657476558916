import React from 'react'

import Icon, { Props } from '../icon'

export const TrashIcons: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 17 20" {...props}>
    <path
      d="M17,4 L17,2 C16.5572917,1.43055556 16.3422619,1.33333333 16.0555556,1 L12.2777778,1 L11.3333333,1 C11.421875,0.375 11.3080357,0.243055556 11.3333333,0 C11.0044643,0.0486111111 10.8400298,0 10.3888889,0 L6.61111111,0 C6.15997024,0 5.99553571,0.0486111111 5.66666667,0 C5.69196429,0.243055556 5.578125,0.375 5.66666667,1 L4.72222222,1 L0.944444444,1 C0.657738095,1.33333333 0.442708333,1.43055556 0,2 L0,4 L17,4 Z M14,20 C14.6458333,20 15.0885417,19.8177083 15,19 C15.8177083,19.0885417 16,18.6458333 16,18 L16,18 L16,5 C16,5.33854167 15.9544271,5.22786458 16,5 C15.7721354,5.04557292 15.6614583,5 16,5 L16,5 L1,5 C1.33854167,5 1.22786458,5.04557292 1,5 C1.04557292,5.22786458 1,5.33854167 1,5 L1,5 L1,18 C1,18.6458333 1.18229167,19.0885417 2,19 C1.91145833,19.8177083 2.35416667,20 3,20 L3,20 L14,20 Z M5,17 C4.35416667,17 4.234375,16.9414062 4,17 C4.046875,16.7070312 4,16.5572917 4,16 L4,16 L4,8 C4,7.44270833 4.046875,7.29296875 4,7 C4.234375,7.05859375 4.35416667,7 5,7 C4.64583333,7 4.765625,7.05859375 5,7 C4.953125,7.29296875 5,7.44270833 5,8 L5,8 L5,16 C5,16.5572917 4.953125,16.7070312 5,17 C4.765625,16.9414062 4.64583333,17 5,17 Z M9,17 C8.35416667,17 8.234375,16.9414062 8,17 C8.046875,16.7070312 8,16.5572917 8,16 L8,16 L8,8 C8,7.44270833 8.046875,7.29296875 8,7 C8.234375,7.05859375 8.35416667,7 9,7 C8.64583333,7 8.765625,7.05859375 9,7 C8.953125,7.29296875 9,7.44270833 9,8 L9,8 L9,16 C9,16.5572917 8.953125,16.7070312 9,17 C8.765625,16.9414062 8.64583333,17 9,17 Z M13,17 C12.3541667,17 12.234375,16.9414062 12,17 C12.046875,16.7070312 12,16.5572917 12,16 L12,16 L12,8 C12,7.44270833 12.046875,7.29296875 12,7 C12.234375,7.05859375 12.3541667,7 13,7 C12.6458333,7 12.765625,7.05859375 13,7 C12.953125,7.29296875 13,7.44270833 13,8 L13,8 L13,16 C13,16.5572917 12.953125,16.7070312 13,17 C12.765625,16.9414062 12.6458333,17 13,17 Z"
      fill="inherit"
      fillRule="nonzero"
    />
  </Icon>
)

export default TrashIcons
