import { TaxPortion as CtTaxPortion } from '@danone-global/ct/interfaces'
import Price from '@danone-global/internal/react/components/price'
import { Grid, Typography } from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: theme.spacing(2, 0),
    },

    value: {
      paddingRight: theme.spacing(2),
    },
  }),
  { name: 'TaxPortion' },
)

export const TaxPortion: React.FC<CtTaxPortion> = ({ name, rate, amount }) => {
  const classes = useStyles()

  // Dont render if the amount is 0
  if (amount.centAmount === 0) {
    return null
  }

  return (
    <Grid className={classes.root} direction="row" flex>
      <Grid flex>
        <Typography color="primary-dark" variant="body2" noGutters>
          <FormattedMessage
            defaultMessage="Incl. {rate}% tax"
            id="my-order.tax-portion"
            values={{
              rate: parseFloat((rate * 100).toFixed(2)),
            }}
          />
        </Typography>
      </Grid>

      <Grid className={classes.value}>
        <Typography
          color="primary-dark"
          component="span"
          variant="body2"
          noGutters
        >
          <Price
            data-testid={`my-order.tax-portion.${name}.price`}
            inherit
            {...amount}
          />
        </Typography>
      </Grid>
    </Grid>
  )
}

export default TaxPortion
