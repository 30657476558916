import {
  MinIcon,
  PlusIcon,
} from '@danone-global/internal/react/components/icons'
import {
  Grid,
  IconButton,
  Typography,
} from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const FieldsStepper = ({
  value,
  onIncrease,
  onDecrease,
  ...rest
}: {
  value: number
  onDecrease: () => void
  onIncrease: () => void
  'data-testid': string
}) => {
  const classes = useStyles()

  return (
    <Grid
      alignItems="center"
      data-testid={rest['data-testid']}
      justify="center"
      flex
    >
      <IconButton
        className={classes.control}
        color="white"
        data-testid={`btn.${rest['data-testid']}.decrease`}
        onClick={onDecrease}
        variant="brand"
      >
        <MinIcon height={16} width={16} />
      </IconButton>

      <Typography
        align="center"
        className={classes.label}
        data-testid={`${rest['data-testid']}.amount`}
        noGutters
      >
        {value}
      </Typography>

      <IconButton
        className={classes.control}
        color="white"
        data-testid={`btn.${rest['data-testid']}.increase`}
        onClick={onIncrease}
        variant="brand"
      >
        <PlusIcon height={16} width={16} />
      </IconButton>
    </Grid>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    label: {
      minWidth: theme.utils.getSize(40),
    },

    control: {},
  }),
  { name: 'FieldsStepper' },
)

export default FieldsStepper
