import React from 'react'

import Icon, { Props } from '../icon'

export const PlusIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 15 15" {...props}>
    <path
      d="M6 6V1.5a1.5 1.5 0 0 1 3 0V6h4.5a1.5 1.5 0 0 1 0 3H9v4.5a1.5 1.5 0 0 1-3 0V9H1.5a1.5 1.5 0 0 1 0-3H6z"
      fill="inherit"
      fillRule="evenodd"
    />
  </Icon>
)

export default PlusIcon
