import {
  FieldsInput,
  validations,
} from '@danone-global/internal/react/components/fields'
import {
  MinIcon,
  PlusIcon,
} from '@danone-global/internal/react/components/icons'
import {
  Notification,
  Typography,
} from '@danone-global/internal/react/components/ui'
import {
  getCustomValue,
  Theme,
  useCore,
  useIsBelowBreakpoint,
} from '@danone-global/internal/react/core'
import { localisedProductName } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

export const createInscriptionIds = (quantity: number, lineItemId: string) =>
  new Array(quantity)
    .fill(0)
    .map((_, index) => `${lineItemId}-inscription-${index}`)

/* istanbul ignore next */
export const createPatientIds = (quantity: number, lineItemId: string) =>
  new Array(quantity)
    .fill(0)
    .map((_, index) => `${lineItemId}-patient-${index}`)

export const InscriptionInputs = ({ lineItem, sku }) => {
  const { localeConfig } = useCore()
  const productName = localisedProductName(lineItem, localeConfig.locale)
  const formContext = useFormContext()
  const classes = useStyles()
  const intl = useIntl()

  const inscriptionTextMaxLength: number =
    getCustomValue(
      lineItem.variant?.attributesRaw,
      'inscriptionTextEnabled',
    ) === true
      ? getCustomValue(
        lineItem.variant?.attributesRaw,
        'inscriptionTextMaxLength',
      ) ?? 0
      : 0

  // Don't show inputs if inscriptions are disabled
  if (!inscriptionTextMaxLength || inscriptionTextMaxLength < 1) {
    return null
  }

  const inscriptionIds = createInscriptionIds(lineItem.quantity, lineItem.id)

  return (
    <div className={classes.inscriptionInputs}>
      <Typography className={classes.inscriptionInputsTitle} type="body">
        <FormattedMessage
          defaultMessage="Add your custom inscriptions below (max. {amount} digits)"
          id="line-item.add-inscription-max-digits"
          values={{ amount: inscriptionTextMaxLength }}
        />
      </Typography>

      {inscriptionIds.map((inscriptionId, index, list) => {
        return (
          <div key={inscriptionId}>
            <FieldsInput
              className={classes.inscriptionInputsLabel}
              control={formContext.control}
              label={`${productName} ${list.length > 1 ? index + 1 : ''}`}
              maxLength={inscriptionTextMaxLength}
              name={inscriptionId}
              placeholder={intl.formatMessage({
                id: 'line-item.add-inscription-placeholder',
                defaultMessage: 'Inscription',
              })}
              validate={validations.containsEmoji}
              required
            />
          </div>
        )
      })}

      {Object.keys(formContext?.formState.errors).some((inscriptionId) =>
        inscriptionIds.includes(inscriptionId),
      ) ? (
        <Notification
          className={clsx(classes.errorNotification, classes.inscriptionError)}
          indicator="top-left"
          message={intl.formatMessage({
            id: 'line-item.inscription-general-error',
            defaultMessage:
              'Please fill in all inscriptions so you can proceed to checkout',
          })}
          testId={`line-item-${sku}.inscription-error`}
          error
        />
      ) : undefined}
    </div>
  )
}

/* istanbul ignore next */
export const PatientInputs = ({ lineItem, sku }) => {
  const formContext = useFormContext()
  const classes = useStyles()
  const intl = useIntl()

  const patientIds = createPatientIds(lineItem.quantity, lineItem.id)
  return (
    <div className={classes.patientInputs}>
      <div key={patientIds[0]}>
        <FieldsInput
          className={classes.inscriptionInputsLabel}
          control={formContext?.control}
          label={intl.formatMessage({
            id: 'line-item.patientname',
            defaultMessage: `Patient name`,
          })}
          name={patientIds[0]}
          placeholder={intl.formatMessage({
            id: 'line-item.add-patientnotes-placeholder',
            defaultMessage: 'Patient name',
          })}
          maxLength={30}
          validate={validations.containsEmoji}
        />
      </div>
    </div>
  )
}

export const InscriptionSummary = ({ lineItem }) => {
  const classes = useStyles()
  const isBelowLgBreakpoint = useIsBelowBreakpoint('lg')

  const lineItemInscriptions = JSON.parse(
    lineItem.custom?.customFieldsRaw?.find((c) => c.name === 'inscriptionText')
      ?.value || JSON.stringify([]),
  ).filter(Boolean)

  const [expand, setExpand] = useState(false)

  if (!lineItemInscriptions.length) {
    return null
  }

  const showExpandButton =
    !isBelowLgBreakpoint && lineItemInscriptions.length > 3

  return (
    <div className={classes.inscriptionsSummary}>
      {showExpandButton ? (
        <Typography
          className={clsx(
            classes.inscriptionsSummaryTitle,
            classes.inscriptionsSummaryTitleButton,
            !expand && classes.inscriptionsSummaryTitleButtonExpand,
          )}
          data-testid="btn.line-item.inscriptions-expand"
          onClick={() => setExpand(!expand)}
          variant="h5"
        >
          {expand ? (
            <>
              <MinIcon />
              <FormattedMessage
                defaultMessage="Hide inscriptions"
                id="btn.line-item.inscriptions-hide"
              />
            </>
          ) : (
            <>
              <PlusIcon />
              <FormattedMessage
                defaultMessage="View inscriptions"
                id="btn.line-item.inscriptions-view"
              />
            </>
          )}
        </Typography>
      ) : (
        <Typography className={classes.inscriptionsSummaryTitle} variant="h5">
          <FormattedMessage
            defaultMessage="Inscriptions"
            id="btn.line-item.inscriptions"
          />
          :
        </Typography>
      )}

      {isBelowLgBreakpoint || !showExpandButton || expand
        ? lineItemInscriptions?.map((i, index, list) => (
          <Typography
            key={index}
            className={classes.inscriptionsSummaryItem}
            variant="body"
          >
            {list.length > 1 ? `${index + 1}.` : undefined} {i}
          </Typography>
        ))
        : undefined}
    </div>
  )
}

export const PatientNameNotesSummary = ({ lineItem }) => {
  const classes = useStyles()

  let lineItemPatientNames = JSON.parse(
    lineItem.custom?.customFieldsRaw?.find((c) => c.name === 'patientName')
      ?.value || JSON.stringify([]),
  ).filter(Boolean)

 lineItemPatientNames=[lineItemPatientNames[0]]

  if (!lineItemPatientNames.length) {
    return null
  }

  return (
    <div>
      {lineItemPatientNames?.map((i, index, list) => (
        <Typography
          key={index}
          className={classes.patientText}
        >
          <FormattedMessage
            defaultMessage="Patient name"
            id="line-item.patientName"
          />
          {list.length > 1 ? ` ${index + 1}` : undefined}: {i}
        </Typography>
      ))
      }
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    errorNotification: {
      marginTop: theme.spacing(2),
      marginBottom: 0,
      padding: theme.spacing(1, 1.5),
    },
    inscriptionInputs: {
      '& $inscriptionError p': {
        color: theme.palette.warning.main,
      },

      [theme.breakpoints.up('md')]: {
        flex: '0 0 85%',
        marginTop: theme.spacing(1),
        marginLeft: 80,
      },
    },

    patientInputs: {
      '& $inscriptionError p': {
        color: theme.palette.warning.main,
      },

      [theme.breakpoints.up('md')]: {
        flex: '0 0 85%',
        marginTop: theme.spacing(1),
        marginLeft: 95,
      },
    },

    patientText: {
      fontSize: 14,
      color: '#4C4C4C !important',
      margin: '5px 0',
      wordBreak: 'break-all',
      lineHeight: '20px',
    },

    inscriptionInputsTitle: {},

    inscriptionInputsLabel: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },

    inscriptionsSummary: {
      position: 'relative',
      background: theme.palette.primary.dark,
      borderRadius: theme.shape.borderRadius(),
      padding: theme.spacing(2),
      width: '100%',
      wordWrap: 'break-word',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: -11,
        left: 30,
        border: theme.utils.createStyle('solid', theme.palette.primary.dark),
        borderWidth: theme.utils.createStyle('1px', '1px', 0, 0),
        width: 15,
        height: 15,
        transform: 'rotate(-45deg) translate(-50%)',
        backgroundColor: theme.palette.primary.dark,
        borderRadius: 3,
      },

      '& p$inscriptionsSummaryItem': {
        color: 'white',
        margin: 0,

        '&:not(:last-child)': {
          marginBottom: theme.spacing(0.5),
        },
      },

      [theme.breakpoints.up('md')]: {
        background: 'white',

        '&::before': {
          borderColor: 'white',
          backgroundColor: 'white',
        },

        '& p$inscriptionsSummaryItem': {
          color: theme.palette.text.primary,
        },
      },
    },
    inscriptionsSummaryTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(),
      color: 'white',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        fill: 'white',
        width: 15,
        height: 15,
        marginRight: theme.spacing(),
      },

      [theme.breakpoints.up('md')]: {
        color: theme.palette.primary.dark,
      },
    },
    inscriptionsSummaryTitleButton: {
      cursor: 'pointer',
    },
    inscriptionsSummaryTitleButtonExpand: {
      marginBottom: 0,
    },
    inscriptionsSummaryItem: {},
    inscriptionError: {},
  }),
  { name: 'LineItemInscriptions' },
)
