import Rating from '@danone-global/internal/react/components/rating'
import { Typography } from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { Control, useController } from 'react-hook-form'

import FieldsError from '../error'

export interface Props {
  name: string
  label?: string
  control: Control<any>
  required?: boolean
  placeholder?: string
  className?: string
  defaultValue?: string
  disabled?: boolean
  starClasses?: string
  starActiveClasses?: string
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      marginBottom: theme.spacing(2),

      '& label': {
        display: 'block',
        marginBottom: theme.spacing(0.5),
      },
    },

    input: {
      position: 'relative',
      width: '100%',
      height: 'auto',
      minWidth: 'auto',
      border: '1px solid rgba(151, 151, 152, 0.25)',
      padding: theme.spacing(),
      fontSize: theme.utils.getSize(16),
      fontWeight: theme.typography.fontWeightLight,
      fontFamily: theme.typography.fontFamily,
      outline: 'none',
      transition: 'all 200ms ease-out',
      borderRadius: theme.shape.borderRadius(0.5),

      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },

    invalid: {
      borderColor: theme.palette.error.main,
    },

    valid: {},
  }),
  { name: 'FieldsRating' },
)

export const FieldsRating: React.FC<Props> = ({
  name,
  label,
  control,
  required = false,
  className,
  defaultValue,
  disabled,
  starClasses = '',
  starActiveClasses = '',
}) => {
  const classes = useStyles()
  const { field: input, fieldState } = useController({
    name,
    control,
    rules: {
      required,
    },
    defaultValue,
  })

  const handleRatingChange = React.useCallback(
    (amount) => {
      input.onChange(amount)
    },
    [input],
  )

  return (
    <div className={clsx(classes.root, className)}>
      {label && (
        <label htmlFor={name}>
          <Typography component="span" variant="h5">
            {label}
            {required && <span> *</span>}
          </Typography>
        </label>
      )}

      <div>
        <Rating
          disabled={disabled}
          onStarClick={handleRatingChange}
          rating={input.value || 0}
          starActiveClasses={starActiveClasses}
          starClasses={starClasses}
        />
      </div>

      {fieldState.error && <FieldsError error={fieldState.error} />}
    </div>
  )
}

export default FieldsRating
