import { gql } from '@apollo/client'

export const GET_SHIPPING_METHODS = gql`
  query getShippingMethods($cartId: String!, $locale: Locale!) {
    shippingMethodsByCart(id: $cartId) {
      id
      name
      localizedDescription(locale: $locale)
      zoneRates {
        shippingRates {
          isMatching
          freeAbove {
            fractionDigits
            centAmount
            currencyCode
          }
          price {
            fractionDigits
            centAmount
            currencyCode
          }
        }
      }
    }
  }
`
