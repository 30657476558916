import type { Widget } from '@danone-global/internal/react/core'
import React from 'react'

export default {
  tags: ['reviews', 'reviews-v2'],

  developmentProps: {
    sku: 'FOM01',
  },

  Component: React.lazy(() => import('./reviews.widget')),
} as Widget
