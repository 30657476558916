// Because of course you can't just add sharable utilities easily

import { Maybe, ShippingInfo } from '@danone-global/ct/interfaces'

export const checkIsShippingFree = (
  shippingInfo: Maybe<ShippingInfo> | undefined,
) =>
  shippingInfo
    ? shippingInfo?.taxedPrice?.totalGross.centAmount === 0 &&
      (!shippingInfo.discountedPrice ||
        shippingInfo.discountedPrice.value.centAmount === 0)
    : false
