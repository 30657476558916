import React from 'react'

import { Props } from '../icon'
import { ThumbDownIcon } from './thumb-down.icon'

export const ThumbUpIcon: React.FC<Omit<Props, 'viewBox'>> = ({
  style = {},
  ...props
}) => (
  <ThumbDownIcon
    style={{
      ...style,
      transform: 'scaleY(-1) translateY(2px)',
    }}
    {...props}
  />
)

export default ThumbUpIcon
