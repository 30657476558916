import { gql } from '@apollo/client'

export const discountLineItemPriceFragment = gql`
  fragment discount on DiscountedLineItemPrice {
    value {
      ...money
    }
    includedDiscounts {
      discount {
        id
        isActive
        sortOrder
        nameAllLocales {
          locale
          value
        }
      }
      discountedAmount {
        centAmount
      }
    }
  }
`
