import React from 'react'

import Icon, { Props } from '../icon'

export const ArrowLeftIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 13 23" {...props}>
    <g
      fill="inherit"
      fillRule="nonzero"
      transform="translate(6.218754, 11.335002) scale(-1, 1) translate(-6.218754, -11.335002) translate(-0.000000, -0.000000)"
    >
      <path d="M2.59090175,0.470469113 L12.0284018,10.4704691 C12.3169247,10.7761887 12.4519684,11.1712207 12.4362842,11.5604975 C12.4502101,11.9584259 12.3065021,12.3606614 12.0048588,12.6661491 L2.56735879,22.2239269 C1.98529321,22.8134107 1.03556478,22.8194244 0.44608097,22.2373588 C-0.14340284,21.6552932 -0.149416505,20.7055648 0.432649075,20.116081 L8.907,11.534 L0.40910611,2.52953875 C-0.15949027,1.92705252 -0.132017118,0.97770249 0.470469113,0.40910611 C1.07295534,-0.15949027 2.02230537,-0.132017118 2.59090175,0.470469113 Z" />
    </g>
  </Icon>
)

export default ArrowLeftIcon
