import { Cart } from '@danone-global/ct/interfaces'
import {
  Button,
  Container,
  Grid,
  Hr,
  Typography,
} from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import CheckoutLineItems from './line-items'
import PriceSummary from './price-summary'
import ViewProducts from './view-products'

export interface Props {
  shoppingCartUrl: string
  hideActions?: boolean
  onEditOrderClick?: () => void
  cart: Cart
  showTaxes?: boolean
}

export const CheckoutOrder: React.FC<Props> = ({
  shoppingCartUrl,
  onEditOrderClick,
  hideActions,
  cart,
  showTaxes,
}) => {
  const [viewingProducts, setViewingProducts] = React.useState(false)
  const classes = useStyles()

  const handleEditOrder = () => {
    if (onEditOrderClick) {
      onEditOrderClick()
    } else {
      window.location.href = shoppingCartUrl
    }
  }

  const toggleViewProducts = React.useCallback(() => {
    setViewingProducts((viewingProducts) => !viewingProducts)
  }, [])

  return (
    <>
      <Container className={classes.root} direction="column">
        <Grid justify="space-between" flex>
          <Typography variant="h4" noGutters>
            <FormattedMessage
              defaultMessage="My Order"
              id="widgets.checkout.order.title"
            />
          </Typography>

          {!hideActions ? (
            <>
              <Button
                className={classes.editOrder}
                data-testid="btn.edit-order"
                onClick={handleEditOrder}
                small
              >
                <FormattedMessage
                  defaultMessage="Edit order"
                  id="btn.checkout.edit-order"
                />
              </Button>
              <Button
                className={classes.viewProducts}
                data-testid="btn.view-products"
                onClick={toggleViewProducts}
                small
              >
                <FormattedMessage
                  defaultMessage="View products"
                  id="btn.checkout.view-products"
                />
              </Button>
            </>
          ) : undefined}
        </Grid>
        <CheckoutLineItems cart={cart} />
        <Hr />
        {cart ? (
          <PriceSummary
            cart={cart}
            className={classes.totalPrice}
            showTaxes={showTaxes}
            valueClassName={classes.value}
          />
        ) : undefined}
      </Container>

      {viewingProducts ? (
        <ViewProducts close={toggleViewProducts} editOrder={handleEditOrder} />
      ) : undefined}
    </>
  )
}

export default CheckoutOrder

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.secondary,
      border: 'none',

      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 0, 2, 0),
      },
    },

    value: {
      paddingRight: theme.spacing(2),
    },

    editOrder: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    viewProducts: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },

    totalPrice: {},
  }),
  { name: 'CheckoutOrder' },
)
