import React from 'react'

import Icon, { Props } from '../icon'

export const VerifiedIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 382 381" {...props}>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="certified"
        transform="translate(0.148400, 0.146200)"
      >
        <path
          d="M190.8528,2.84217094e-14 L227.7978,34.578 C230.165,36.9452 233.4814,37.89051 236.3212,36.9452 L286.0482,25.5782 L300.7282,74.3552 C301.6735,77.195 304.0446,79.56609 306.8844,80.5114 L355.6614,95.1914 L344.2944,144.9184 C343.8218,147.7582 344.2944,151.0746 346.6616,153.4418 L381.2316,190.8558 L346.6619,227.79364 C344.76738,230.16084 343.82207,233.47724 344.2791,235.84054 L355.6461,285.56754 L306.8691,300.24754 C304.0293,301.19284 301.65821,303.56394 300.7129,306.40374 L286.0329,355.18074 L236.3059,343.81374 C233.4661,343.34114 230.1497,343.81374 227.7825,346.18094 L190.8445,380.75094 L153.9065,346.18094 C152.012,344.75905 149.6448,343.81374 147.2776,343.81374 C146.3323,343.81374 145.38306,344.2864 144.9104,344.2864 L95.1834,355.6534 L80.5034,306.8764 C79.5581,304.0366 77.187,301.66551 74.3472,300.7202 L25.5702,286.0402 L36.9372,236.3132 C37.4098,233.4734 36.9372,230.157 34.57,227.7898 L2.84217094e-14,190.8518 L35.051,153.4338 C37.4182,151.0666 38.36351,148.2268 37.4182,144.9104 L26.0512,95.1834 L74.8282,80.5034 C77.668,79.5581 80.03909,77.187 80.9844,74.3472 L95.6644,25.5702 L145.3914,36.9372 C148.2312,37.4098 151.5476,36.9372 153.9148,34.57 L190.8528,2.84217094e-14 Z M287.4698,122.6578 C283.6805,118.8687 277.9969,118.8687 274.2078,122.6578 L157.7078,239.1578 L107.5128,188.9588 C103.7235,185.1697 98.0399,185.1697 94.2508,188.9588 C90.4617,192.7481 90.4617,198.4317 94.2508,202.2208 L151.0788,259.0488 C152.9733,260.94329 155.3405,261.8886 157.7077,261.8886 C160.0749,261.8886 162.4421,260.9394 164.3366,259.0488 L287.4698,135.9158 C291.2589,132.1266 291.2589,126.443 287.4698,122.6578 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </Icon>
)

export default VerifiedIcon
