import { Grid, Typography } from '@danone-global/internal/react/components/ui'
import { Theme, useCart } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: theme.spacing(2, 0),
      paddingRight: theme.spacing(2),
    },
  }),
  { name: 'TaxDisclaimer' },
)

export const TaxDisclaimer: React.FC = () => {
  const [cart] = useCart()
  const classes = useStyles()

  if (cart?.country !== 'CN') {
    return null
  }

  return (
    <Grid className={classes.root} direction="row" flex>
      <Grid flex>
        <Typography color="primary-dark" variant="body2" noGutters>
          <FormattedMessage
            defaultMessage="Import taxes may apply later."
            id="my-order.tax-disclaimer"
          />
        </Typography>
      </Grid>
    </Grid>
  )
}

export default TaxDisclaimer
