import React from 'react'

import Icon, { Props } from '../icon'

export const CartIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M21.5 11.9L23 5.2c.1-.3 0-.6-.2-.8-.2-.3-.5-.4-.8-.4H4.4L4 1.8c-.1-.5-.5-.8-1-.8H1c-.6 0-1 .4-1 1s.4 1 1 1h1.2L5 18.2c.1.5.5.8 1 .8h14c.6 0 1-.4 1-1s-.4-1-1-1H6.8l-.4-2h11.1c2 0 3.6-1.3 4-3.1zM4.8 6h16l-1.2 5.4c-.2.9-1 1.6-2 1.6H6.1L4.8 6z" />
    <circle className="st0" cx="8" cy="22" r="2" />
    <circle className="st0" cx="19" cy="22" r="2" />
  </Icon>
)

export default CartIcon
