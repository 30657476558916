import { CartUpdateAction, ShippingMethod } from '@danone-global/ct/interfaces'
import Price from '@danone-global/internal/react/components/price'
import { Grid, Typography } from '@danone-global/internal/react/components/ui'
import {
  checkIsShippingFree,
  Theme,
  UPDATE_ACTIVE_CART,
  useCart,
  useMutation,
} from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useState } from 'react'

import { useCorrectDeliveryName } from './delivery-method.hooks'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: theme.shape.borderRadius(),
      border: theme.utils.createStyle(
        theme.utils.getSize(1, { forceUnit: true }),
        'solid',
        '#eaeaea',
      ),
      marginTop: theme.spacing(),
      overflow: 'hidden',
      cursor: 'pointer',
    },

    active: {
      borderColor: theme.palette.primary.main,

      '& $icon': {
        borderColor: theme.palette.primary.main,

        '&::after': {
          position: 'absolute',
          content: '""',
          top: '50%',
          left: '50%',
          display: 'block',
          width: theme.utils.getSize(9),
          height: theme.utils.getSize(9),
          borderRadius: '100%',
          backgroundColor: theme.palette.primary.main,
          transform: 'translate(-50%, -50%)',
        },
      },
    },

    header: {
      position: 'relative',
      padding: theme.spacing(2),

      '& p': {
        flex: 1,
      },
    },

    name: {
      marginRight: theme.spacing(),
    },

    icon: {
      position: 'relative',
      marginTop: theme.utils.getSize(-4),
      marginRight: theme.spacing(),
      content: '""',
      display: 'block',
      width: theme.utils.getSize(16),
      height: theme.utils.getSize(16),
      borderRadius: '100%',
      backgroundColor: theme.utils.hexToRgba(theme.palette.primary.main, 0.1),
      border: theme.utils.createStyle(
        theme.utils.getSize(1, { forceUnit: true }),
        'solid',
        theme.utils.hexToRgba(theme.palette.primary.main, 0.15),
      ),
    },

    description: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0, 2, 2, 2),
    },
  }),
  { name: 'DeliveryMethod' },
)

export const DeliveryMethod: React.FC<ShippingMethod> = ({
  id,
  name: shippingMethodName,
  zoneRates,
  localizedDescription,
}) => {
  const [updateCart] = useMutation(UPDATE_ACTIVE_CART)
  const [cart] = useCart()
  const name = useCorrectDeliveryName(shippingMethodName)
  const classes = useStyles()

  const handleSelectShippingMethod = React.useCallback(async () => {
    if (cart?.shippingInfo?.shippingMethod?.id === id) {
      return
    }

    const setShippingMethodAction: CartUpdateAction = {
      setShippingMethod: {
        shippingMethod: {
          id,
        },
      },
    }

    await updateCart({
      variables: {
        actions: [setShippingMethodAction],
      },
    })
  }, [id, cart?.shippingInfo])

  const shippingRate = React.useMemo(() => {
    for (const zoneRate of zoneRates) {
      const match = zoneRate.shippingRates.find(
        (shippingRate) =>
          shippingRate.isMatching &&
          shippingRate.price.currencyCode ===
            cart?.taxedPrice?.totalGross.currencyCode,
      )

      if (match) {
        const isFreeShipping = checkIsShippingFree(cart?.shippingInfo)

        return isFreeShipping
          ? {
              ...match,
              price: { ...match.price, centAmount: 0, fractionDigits: 0 },
            }
          : match
      }
    }
    return null
  }, [zoneRates, cart?.taxedPrice])

  return (
    <Grid
      className={clsx(classes.root, {
        [classes.active]: id === cart?.shippingInfo?.shippingMethod?.id,
      })}
      data-testid={`btn.select-shipping-method.${id}`}
      onClick={handleSelectShippingMethod}
    >
      <Grid alignItems="center" className={classes.header} flex>
        <span className={classes.icon} />

        <Typography
          className={classes.name}
          component="p"
          variant="h5"
          noGutters
        >
          {name}
        </Typography>
        {shippingRate && <Price showFreeLabel small {...shippingRate.price} />}
      </Grid>

      <Grid className={classes.description}>
        <Typography color="text-primary" noGutters>
          {localizedDescription}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default DeliveryMethod
