import { useLazyQuery } from '@apollo/client'
import { Cart, LineItem, Query } from '@danone-global/ct/interfaces'
import { GET_PRODUCT_CATEGORIES } from '@danone-global/internal/graphql'
import {
  analytics,
  ANALYTICS_CATEGORY_LIMIT,
  GOODIES_PRODUCT_TYPE,
  isValue,
  limitArrayCharacters,
  UPDATE_ACTIVE_CART,
  useCart,
  useCore,
  useMutation,
} from '@danone-global/internal/react/core'
import { useChannelId } from '@danone-global/internal/react/modules/stock'

/**
 * Checks where the product of a line item is available for subscriptions
 */
export const isProductAvailableForSubscription = (lineItem: LineItem) =>
  lineItem.variant.attributesRaw.some(
    (attribute) =>
      attribute.name === 'availableForSubscription' && attribute.value,
  )

export const useUpdateQuantity = ({
  lineItem,
  withAnalytics,
  setCartError,
}: {
  lineItem?: LineItem
  withAnalytics: boolean
  setCartError?: (error: string) => void
}) => {
  const [updateCart] = useMutation(UPDATE_ACTIVE_CART)

  const defaultChannelId = useChannelId()
  const priceChannelId = lineItem?.distributionChannel?.id || defaultChannelId
  const stockChannelId = lineItem?.supplyChannel?.id || defaultChannelId
  const { config, localeConfig } = useCore()
  const [cart] = useCart()

  const [getProductCategories] = useLazyQuery<Query>(GET_PRODUCT_CATEGORIES)

  return async (quantity) => {
    if (!lineItem) {
      return
    }
    const { data: productCategoryData } = await getProductCategories({
      variables: {
        skus: cart?.lineItems.map((i) => i.variant?.sku),
        locale: localeConfig.locale,
      },
    })

    const oldQuantity = cart.lineItems.find(
      (i) => i.id === lineItem.id,
    )?.quantity

    await updateCart({
      variables: {
        actions:
          quantity > 0
            ? addToCartActions(
              lineItem,
              quantity,
              priceChannelId,
              stockChannelId,
            )
            : removeFromCartActions(lineItem, quantity, cart),
      },
    })
      .then(() => {
        if (setCartError) {
          setCartError('')
        }
      })
      .catch((error) => {
        if (
          setCartError &&
          error?.graphQLErrors?.[0]?.extensions?.code === 'InvalidOperation'
        ) {
          setCartError(
            error?.graphQLErrors?.[0]?.extensions?.localizedMessage?.[
            localeConfig.locale
            ] || error.message,
          )
        }
      })

    if (withAnalytics) {
      const categories =
        productCategoryData?.products?.results
          ?.find((i) => i.id === lineItem.productId)
          ?.masterData?.current?.categories.map((i) => i.name) || []

      const categoriesString = limitArrayCharacters(
        categories.join(','),
        ANALYTICS_CATEGORY_LIMIT,
      )

      if (quantity > 0) {
        analytics.push({
          action: 'ADD_LINE_ITEM',
          event: 'add_to_cart',
          ecommerce: {
            currency: lineItem?.price?.value?.currencyCode,
            value: Number(lineItem.price?.value?.centAmount) / 100,
            items: [
              {
                item_id: lineItem.variant.sku,
                item_name: lineItem.nameAllLocales?.find(
                  (name) => name.locale === cart?.locale,
                )?.value,
                price: Number(lineItem.price?.value?.centAmount) / 100,
                quantity,
                item_category: categoriesString,
                discount:
                  Number(lineItem.price?.discounted?.value?.centAmount) / 100 ||
                  0,
                affiliation: config.storeName,
                item_variant: lineItem?.variant?.key,
              },
            ],
          },
        })
      } else {
        analytics.push({
          action: 'REMOVE_LINE_ITEM',
          event: 'remove_from_cart',
          ecommerce: {
            currency: lineItem?.price?.value?.currencyCode,
            value: Number(lineItem.price?.value?.centAmount) / 100,
            items: [
              {
                item_id: lineItem.variant.sku,
                item_name: lineItem.nameAllLocales?.find(
                  (name) => name.locale === cart?.locale,
                )?.value,
                price: Number(lineItem.price?.value?.centAmount) / 100,
                quantity: oldQuantity,
                item_category: categoriesString,
                affiliation: config.storeName,
                item_variant: lineItem?.variant?.key,
                discount:
                  Number(lineItem.price?.discounted?.value?.centAmount) / 100 ||
                  0,
              },
            ],
          },
        })
      }
    }
  }
}


const addToCartActions = (
  lineItem: LineItem,
  quantity: number,
  priceChannelId: string,
  stockChannelId: string,
) => {

  const packageComposition = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'packageComposition',
  )?.value

  // Note: The value in this custom field is a string!
  const inscriptionText: string = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'inscriptionText',
  )?.value

  const patientName: string = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'patientName',
  )?.value

  const subscription: string = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'cbPricePlanId',
  )?.value

  const isChargebeeSubscription = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'is_subscription',
  )?.value

  return [
    {
      addLineItem: {
        sku: lineItem.variant.sku,
        quantity,
        ...(packageComposition || inscriptionText || subscription || patientName || isChargebeeSubscription
          ? {
            custom: {
              typeKey: 'line-item-custom-fields',
              fields: [
                packageComposition
                  ? {
                    name: 'packageComposition',
                    value: JSON.stringify(packageComposition),
                  }
                  : null,
                inscriptionText
                  ? {
                    name: 'inscriptionText',
                    // The array has to be stored as string in Commercetools, hence we have to stringify a string
                    value: JSON.stringify(inscriptionText),
                  }
                  : null,
                patientName
                  ? {
                    name: 'patientName',
                    // The array has to be stored as string in Commercetools, hence we have to stringify a string
                    value: JSON.stringify(patientName),
                  }
                  : null,
                subscription
                  ? {
                    name: 'cbPricePlanId',
                    value: JSON.stringify(subscription),
                  }
                  : null,
                isChargebeeSubscription ? {
                  name: 'is_subscription',
                  value: JSON.stringify(isChargebeeSubscription),
                }
                  : null,
              ].filter(isValue),
            },
          }
          : {}),
        ...(priceChannelId
          ? {
            distributionChannel: {
              id: priceChannelId,
            },
          }
          : {}),
        ...(stockChannelId
          ? {
            supplyChannel: {
              id: stockChannelId,
            },
          }
          : {}),
      },
    },
  ]
}

/* istanbul ignore next */
const removeFromCartActions = (
  lineItem: LineItem,
  quantity: number,
  cart: Cart,
) => {

  const newQuantity = lineItem.quantity + quantity

  // Note: The value in this custom field is a string!
  const inscriptionText: [] = JSON.parse(
    lineItem.custom?.customFieldsRaw.find((f) => f.name === 'inscriptionText')
      ?.value || JSON.stringify([]),
  )

  const patientName: string =
    lineItem.custom?.customFieldsRaw.find((f) => f.name === 'patientName')
      ?.value || JSON.stringify([])



  const customField =

    inscriptionText.length > 0
      ? newQuantity > 0 && inscriptionText.length > newQuantity
        ? {
          setLineItemCustomField: {
            lineItemId: lineItem.id,
            name: 'inscriptionText',
            // The array has to be stored as string in Commercetools, hence we have to stringify a string
            value: JSON.stringify(
              JSON.stringify(inscriptionText.slice(0, newQuantity)),
            ),
          },
        }
        : undefined
      : newQuantity < 0 && patientName.length > newQuantity
        ? {

          setLineItemCustomField: {

            lineItemId: lineItem.id,
            name: 'patientName',
            // The array has to be stored as string in Commercetools, hence we have to stringify a string
            value: JSON.stringify(
              JSON.stringify(patientName.slice(0, newQuantity)),
            ),
          },

        }
        : undefined

  return [
    {
      removeLineItem: {
        lineItemId: lineItem.id,
        quantity: quantity === 0 ? undefined : Math.abs(quantity),
      },
    },
    customField,
  ].filter(isValue)
}

