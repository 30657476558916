import type { Widget } from '@danone-global/internal/react/core'
import React from 'react'

export default {
  tags: ['price', 'price-v2'],

  developmentProps: {
    sku: 'FOM01',
  },

  Component: React.lazy(() => import('./price.widget')),
} as Widget
