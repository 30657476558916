import { getCurrencySymbol } from './get-currency-symbol'

export const formatMoney = (
  locale: string,
  currencyCode: string,
  centAmount: number,
  fractionDigits: number,
): string => {
  const symbol = getCurrencySymbol(locale, currencyCode)

  const amount = (centAmount / 100).toLocaleString(locale, {
    // style: 'currency',
    // currency: currencyCode,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })

  return (currencyCode === 'TRY' ? '%v %s' : '%s %v')
    .replace('%s', symbol)
    .replace('%v', amount)
}
