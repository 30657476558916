import { Typography } from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { ChangeEvent } from 'react'
import { Control, useController } from 'react-hook-form'

import FieldsError from '../error'

export interface Props {
  name: string

  label: string

  control: Control<any>

  required?: boolean

  placeholder?: string

  className?: string

  defaultChecked?: boolean

  disabled?: boolean

  onChange?: (event: ChangeEvent) => void
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      margin: theme.spacing(2, 0),
    },

    label: {
      position: 'relative',
      display: 'block',
      cursor: 'pointer',
      userSelect: 'none',
      marginLeft: theme.spacing(3.5),
      whiteSpace: 'pre-wrap',

      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },

    input: {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      width: 0,
      height: 0,
      cursor: 'pointer',

      '&:disabled + $checked': {
        opacity: 0.2,
        cursor: 'not-allowed',
      },
    },

    checkbox: {
      position: 'absolute',
      top: theme.utils.getSize(2),
      left: 0,
      width: theme.utils.getSize(20),
      height: theme.utils.getSize(20),
      backgroundColor: '#e1e1e1',
      borderRadius: theme.shape.borderRadius(0.5),
      cursor: 'pointer',
      boxShadow: 'inset 1px 1px 1px 0px rgb(0 0 0 / 10%)',
    },

    checked: {
      backgroundColor: theme.palette.primary.dark,

      '&::after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: `calc(50% - ${theme.utils.getSize(4, { forceUnit: true })})`,
        top: 0,
        width: theme.spacing(),
        height: theme.spacing(2),
        border: 'solid white',
        borderWidth: theme.utils.createStyle(
          0,
          theme.utils.getSize(3, { forceUnit: true }),
          theme.utils.getSize(3, { forceUnit: true }),
          0,
        ),
        transform: 'rotate(45deg)',
      },
    },
  }),
  { name: 'FieldsCheckbox' },
)

export const FieldsCheckbox: React.FC<Props> = ({
  name,
  label,
  control,
  required = false,
  placeholder,
  className,
  defaultChecked = '',
  onChange,
  disabled,
}) => {
  const classes = useStyles()
  const { field: input, fieldState } = useController({
    name,
    control,
    rules: { required },
    defaultValue: defaultChecked,
  })

  const handleChange = React.useCallback(
    (event: ChangeEvent) => {
      input.onChange(event)

      if (onChange) {
        onChange(event)
      }
    },
    [input],
  )

  return (
    <div className={clsx(classes.root, className)}>
      <label data-testid={`checkbox-label-${name}`} htmlFor={name}>
        <Typography
          className={classes.label}
          component="span"
          dangerouslySetInnerHTML={{
            __html: label,
          }}
          variant="body"
        />

        <input
          checked={input.value}
          className={classes.input}
          data-testid={`checkbox-${name}`}
          disabled={disabled}
          id={name}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          type="checkbox"
          value={input.value}
        />

        <span
          className={clsx(classes.checkbox, input.value && classes.checked)}
        />
      </label>

      {fieldState.error && (
        <FieldsError
          data-testid={`error-field-${name}.${fieldState.error.type}`}
          error={fieldState.error}
        />
      )}
    </div>
  )
}

export default FieldsCheckbox
