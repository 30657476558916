import React from 'react'

import { useCart } from './useCart'

export const useLineItemErrors = (): { [index: string]: string } => {
  const [cart] = useCart()

  return React.useMemo(() => {
    const allowOrderLineItemErrorsRaw = cart?.custom?.customFieldsRaw?.find(
      ({ name }) => name === 'allowOrderLineItemErrorsRaw',
    )

    if (allowOrderLineItemErrorsRaw) {
      const allowOrderLineItemErrors = JSON.parse(
        allowOrderLineItemErrorsRaw.value,
      )

      const lineItemErrors: { [index: string]: string } = {}
      let index = 0

      while (index < allowOrderLineItemErrors.length) {
        lineItemErrors[allowOrderLineItemErrors[index]] =
          allowOrderLineItemErrors[index + 1]
        index += 2
      }

      return lineItemErrors
    }

    return {}
  }, [cart?.custom?.customFieldsRaw])
}
