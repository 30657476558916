import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

import { Grid } from '../grid'
import { Props as GridProps } from '../grid/grid.component'

export interface Props extends GridProps {
  noDesktop?: boolean
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: theme.shape.borderRadius(),
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
      },
    },

    noDesktop: {
      [theme.breakpoints.up('sm')]: {
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
  }),
  { name: 'Container' },
)

export const Container: React.FC<Props> = ({
  children,
  className,
  noDesktop = false,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid
      className={clsx(classes.root, className, {
        [classes.noDesktop]: noDesktop,
      })}
      container
      {...rest}
    >
      {children}
    </Grid>
  )
}

export default Container
