import { MyCartUpdateAction } from '@danone-global/ct/interfaces'
import React from 'react'

import { CheckoutContext } from './checkout.context'

export type VerificationData = { phone: string; vToken: string }

export interface CheckoutContextOptions {
  activeStep: number

  loading: boolean

  actionsClassName: string

  containerClassName: string

  updateCart: (actions: MyCartUpdateAction[]) => Promise<void>

  setActiveStep: (step: number) => void

  editStep: (step: number) => void

  nextStep: () => void

  prevStep: () => void

  cancelEditStep: () => void

  verificationData: VerificationData

  setVerificationData: (data: VerificationData) => void

  callbackUrl: string

  isDeeplinkCart: boolean

  termsUrl?: string

  dataCollectionUrl?: string

  privacyUrl?: string

  faqUrl?: string
}

export const useCheckout = () =>
  React.useContext(CheckoutContext) as unknown as CheckoutContextOptions
