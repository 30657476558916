import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client'
import type { Cart } from '@danone-global/ct/interfaces'

import { GET_ACTIVE_CART, getActiveCartVariables } from '../core.graphql'
import { useCore } from './useCore'
import { useEffect } from 'react'

export const useCart = (): [
  Cart | undefined,
  boolean,
  (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<{ me: { carts: { results: Cart[] } } }>>,
] => {
  const { localeConfig } = useCore()
  const { data, loading, refetch } = useQuery(GET_ACTIVE_CART, {
    variables: getActiveCartVariables(localeConfig?.country),
  })

  const cart = data?.me?.carts?.results[0];

  useEffect(() => {
    if (cart) {
      setLocalStorageFlagForSubscription(cart)
    }
  }, [cart]);

  return [cart, loading, refetch]
}


export function setLocalStorageFlagForSubscription(cart: any) {
  localStorage.setItem('hasSubscription', String(doesCartHasSubcription(cart)))
}

export function doesCartHasSubcription(cart: any) : boolean {
  if (cart && cart.lineItems && cart.lineItems.length) {
    return cart.lineItems?.some((item : any) => {
      return item.custom?.customFieldsRaw && item.custom?.customFieldsRaw.some((cfield : any) => cfield.name === "is_subscription" && cfield.value === true)
    })
  } else {
    return false
  }
}
