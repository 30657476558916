import { CartUpdateAction } from '@danone-global/ct/interfaces'
import { CloseIcon } from '@danone-global/internal/react/components/icons'
import Price from '@danone-global/internal/react/components/price'
import {
  Grid,
  IconButton,
  Typography,
} from '@danone-global/internal/react/components/ui'
import {
  Theme,
  UPDATE_ACTIVE_CART,
  useLocalisedString,
  useMutation,
} from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { DiscountInterface } from '../'

export interface Props extends DiscountInterface {
  currencyCode: string
  fractionDigits: number
  valueClassName: string
  notMatching?: boolean
  isDiscountRemoveBtn?: boolean
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      position: 'absolute',
      top: -13,
      right: -22,
    },
    price: {
      paddingLeft: theme.spacing(1),
    },
    unmatchedDiscount: {
      padding: theme.spacing(1, 0),
    },
    unmatchedDiscountText: {},
  }),
  { name: 'DiscountCodePrice' },
)

export const DiscountCodePrice: React.FC<Props> = ({
  discountCodeId,
  nameAllLocales,
  centAmount,
  currencyCode,
  fractionDigits,
  valueClassName,
  notMatching,
  isDiscountRemoveBtn
}) => {
  const [updateCart] = useMutation(UPDATE_ACTIVE_CART)
  const name = useLocalisedString(nameAllLocales)
  const classes = useStyles()

  const removeDiscountCode = React.useCallback(async () => {
    const action: CartUpdateAction = {
      removeDiscountCode: {
        discountCode: {
          id: discountCodeId,
          typeId: 'discount-code',
        },
      },
    }

    await updateCart({
      variables: {
        actions: [action],
      },
    })
  }, [discountCodeId])

  return (
    <Grid
      data-testid={`my-order.discount.${discountCodeId}`}
      direction="row"
      gutters="top"
      container
      flex
    >
      <Grid flex>
        <Typography color="primary-dark" variant="body2" noGutters>
          {name}
        </Typography>
      </Grid>

      <Grid
        className={clsx(
          valueClassName,
          classes.price,
          notMatching && classes.unmatchedDiscount,
        )}
        flex={notMatching ? false : true}
        justify="flex-end"
      >
        {notMatching ? (
          <Typography
            className={classes.unmatchedDiscountText}
            component="div"
            variant="body2"
            noGutters
          >
            <FormattedMessage
              defaultMessage="This coupon can not be applied. Please remove it to continue to the checkout."
              id="my-order.add-discount-code.not-matching"
            />
          </Typography>
        ) : (
          <Typography
            color="primary-dark"
            component="span"
            variant="body2"
            noGutters
          >
            <Price
              centAmount={-centAmount}
              currencyCode={currencyCode}
              data-testid={`my-order.discount.${discountCodeId}.price`}
              fractionDigits={fractionDigits}
              inherit
              showFreeLabel
            />
          </Typography>
        )}
        {!isDiscountRemoveBtn &&
        <IconButton
          className={classes.button}
          color="black"
          data-testid={`btn.remove-discount-${discountCodeId}`}
          onClick={removeDiscountCode}
          variant="flat"
        >
          <CloseIcon width={13} />
        </IconButton>
      }
      </Grid>
    </Grid>
  )
}

export default DiscountCodePrice
