import type { Widget } from '@danone-global/internal/react/core'
import React from 'react'

export default {
  tags: ['variant-selector', 'variant-selector-v2'],

  developmentProps: {
    sku: 'PACKAGE01',
  },

  Component: React.lazy(() => import('./variant-selector.widget')),
} as Widget
