import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

export interface Props {
  viewBox: string

  width?: number

  height?: number

  size?: number

  className?: string

  style?: any

  onClick?: any

  color?: 'default' | 'primary'
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    'color-default': {},

    'color-primary': {
      fill: theme.palette.primary.main,
    },
  }),
  { name: 'Icon' },
)

export const Icon: React.FC<Props> = ({
  children,
  viewBox,
  width = 24,
  height = 24,
  size = null,
  style = {},
  color,
  className,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <svg
      className={clsx(classes[`color-${color}`], className)}
      height={size || height}
      style={style}
      viewBox={viewBox}
      width={size || width}
      {...rest}
    >
      {children}
    </svg>
  )
}

export default Icon
