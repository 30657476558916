import { Typography } from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

import {
  AddressFoundCallback,
  AutoCompleteProvider,
  Result,
} from './fields-auto-complete.interfaces'

export interface Props {
  name: string

  label?: string

  placeholder?: string

  className?: string

  provider: AutoCompleteProvider

  onAddressFound: AddressFoundCallback
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      marginBottom: theme.spacing(),

      '& label': {
        display: 'block',
        marginBottom: theme.spacing(),
      },
    },

    input: {
      position: 'relative',
      width: '100%',
      height: 'auto',
      minWidth: 'auto',
      border: '1px solid rgba(151, 151, 152, 0.25)',
      padding: theme.spacing(),
      fontSize: theme.utils.getSize(16),
      fontWeight: theme.typography.fontWeightLight,
      fontFamily: theme.typography.fontFamily,
      outline: 'none',
      transition: 'all 200ms ease-out',
      borderRadius: theme.shape.borderRadius(),
      boxShadow: 'inset 1px 1px 1px 0px rgb(0 0 0 / 10%)',

      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },

    list: {
      position: 'absolute',
      background: 'white',
      zIndex: 1000,
      maxHeight: '650px',
      width: '100%',
      margin: 0,
      listStyle: 'none',
      padding: theme.spacing(1),
      border: '1px solid rgba(151, 151, 152, 0.25)',
      borderRadius: theme.shape.borderRadius(),
      overflowY: 'auto',
    },

    listItem: {
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius(),
      cursor: 'pointer',
      transition: 'all 200ms ease-out',
      willChange: 'background-color',

      '&:hover': {
        backgroundColor: theme.utils.hexToRgba(
          theme.palette.primary.main,
          0.15,
        ),
      },
    },
  }),
  { name: 'FieldsAutoComplete' },
)

export const FieldsAutoComplete: React.FC<Props> = ({
  name,
  label,
  placeholder,
  className,
  provider,
  onAddressFound,
}) => {
  const classes = useStyles()
  const [items, setItems] = React.useState<Result[]>([])

  const { search, select } = provider.useSetup(setItems, onAddressFound, name)

  const handleOnChange = React.useCallback(
    (event) => {
      search(event.target.value)
    },
    [search],
  )

  const handleSelectItem = React.useCallback(
    (item: Result) => () => {
      select(item)

      if (!item.hasChildItems) {
        setItems([])
      }
    },
    [],
  )

  return (
    <div
      className={clsx(classes.root, className)}
      data-testid={`field-${name}`}
    >
      {label && (
        <label htmlFor={name}>
          <Typography component="span" variant="h5">
            {label}
          </Typography>
        </label>
      )}

      <div>
        <input
          autoComplete="off"
          className={classes.input}
          data-testid={`input-${name}`}
          id={name}
          name={name}
          onChange={handleOnChange}
          placeholder={placeholder}
        />

        {items.length > 0 && (
          <ul className={classes.list}>
            {items.map((item) => (
              <Typography
                key={item.text}
                className={classes.listItem}
                component="li"
                data-testid={`btn.search-result.${item.text}`}
                onClick={handleSelectItem(item)}
                variant="body2"
              >
                {item.text}
              </Typography>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default FieldsAutoComplete
