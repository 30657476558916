import { Icon } from '@danone-global/internal/react/components/icons'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

import { Loader } from '../loader'

export type Variants = 'default' | 'flat' | 'link' | 'brand' | 'reversed'

export interface Props {
  component?: 'button' | 'a' | 'div'

  variant?: Variants

  icon?: Icon

  onClick?: (event) => void

  disabled?: boolean

  loading?: boolean

  small?: boolean

  fullWidth?: boolean

  className?: string

  href?: string

  target?: string

  rel?: string

  type?: 'button' | 'reset' | 'submit'
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 200ms ease-out',
      padding: theme.spacing(1, 3),
      fontSize: theme.utils.getSize(18),
      borderRadius: theme.utils.getSize(50),
      fontFamily: theme.typography.fontFamily,
      letterSpacing: theme.utils.getSize(0.5),
      outline: 'none',
      lineHeight: theme.utils.getSize(24, { forceUnit: true }),
      textDecoration: 'none',
    },

    small: {
      fontSize: theme.utils.getSize(14),
      lineHeight: theme.utils.getSize(18, { forceUnit: true }),
      padding: theme.spacing(0.5, 1.5),
    },

    fullWidth: {
      width: '100%',

      '& $label': {
        width: '100%',
        textAlign: 'center',
      },
    },

    default: {
      backgroundColor: theme.palette.common.transparent,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      border: theme.utils.createStyle(
        'solid',
        '1px',
        theme.palette.primary.main,
      ),

      '&:hover': {
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
        fill: theme.palette.primary.dark,
      },
    },

    reversed: {
      backgroundColor: theme.palette.common.transparent,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      border: theme.utils.createStyle(
        'solid',
        '2px',
        theme.palette.primary.main,
      ),

      '&:hover': {
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
        fill: theme.palette.primary.main,
      },

      '&$disabled': {
        backgroundColor: theme.palette.common.transparent,
        borderColor: theme.palette.text.disabled,
        color: theme.palette.text.disabled,
      },

      '&$disabled:hover': {
        backgroundColor: theme.palette.common.transparent,
      },
    },

    brand: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
      fill: theme.palette.text.secondary,

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },

    flat: {
      backgroundColor: theme.palette.common.transparent,
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,

      '&:hover': {
        fill: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
      },
    },

    link: {
      textDecoration: 'underline',
    },

    disabled: {
      color: theme.palette.text.disabled,
      fill: theme.palette.text.disabled,
      backgroundColor: theme.palette.background.disabled,
      cursor: 'not-allowed',

      '&:hover': {
        color: theme.palette.text.disabled,
        fill: theme.palette.text.disabled,
        backgroundColor: theme.palette.background.disabled,
      },
    },

    loading: {
      '& span, svg': {
        opacity: 0,
      },
    },

    label: {},

    icon: {
      position: 'relative',
      top: theme.utils.getSize(0.1),
      marginLeft: theme.spacing(2),
      maxWidth: theme.utils.getSize(16),
      maxHeight: theme.utils.getSize(16),
    },

    loader: {
      position: 'absolute',
      left: `calc(50% - ${theme.utils.getSize(10, { forceUnit: true })})`,
      top: `calc(50% - ${theme.utils.getSize(10, { forceUnit: true })})`,
    },
  }),
  { name: 'Button' },
)

export const Button: React.FC<Props> = ({
  component: Component = 'button',
  variant = 'default',
  icon: Icon = null,
  className = null,
  disabled = false,
  loading = false,
  children,
  onClick,
  small = false,
  fullWidth = false,
  type = 'button',
  ...rest
}) => {
  const classes = useStyles()

  const handleButtonClick = React.useCallback(
    (event) => {
      if (!disabled && !loading && onClick) {
        onClick(event)
      }
    },
    [disabled, onClick, loading],
  )

  return (
    <Component
      className={clsx(
        classes.root,
        classes[variant],
        {
          [classes.disabled]: disabled && !loading,
          [classes.loading]: loading,
          [classes.small]: small,
          [classes.fullWidth]: fullWidth,

          // Also add flat when variant is link
          [classes.flat]: variant === 'link',
        },
        className,
      )}
      disabled={disabled || loading}
      onClick={handleButtonClick}
      type={type}
      {...rest}
    >
      <span className={classes.label}>{children}</span>

      {Icon && <Icon className={classes.icon} width={16} />}

      {loading && <Loader className={classes.loader} variant="small" />}
    </Component>
  )
}

export default Button
