import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { HTMLAttributes } from 'react'

export const GUTTERS = [0, 8, 16, 24, 40]
export const GRID_SIZES = [true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const generateGutter = (theme, breakpoint) => {
  const styles = {}

  GUTTERS.forEach((spacing, index) => {
    if (index === 0) {
      // Skip the default style.
      return
    }

    styles[`spacing-${breakpoint}-${spacing}`] = {
      margin: -spacing / 2,
      width: `calc(100% + ${spacing}px)`,
      '& > $typeItem': {
        padding: spacing / 2,
      },
    }
  })

  return styles
}

export const generateGrid = (globalStyles, theme, breakpoint) => {
  // For the auto layouting
  const styles = {
    [`grid-${breakpoint}`]: {
      flexBasis: 0,
      flexGrow: 1,
      maxWidth: '100%',
    },
  }

  GRID_SIZES.forEach((size) => {
    if (typeof size === 'boolean') {
      // Skip the first one as handle above.
      return
    }

    // Only keep 6 significant numbers.
    const width = `${Math.round((size / 12) * 10e6) / 10e4}%`

    /* eslint-disable max-len */
    // Close to the bootstrap implementation:
    // https://github.com/twbs/bootstrap/blob/b0508a975d711d6b24c01f57dd5445c22699fac4/scss/mixins/_grid.scss#L69
    /* eslint-enable max-len */
    styles[`grid-${breakpoint}-${size}`] = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      flexBasis: width,
      maxWidth: width,
    }
  })

  // No need for a media query for the first size.
  if (breakpoint === 'xs') {
    Object.assign(globalStyles, styles)
  } else {
    globalStyles[theme.breakpoints.up(breakpoint)] = styles
  }
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    typeContainer: {
      position: 'relative',
      boxSizing: 'border-box',
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      maxWidth: theme.utils.getSize(theme.breakpoints.containerWidth),
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    typeItem: {
      boxSizing: 'border-box',
      flex: '0 0 auto',
      margin: 0,
    },

    flex: {
      display: 'flex',
      flex: 1,
    },

    'direction-xs-column': {
      flexDirection: 'column',
    },

    'direction-xs-column-reverse': {
      flexDirection: 'column-reverse',
    },

    'direction-xs-row-reverse': {
      flexDirection: 'row-reverse',
    },

    'wrap-xs-nowrap': {
      flexWrap: 'nowrap',
    },

    'wrap-xs-wrap-reverse': {
      flexWrap: 'wrap-reverse',
    },

    'align-items-xs-center': {
      alignItems: 'center',
    },

    'align-items-xs-flex-start': {
      alignItems: 'flex-start',
    },

    'align-items-xs-flex-end': {
      alignItems: 'flex-end',
    },

    'align-items-xs-baseline': {
      alignItems: 'baseline',
    },

    'align-content-xs-center': {
      alignContent: 'center',
    },

    'align-content-xs-flex-start': {
      alignContent: 'flex-start',
    },

    'align-content-xs-flex-end': {
      alignContent: 'flex-end',
    },

    'align-content-xs-space-between': {
      alignContent: 'space-between',
    },

    'align-content-xs-space-around': {
      alignContent: 'space-around',
    },

    'justify-xs-center': {
      justifyContent: 'center',
    },

    'justify-xs-flex-end': {
      justifyContent: 'flex-end',
    },

    'justify-xs-space-between': {
      justifyContent: 'space-between',
    },

    'justify-xs-space-around': {
      justifyContent: 'space-around',
    },

    ...generateGutter(theme, 'xs'),

    ...theme.breakpoints.keys.reduce((accumulator, key) => {
      // Use side effect over immutability for better performance.
      generateGrid(accumulator, theme, key)

      return accumulator
    }, {}),

    'gutters-top': {
      marginTop: theme.spacing(),
    },

    'gutters-down': {
      marginDown: theme.spacing(),
    },
  }),
  { name: 'Grid' },
)

export interface Props extends HTMLAttributes<any> {
  alignContent?: string

  alignItems?: string

  container?: boolean

  flex?: boolean

  direction?: 'row' | 'column'

  item?: boolean

  justify?: string

  spacing?: 0 | 8 | 16

  wrap?: string

  className?: string

  component?: React.FunctionComponent<any>

  lg?: number | boolean

  md?: number | boolean

  sm?: number | boolean

  xl?: number | boolean

  xs?: number | boolean

  gutters?: 'default' | 'top' | 'down'
}

export const Grid: React.FC<Props> = ({
  alignContent = 'stretch',
  alignItems = 'stretch',
  container = false,
  flex = false,
  direction = 'row',
  item = false,
  justify = 'flex-start',
  spacing = 0,
  wrap = 'wrap',
  gutters = 'default',
  className: classNameProp,
  component: Component = 'div',
  lg,
  md,
  sm,
  xl,
  xs,
  children,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Component
      className={clsx(
        classes.root,
        {
          [classes.typeContainer]: container,
          [classes.typeItem]: item,
          [classes.flex]: flex,
          [classes[`spacing-xs-${String(spacing)}`]]:
            container && spacing !== 0,
          [classes[`direction-xs-${String(direction)}`]]: direction !== 'row',
          [classes[`wrap-xs-${String(wrap)}`]]: wrap !== 'wrap',
          [classes[`align-items-xs-${String(alignItems)}`]]:
            alignItems !== 'stretch',
          [classes[`align-content-xs-${String(alignContent)}`]]:
            alignContent !== 'stretch',
          [classes[`justify-xs-${String(justify)}`]]: justify !== 'flex-start',
          [classes['grid-xs']]: xs === true,
          [classes[`grid-xs-${String(xs)}`]]: xs && xs !== true,
          [classes['grid-sm']]: sm === true,
          [classes[`grid-sm-${String(sm)}`]]: sm && sm !== true,
          [classes['grid-md']]: md === true,
          [classes[`grid-md-${String(md)}`]]: md && md !== true,
          [classes['grid-lg']]: lg === true,
          [classes[`grid-lg-${String(lg)}`]]: lg && lg !== true,
          [classes['grid-xl']]: xl === true,
          [classes[`grid-xl-${String(xl)}`]]: xl && xl !== true,
          [classes[`gutters-${String(gutters)}`]]: gutters !== 'default',
        },
        classNameProp,
      )}
      {...rest}
    >
      {children}
    </Component>
  )
}

export default Grid
