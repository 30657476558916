import { Money } from '@danone-global/ct/interfaces'
import Price from '@danone-global/internal/react/components/price'
import { Grid, Typography } from '@danone-global/internal/react/components/ui'
import { useCore } from '@danone-global/internal/react/core'
import { useSelectedPlanName } from '@danone-global/internal/react/modules/chargebee'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export interface Props {
  valueClassName: string

  price: Money
}

export const TotalPrice: React.FC<Props> = ({ valueClassName, price }) => {
  const subscriptionLabel = useSelectedPlanName()
  const { features } = useCore()

  return (
    <>
      <Grid direction="row" flex>
        <Grid flex>
          <Typography
            color="primary-dark"
            fontWeight="bold"
            variant="body"
            noGutters
          >
            <FormattedMessage
              defaultMessage="Total price"
              id="my-order.total-price"
            />
          </Typography>
        </Grid>

        <Grid className={valueClassName}>
          <Typography
            color="primary-dark"
            component="span"
            variant="body"
            noGutters
          >
            <Price
              tbd={features.showTaxDuringCheckout}
              testId="my-order.total-price"
              inherit
              {...price}
            />
          </Typography>
        </Grid>
      </Grid>

      {subscriptionLabel && (
        <Grid direction="row" justify="flex-end" flex>
          <Grid className={valueClassName}>
            <Typography
              color="primary-dark"
              component="span"
              data-testid="my-order.active-subscription"
              variant="body2"
              noGutters
            >
              {`(${subscriptionLabel})`}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default TotalPrice
