import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: 20,
      height: 20,
      position: 'relative',
      textIndent: '-9999em',
      borderTop: `${theme.utils.getSize(2, {
        forceUnit: true,
      })} solid rgba(255, 255, 255, 0.2)`,
      borderRight: `${theme.utils.getSize(2, {
        forceUnit: true,
      })} solid rgba(255, 255, 255, 0.2)`,
      borderBottom: `${theme.utils.getSize(2, {
        forceUnit: true,
      })} solid rgba(255, 255, 255, 0.2)`,
      borderLeft: `${theme.utils.getSize(2, {
        forceUnit: true,
      })} solid #ffffff`,
      transform: 'translateZ(0)',
      animation: '$loaderAnimation 1.1s infinite linear',
      borderRadius: '50%',

      '&:after': {
        borderRadius: '50%',
      },
    },

    light: {},
    dark: {
      borderTopColor: theme.utils.hexToRgba(theme.palette.primary.main, 0.4),
      borderRightColor: theme.utils.hexToRgba(theme.palette.primary.main, 0.4),
      borderBottomColor: theme.utils.hexToRgba(theme.palette.primary.main, 0.4),
      borderLeftColor: theme.utils.hexToRgba(theme.palette.primary.main, 1),
    },

    '@keyframes loaderAnimation': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },

    default: {
      width: theme.utils.getSize(40),
      height: theme.utils.getSize(40),

      '&:after': {
        width: theme.utils.getSize(40),
        height: theme.utils.getSize(40),
      },
    },

    small: {
      width: theme.utils.getSize(20),
      height: theme.utils.getSize(20),

      '&:after': {
        width: theme.utils.getSize(20),
        height: theme.utils.getSize(20),
      },
    },
  }),
  { name: 'Loader' },
)

export interface Props {
  variant?: 'default' | 'small'
  color?: 'light' | 'dark'
  className?: string
}

export const Loader: React.FC<Props> = ({
  variant = 'default',
  color = 'light',
  className,
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.root,
        classes[color],
        classes[variant],
        className,
      )}
    />
  )
}

export default Loader
