import { IPasswordValidationOptions } from '@danone-global/internal/react/core'

const defaults: IPasswordValidationOptions = {
  minLength: 8,
  mustHaveLetter: true,
  specialCharacters: 'default',
  mustHaveNumber: true,
  mustHaveLowercaseAndUppercase: false,
}

const specialCharacterRegex = {
  default: new RegExp('[|!@#$%^&*()\\-=_+,./<>?;\':"{}[\\]]'),
  DACH: new RegExp('[!@#$%^&*_=+-.]'),
}

export const isValidPassword = (
  options?: Partial<IPasswordValidationOptions>,
) => {
  const {
    minLength,
    specialCharacters,
    mustHaveNumber,
    mustHaveLetter,
    mustHaveLowercaseAndUppercase,
  } = { ...defaults, ...options }

  return {
    password: (value: string): boolean =>
      !value ||
      ((minLength ? value.length >= minLength : true) &&
        (mustHaveLetter ? /[A-Za-z]/.test(value) : true) &&
        (specialCharacters !== 'none'
          ? specialCharacterRegex[specialCharacters].test(value)
          : true) &&
        (mustHaveNumber ? /[0-9]/.test(value) : true) &&
        (mustHaveLowercaseAndUppercase
          ? /(?=.*[a-z])(?=.*[A-Z])/.test(value)
          : true)),
  }
}
