import { AlertIcon } from '@danone-global/internal/react/components/icons'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

import { Typography } from '../typography'

type Variants = 'warning'

type Props = {
  children: React.ReactNode
  variant?: Variants
  className?: string
}

const Alert = ({ children, variant = 'warning', className }: Props) => {
  const classes = useStyles()

  return (
    <Typography className={clsx(classes.root, classes[variant], className)}>
      <span className={classes.text}>
        {variant === 'warning' ? (
          <AlertIcon className={classes.icon} width={18} />
        ) : undefined}

        {children}
      </span>
    </Typography>
  )
}

export default Alert

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'block',
      border: `1px solid`,
      borderRadius: theme.shape.borderRadius(),
      padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
      margin: 0,
    },
    warning: {
      borderColor: theme.palette.warning.main,
      backgroundColor: theme.palette.warning.light,
      '& $text': {
        color: theme.palette.warning.main,
      },
    },
    icon: {
      width: 16,
      height: 14,
      top: 2,
      position: 'relative',
      marginRight: theme.spacing(0.8),
    },
    text: {},
    [theme.breakpoints.up('md')]: {
      root: {
        padding: `${theme.spacing(1.4)} ${theme.spacing(2.2)}`,
      },
    },
  }),
  { name: 'Alert' },
)
