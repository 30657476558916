import { MyCartUpdateAction } from '@danone-global/ct/interfaces'
import { CheckIcon } from '@danone-global/internal/react/components/icons'
import {
  Button,
  Grid,
  IconButton,
  Input,
  Typography,
} from '@danone-global/internal/react/components/ui'
import {
  getActiveCart,
  Theme,
  UPDATE_ACTIVE_CART,
  useCore,
  useMutation,
} from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: theme.spacing(3, 0),
    },

    addingDiscountCode: {
      margin: theme.spacing(1, 0, 3),
    },

    addingLabel: {
      margin: theme.spacing(1, 0),
    },

    addButton: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSize,
      padding: theme.utils.getSize(6),
      lineHeight: 1,
    },

    inputContainer: {},

    input: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    button: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: theme.shape.borderRadius(),
      borderBottomRightRadius: theme.shape.borderRadius(),
      padding: theme.spacing(0, 2),
    },
  }),
  { name: 'AddDiscountCode' },
)

export const AddDiscountCode = () => {
  const [addingDiscountCode, setAddingDiscountCode] = React.useState(false)
  const [discountCode, setDiscountCode] = React.useState('')
  const [couponCodeError, setCouponCodeError] = React.useState(false)

  const [updateCart, { error }] = useMutation(UPDATE_ACTIVE_CART)

  const classes = useStyles()
  const core = useCore()

  const toggleAddDiscount = React.useCallback(() => {
    setAddingDiscountCode(!addingDiscountCode)
  }, [addingDiscountCode])

  const handleAddDiscountCode = React.useCallback(async () => {
    if (discountCode.trim().length > 0) {
      setCouponCodeError(false)
      const addDiscountCodeAction: MyCartUpdateAction = {
        addDiscountCode: {
          code: discountCode,
        },
      }

      try {
        await updateCart({
          variables: {
            actions: [addDiscountCodeAction],
          },
        })

        const cart = await getActiveCart(core, false)
        let notAllowedCoupanCodes
        if(cart?.discountCodes?.length) {
          notAllowedCoupanCodes = cart?.discountCodes?.filter(code => 
            {
              const isCodeMatches = code?.discountCode?.code === discountCode
              if(isCodeMatches) {
                return {state: code?.state, code: discountCode}
              } else {
                return
              }
            }
          )
        }   
        if(notAllowedCoupanCodes?.find(item=> item?.state === 'MaxApplicationReached')) {
          setCouponCodeError(true)
          setAddingDiscountCode(true)
        } else {
          setDiscountCode('')
          setCouponCodeError(false)
        }
      } catch {
        setCouponCodeError(false)
        // Error from updateCart will be used
      }
    } else {
      setAddingDiscountCode(false)
    }
  }, [discountCode])

  const handleDiscountChange = React.useCallback((event) => {
    setDiscountCode(event.target.value)
  }, [])

  return (
    <div
      className={clsx(
        classes.root,
        addingDiscountCode && classes.addingDiscountCode,
      )}
    >
      {!addingDiscountCode && (
        <Button
          className={classes.addButton}
          data-testid="btn.add-voucher-code"
          onClick={toggleAddDiscount}
          variant="default"
        >
          <FormattedMessage
            defaultMessage="Add voucher code +"
            id="my-order.add-discount-code.add"
          />
        </Button>
      )}

      {addingDiscountCode && (
        <Typography className={classes.addingLabel} variant="body">
          <FormattedMessage
            defaultMessage="Add voucher code"
            id="my-order.add-discount-code.add-label"
          />
        </Typography>
      )}

      {addingDiscountCode && (
        <Grid className={classes.inputContainer} flex>
          <Input
            data-testid="input.discount-code"
            inputClassName={classes.input}
            onChange={handleDiscountChange}
            value={discountCode}
          />

          <IconButton
            className={classes.button}
            data-testid="btn.add-discount-code"
            onClick={handleAddDiscountCode}
            variant="brand"
          >
            <CheckIcon width={16} />
          </IconButton>
        </Grid>
      )}

      {error && (
        <Typography color="primary">
          <FormattedMessage
            defaultMessage="Discount code could not be applied"
            id="my-order.add-discount-code.error"
          />
        </Typography>
      )}

      {couponCodeError && (
        <Typography color="primary">
          <FormattedMessage
            defaultMessage="This discount code is not valid"
            id="my-order.coupon-code.error"
          />
        </Typography>
      )}
    </div>
  )
}

export default AddDiscountCode
