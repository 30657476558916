import { useCore } from '@danone-global/internal/react/core'
import React from 'react'

/**
 * Returns the correct channel id to use
 */
export const useChannelId = (providedChannelId: string = undefined): string => {
  const { config } = useCore()

  return React.useMemo(() => {
    if (
      providedChannelId &&
      config?.channelKeyToId &&
      config?.channelKeyToId[providedChannelId]
    ) {
      return config.channelKeyToId[providedChannelId]
    } else if (providedChannelId) {
      return providedChannelId
    }

    return config.defaultChannelId
  }, [providedChannelId])
}
