import { Typography } from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import {
  Control,
  FieldPathValue,
  useController,
  Validate,
} from 'react-hook-form'

import FieldsError from '../error'

export interface Props {
  name: string

  label?: string

  control: Control<any>

  required?: boolean

  placeholder?: string

  className?: string

  defaultValue?: string

  minLength?: number

  maxLength?: number

  disabled?: boolean

  validate?:
    | Validate<FieldPathValue<any, any>>
    | Record<string, Validate<FieldPathValue<any, any>>>
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      marginBottom: theme.spacing(),
      cursor: 'pointer',

      '& label': {
        display: 'block',
        marginBottom: theme.spacing(),
      },
    },

    input: {
      position: 'relative',
      width: '100%',
      height: 'auto',
      minWidth: 'auto',
      border: '1px solid rgba(151, 151, 152, 0.25)',
      padding: theme.spacing(),
      fontSize: theme.utils.getSize(16),
      fontWeight: theme.typography.fontWeightLight,
      fontFamily: theme.typography.fontFamily,
      outline: 'none',
      transition: 'all 200ms ease-out',
      borderRadius: theme.shape.borderRadius(),
      '-webkit-appearance': 'none',

      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },

    inputWrap: {},

    invalid: {
      borderColor: theme.palette.error.main,
    },
  }),
  { name: 'FieldsSelect' },
)

export const FieldsSelect: React.FC<Props> = ({
  name,
  label,
  control,
  required = false,
  placeholder,
  className,
  defaultValue,
  minLength,
  maxLength,
  children,
  disabled,
  validate,
}) => {
  const classes = useStyles()
  const { field: select, fieldState } = useController({
    name,
    control,
    rules: { required, minLength, maxLength, validate },
    defaultValue,
  })

  return (
    <div className={clsx(classes.root, className)}>
      {label && (
        <label htmlFor={name}>
          <Typography component="span" variant="h5">
            {label}
            {required && <span> *</span>}
          </Typography>
        </label>
      )}

      <div className={classes.inputWrap}>
        <select
          {...select}
          className={clsx(classes.input, fieldState.error && classes.invalid)}
          data-testid={`input-${name}`}
          disabled={disabled}
          id={name}
          name={name}
          placeholder={placeholder}
        >
          {children}
        </select>
      </div>
      {/*{help && (!touched || !error) ? <small className="help">{help}</small> : null}*/}
      {fieldState.error && <FieldsError error={fieldState.error} />}
    </div>
  )
}

export default FieldsSelect
