import { Theme, ThemeOptions } from './'
import { createBreakpoints } from './core.create-breakpoints'
import { createMixins } from './core.create-mixins'
import { createPalette } from './core.create-palette'
import { createShape } from './core.create-shape'
import { createSpacing } from './core.create-spacing'
import { createTypography } from './core.create-typography'

export const createTheme = (options: ThemeOptions): Theme => {
  const {
    direction,
    typography,
    breakpoints = {},
    palette,
    spacing = {
      unit: 8,
    },
    shape = {
      borderRadius: 8,
    },
    unit = 'px',
    mixins = {},
    ...rest
  } = options

  const utils = createMixins(mixins, typography, unit)

  return {
    direction,
    unit,
    spacing: createSpacing(utils, spacing.unit),
    palette: createPalette(palette),
    typography: createTypography(typography),
    breakpoints: createBreakpoints(breakpoints, unit),
    utils,
    shape: createShape(utils, shape),
    ...rest,
  }
}
