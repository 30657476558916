declare const window: {
  digitalData: {
    eventArray: {
      eventLabel: string
      eventCategory: string
      eventName: string | number
      eventAction: string | number
      elementValue: string | number
    }[]
  }
}

export const aemAnalytics = (event): void => {
  // Skip the event if eventName is not set
  if (!window.digitalData || !event.eventName) {
    return
  }

  ;(window.digitalData.eventArray || []).push({
    eventLabel: 'DTC component',
    eventCategory: 'DTC',
    eventName: event.eventName,
    eventAction: event.eventAction,
    elementValue: event.eventValue,
  })
}
