import { Cart, LineItemMode } from '@danone-global/ct/interfaces'
import { getDiscountsFromOrder } from '@danone-global/internal/react/components/my-order'
import Price from '@danone-global/internal/react/components/price'
import { Grid, Typography } from '@danone-global/internal/react/components/ui'
import { calculateSubTotal, useCore } from '@danone-global/internal/react/core'
import { useSelectedPlanName } from '@danone-global/internal/react/modules/chargebee'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export interface Props {
  className: string
  valueClassName: string
  cart: Cart
  showTaxes?: boolean
}

export const PriceSummary: React.FC<Props> = ({
  className,
  valueClassName,
  cart,
  showTaxes,
}) => {
  const { features } = useCore()
  const price = cart.taxedPrice ? cart.taxedPrice.totalGross : cart.totalPrice

  // put in helper
  const amountOfProducts =
    cart?.lineItems?.reduce((amount, lineItem) => {
      if (lineItem.lineItemMode === LineItemMode.Standard) {
        return amount + lineItem.quantity
      }

      return amount
    }, 0) ?? 0

  const discountValue = getDiscountsFromOrder(cart).reduce(
    (total, current) => total + current?.centAmount || 0,
    0,
  )

  if (features.showTaxDuringCheckout) {
    return (
      <>
        <div>
          {/* AMOUNT */}
          <Grid direction="row" gutters="top" flex>
            <Grid flex>
              <Typography color="primary-dark" variant="body2" noGutters>
                <FormattedMessage
                  defaultMessage="Products"
                  id="my-order.products"
                />
                :
              </Typography>
            </Grid>

            <Grid className={valueClassName}>
              <Typography fontWeight="bold" variant="body" noGutters>
                {amountOfProducts}
              </Typography>
            </Grid>
          </Grid>

          {/* SUBTOTAL */}
          <Grid direction="row" gutters="top" flex>
            <Grid flex>
              <Typography color="primary-dark" variant="body2" noGutters>
                <FormattedMessage
                  defaultMessage="Subtotal"
                  id="my-order.subtotal"
                />
                :
              </Typography>
            </Grid>

            <Grid className={valueClassName}>
              <Price
                testId="checkout.order.total-price"
                inherit
                {...calculateSubTotal(cart)}
              />
            </Grid>
          </Grid>

          {/* DISCOUNTS */}
          {discountValue ? (
            <Grid direction="row" gutters="top" flex>
              <Grid flex>
                <Typography color="primary-dark" variant="body2" noGutters>
                  <FormattedMessage
                    defaultMessage="Discount"
                    id="my-order.discount"
                  />
                  :
                </Typography>
              </Grid>

              <Grid className={valueClassName}>
                <Price
                  centAmount={-discountValue}
                  currencyCode={cart?.totalPrice?.currencyCode}
                  fractionDigits={cart?.totalPrice?.fractionDigits ?? 2}
                  testId="checkout.order.total-price"
                  inherit
                />
              </Grid>
            </Grid>
          ) : undefined}

          {/* SHIPPING */}
          <Grid direction="row" gutters="top" flex>
            <Grid flex>
              <Typography color="primary-dark" variant="body2" noGutters>
                <FormattedMessage
                  defaultMessage="Shipping"
                  id="my-order.shipping"
                />
                :
              </Typography>
            </Grid>

            <Grid className={valueClassName}>
              <Price
                centAmount={cart?.shippingInfo?.price.centAmount}
                currencyCode={cart?.shippingInfo?.price.currencyCode}
                fractionDigits={cart?.shippingInfo?.price.fractionDigits ?? 2}
                testId="checkout.order.shipping-and-handling"
                inherit
              />
            </Grid>
          </Grid>

          {/* TAX */}
          <Grid direction="row" gutters="top" flex>
            <Grid flex>
              <Typography color="primary-dark" variant="body2" noGutters>
                <FormattedMessage defaultMessage="Tax" id="my-order.tax" />:
              </Typography>
            </Grid>

            <Grid className={valueClassName}>
              <Price
                centAmount={cart?.taxedPrice?.totalTax?.centAmount}
                currencyCode={cart?.taxedPrice?.totalTax?.currencyCode}
                fractionDigits={cart?.taxedPrice?.totalTax?.fractionDigits ?? 2}
                tbd={!showTaxes}
                testId="checkout.order.tax"
                inherit
              />
            </Grid>
          </Grid>

          {/* TOTAL PRICE */}
          <Grid direction="row" gutters="top" flex>
            <Grid flex>
              <Typography
                color="primary-dark"
                fontWeight="bold"
                variant="body"
                noGutters
              >
                <FormattedMessage
                  defaultMessage="Total price"
                  id="my-order.total-price"
                />
                :
              </Typography>
            </Grid>

            <Grid className={valueClassName}>
              <Price
                centAmount={cart?.taxedPrice?.totalGross?.centAmount}
                currencyCode={cart?.taxedPrice?.totalGross?.currencyCode}
                fractionDigits={
                  cart?.taxedPrice?.totalGross?.fractionDigits ?? 2
                }
                tbd={!showTaxes}
                testId="checkout.order.totalPrice"
                inherit
              />
            </Grid>
          </Grid>
        </div>

        {/* SUBSCRIPTION */}
        <SubscriptionLabel valueClassName={valueClassName} />
      </>
    )
  }

  /* istanbul ignore next */
  return (
    <Grid direction="row" flex>
      <>
        <Grid flex>
          <Typography
            className={className}
            color="primary-dark"
            fontWeight="bold"
            variant="body"
            noGutters
          >
            <FormattedMessage
              defaultMessage="{amount} products"
              id="checkout.order.total-products"
              values={{
                amount: amountOfProducts,
              }}
            />
          </Typography>
        </Grid>

        <Grid className={valueClassName}>
          <Typography
            color="primary-dark"
            component="span"
            variant="body"
            noGutters
          >
            <Price testId="checkout.order.total-price" inherit {...price} />
          </Typography>
        </Grid>
      </>

      <SubscriptionLabel valueClassName={valueClassName} />
    </Grid>
  )
}

export default PriceSummary

/* istanbul ignore next */
const SubscriptionLabel = ({ valueClassName }: { valueClassName: string }) => {
  const subscriptionLabel = useSelectedPlanName()

  return subscriptionLabel ? (
    <Grid direction="row" justify="flex-end" flex>
      <Grid className={valueClassName}>
        <Typography
          color="primary-dark"
          component="span"
          data-testid="checkout.order.active-subscription"
          variant="body2"
          noGutters
        >
          {`(${subscriptionLabel})`}
        </Typography>
      </Grid>
    </Grid>
  ) : null
}
