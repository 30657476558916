import type { Widget } from '@danone-global/internal/react/core'
import React from 'react'

import CheckoutOrder from './checkout-order/checkout-order.component'

export default {
  tags: ['checkout-content', 'checkout-v2'],

  Component: React.lazy(() => import('./checkout.widget')),
} as Widget

export { CheckoutOrder }
