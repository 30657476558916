import { Image } from '@danone-global/ct/interfaces'
import React from 'react'

export interface Props extends Omit<Partial<Image>, 'dimensions'> {
  width?: number | string

  height?: number | string

  maxHeight?: number | string
}

export const CtImage: React.FC<Props> = ({
  width = 56,
  height = 'auto',
  maxHeight = 56,
  url,
  label,
}) => (
  <img
    alt={label}
    src={url}
    style={{
      width,
      height,
      minWidth: width,
      minHeight: height,
      maxHeight,
      objectFit: 'contain',
    }}
  />
)

export default CtImage
