import { AddressInput } from '@danone-global/ct/interfaces'
import {
  COOKIE_DTC_LOGIN,
  COOKIE_DTC_SAP_EXTERNAL_ID,
  COOKIE_PREFILL_DATA,
  removeTokens,
  storeTokens,
} from '@danone-global/internal/graphql'
import * as Sentry from '@sentry/browser'
import Cookies from 'universal-cookie'

import {
  analytics,
  analyticsLoginEvent,
  analyticsLogoutEvent,
} from './analytics'
import { getCustomerToken } from './core.danone-api'
import {
  createCart,
  getActiveCart,
  getMyProfile,
  updateCart,
} from './core.graphql'
import { Core } from './core.provider'
import { getCustomValue, isLoggedIn, isValue, removeTypeName } from './core.utils'

export const DTC_AUTH_STATUS = 'DTC_AUTH_STATUS'

// The event might be fired before the shopping cart is rendered, so we'll set an additional variable in the session
const authLogin = () => {
  window.dispatchEvent(new CustomEvent('dtc-authentication-login'))
  sessionStorage.setItem(DTC_AUTH_STATUS, 'login')
}

const authLogout = () => {
  window.dispatchEvent(new CustomEvent('dtc-authentication-logout'))
  sessionStorage.setItem(DTC_AUTH_STATUS, 'logout')
}

const authDone = () => {
  window.dispatchEvent(new CustomEvent('dtc-authentication-stop'))
  sessionStorage.removeItem(DTC_AUTH_STATUS)
}

export const login =
  (core: Core) =>
  async (
    provider: string,
    externalCustomerId: string,
    prefillData = true,
    mergeCarts = true,
  ): Promise<boolean> => {
    // if (core.config.isDevelopment) {}
    console.log('login() 1: function called', {
      provider,
      externalCustomerId,
      prefillData,
      mergeCarts,
    })

    // Cookie is set, no login required
    const cookies = new Cookies()
    if (isLoggedIn()) {
      return true
    }

    // Trigger modal
    authLogin()

    try {
      const cart = await getActiveCart(core)
      const response = await getCustomerToken(
        cart,
        core,
        provider,
        externalCustomerId,
        mergeCarts,
      )

      // Set logged in
      if (!response?.access_token) {
        throw Error('No response from customer token endpoint')
      }

      console.log('login() 2: store token', {
        response,
      })
      storeTokens(response, core.config, true)

      // Force refresh Cart
      await getActiveCart(core, false)

      if (prefillData) {
        cookies.set(COOKIE_PREFILL_DATA, true, { path: '/' })
        await setProfileInfoOnCart(core)
      } else {
        cookies.remove(COOKIE_PREFILL_DATA, { path: '/' })
      }

      cookies.set(COOKIE_DTC_LOGIN, 'true', {
        maxAge: response.expires_in - 60,
        path: '/',
      })

      let externalId: string
      try {
        const profile = await getMyProfile(core.config)
        externalId = profile?.externalId
        const sap_external_id = getCustomValue(profile?.custom?.customFieldsRaw, 'sap_external_Id')
        cookies.set(COOKIE_DTC_SAP_EXTERNAL_ID, sap_external_id, {
          path: '/',
        })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        Sentry.captureException(err)
      } finally {
        analytics.push(
          await analyticsLoginEvent(
            cart,
            externalId,
            core.config,
            core.localeConfig,
          ),
        )
      }
      authDone()
      return true
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error during authentication', err)
      Sentry.captureException(err)

      authDone()
      logout(core)()
      return false
    }
  }

export const logout = (core: Core) => async () => {
  console.log('logout() 1: trigger modal')

  // Trigger modal
  authLogout()

  const cookies = new Cookies()
  cookies.remove(COOKIE_PREFILL_DATA, { path: '/' })
  removeTokens()

  // Create new cart
  console.log('logout() 2: create cart')
  const cart = await createCart(core)

  console.log('logout() 3: push analytics')
  analytics.push(
    await analyticsLogoutEvent(cart, undefined, core.config, core.localeConfig),
  )

  console.log('logout() 4: done, closing modal')
  authDone()
}

export const setProfileInfoOnCart = async (core: Core) => {
  try {
    const customer = await getMyProfile(core.config)

    await updateCart(
      core,
      true,
      [
        {
          setCustomerEmail: {
            email: customer.email,
          },
        },
        // Country is required and __typename should be removed.
        customer.defaultBillingAddress?.country
          ? {
              setBillingAddress: {
                address: removeTypeName(
                  customer.defaultBillingAddress,
                ) as AddressInput,
              },
            }
          : undefined,
        customer.defaultShippingAddress?.country
          ? {
              setShippingAddress: {
                address: removeTypeName(
                  customer.defaultShippingAddress,
                ) as AddressInput,
              },
            }
          : undefined,
      ].filter(isValue),
    )
  } catch (e) {
    console.error(e)
  }
}
