import { Config } from '@danone-global/internal/react/core'

import { baseEnvironment } from './environment.base'

export const environment: Config = {
  ...baseEnvironment,

  isProduction: true,

  isDevelopment: false,

  ctProjectKey: 'uk-medical-prd-danone',

  ctClientId: 'GlE4VC2F7m7sRgIA7WGnjoEB',

  ctClientSecret: 'Xx8oJb7SbxVudYPDiUzYqGQ7Qd9WrVqc',

  defaultChannelId: 'c39110cf-acfa-4241-aef2-0018c90725b2',

  channelKeyToId: {
    ni: 'f25654d1-5ae4-4c6b-a596-69c76c171ac4',
    roi: 'c39110cf-acfa-4241-aef2-0018c90725b2',
    uk: '955dc31e-7f2d-4552-beba-8eee38d66d11',
  },

  sentryDSN:
    'https://26e95ebef18de217c29264c5f256280b@o4506427613577216.ingest.sentry.io/4506433323008000',

  loqate: {
    apiKey: 'WN92-JT12-EC33-FH28',
    countries: ['IE', 'GB'],
  },
}
