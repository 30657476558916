import type { Widget } from '@danone-global/internal/react/core'
import React from 'react'

export default {
  tags: ['add-to-cart-button', 'add-to-cart-button-v2'],

  developmentProps: {
    sku: 'FOM01',
  },

  Component: React.lazy(() => import('./add-to-cart-button.widget')),
} as Widget
