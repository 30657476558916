import {
  BaseMoney,
  Cart,
  LineItemMode,
  Money,
  Order,
} from '@danone-global/ct/interfaces'

import { sum } from './collection'

export const calculateSubTotal = (cartOrOrder: Cart | Order) =>
  ({
    fractionDigits: 2,
    currencyCode: cartOrOrder.totalPrice.currencyCode,
    centAmount: sum(
      cartOrOrder.lineItems?.filter(
        (l) => l.lineItemMode !== LineItemMode.GiftLineItem,
      ),
      (lineItem) =>
        (lineItem.price.discounted?.value.centAmount ??
          lineItem.price.value.centAmount) * lineItem.quantity,
    ),
  } as Money)

/**
 * Get the price (or discounted price) and multiply with quantity
 */
export const priceTimesQuantity = (price: BaseMoney, quantity: number) => ({
  ...price,
  centAmount: price.centAmount * quantity,
})
