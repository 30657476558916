import { gql } from '@apollo/client'

import { addressFragment } from './address.fragment'
import { discountLineItemPriceFragment } from './discount-line-item-price.fragment'
import { moneyFragment } from './money.fragment'
import { lineItemVariantFragment } from './product-variant.fragment'

export const cartFragment = gql`
  fragment cart on Cart {
    __typename
    id
    anonymousId
    country
    version
    cartState
    store {
      key
    }
    locale
    customerEmail
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    discountCodes {
      state
      discountCode {
        code
        id
        cartDiscounts {
          id
        }
        code
        isActive
        nameAllLocales {
          locale
          value
        }
      }
    }
    shippingInfo {
      shippingMethodName
      shippingMethod {
        id
      }
      price {
        ...money
      }
      taxedPrice {
        totalGross {
          ...money
        }
        totalNet {
          ...money
        }
      }
      shippingRate {
        freeAbove {
          ...money
        }
      }
      discountedPrice {
        ...discount
      }
    }
    taxedPrice {
      taxPortions {
        amount {
          ...money
        }
        name
        rate
      }
      totalGross {
        ...money
      }
      totalNet {
        ...money
      }
      totalTax {
        ...money
      }
    }
    totalPrice {
      ...money
    }
    taxCalculationMode
    taxRoundingMode
    #    customLineItems {
    #      custom {
    #        customFieldsRaw {
    #          name
    #          value
    #        }
    #      }
    #      taxedPrice {
    #        totalGross {
    #          ...money
    #        }
    #      }
    #    }
    lineItems {
      id
      productKey
      productType {
        key
      } 
      nameAllLocales {
        locale
        value
      }
      custom {
        customFieldsRaw {
          name
          value
        }
      }
      quantity
      productId
      productType {
        key
        name
      }
      lineItemMode
      totalPrice {
        ...money
      }
      taxedPrice {
        totalTax {
          ...money
        }
        totalGross {
          ...money
        }
        totalNet {
          ...money
        }
      }
      variant {
        ...lineItemVariant
      }
      discountedPricePerQuantity {
        quantity
        discountedPrice {
          ...discount
        }
      }
      price {
        discounted {
          value {
            ...money
          }
        }
        value {
          ...money
        }
      }
      taxRate {
        amount
      }
      supplyChannel {
        id
      }
      distributionChannel {
        id
        nameAllLocales {
          locale
          value
        }
      }
    }
    shippingAddress {
      ...address
    }
    billingAddress {
      ...address
    }
  }

  ${moneyFragment}
  ${addressFragment}
  ${discountLineItemPriceFragment}
  ${lineItemVariantFragment}
`
