import 'whatwg-fetch'

import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { ApolloClientOptions } from '@apollo/client/core/ApolloClient'
import { setContext } from '@apollo/client/link/context'
import { Operation } from '@apollo/client/link/core/types'

import { getCommercetoolsToken } from './apollo-client.authentication'
import { Config } from './apollo-client.config.interface'
import log from './apollo-client.log'
import { typePolicies } from './apollo-client.type-policies'

let client: ApolloClient<never> = null
export let clientForProject = null

function getComputedClientURI(
  uri: string,
  { operationName, variables }: Operation,
): string {
  if (variables?.extraURIparams) {
    return `${uri}?op=${operationName}&${variables?.extraURIparams}`
  }
  return `${uri}?op=${operationName}`
}

export const getClient = (
  config: Config,
  options: Partial<ApolloClientOptions<unknown>> = {},
) => {
  if (client === null || clientForProject !== config.ctProjectKey) {
    const graphqlUri = `${config.ctHost}/${config.ctProjectKey}/graphql`
    log('Using uri', graphqlUri)

    const authLink = setContext(async (_, { headers }) => {
      const token = await getCommercetoolsToken(config)

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }
    })

    const httpLink = new HttpLink({
      uri: (operation) => getComputedClientURI(graphqlUri, operation),
    })

    // httLink must always be last
    const link = ApolloLink.from([authLink, httpLink])

    client = new ApolloClient({
      cache: new InMemoryCache({
        typePolicies,
      }),
      link,
      ...options,
    }) as ApolloClient<never>

    // This makes sure that when switching theme in Storybook a new client is created
    clientForProject = config.ctProjectKey
  }

  return client
}
