import { Font } from '@danone-global/internal/react/core'

import bariolBoldWoff from '../../../../../fonts/Bariol-Bold.woff'
import bariolBoldWoff2 from '../../../../../fonts/Bariol-Bold.woff2'
import bariolBoldItalicWoff from '../../../../../fonts/Bariol-Bold-Italic.woff'
import bariolBoldItalicWoff2 from '../../../../../fonts/Bariol-Bold-Italic.woff2'
import bariolLightWoff from '../../../../../fonts/Bariol-Light.woff'
import bariolLightWoff2 from '../../../../../fonts/Bariol-Light.woff2'
import bariolLightItalicWoff from '../../../../../fonts/Bariol-Light-Italic.woff'
import bariolLightItalicWoff2 from '../../../../../fonts/Bariol-Light-Italic.woff2'
import bariolRegularWoff from '../../../../../fonts/Bariol-Regular.woff'
import bariolRegularWoff2 from '../../../../../fonts/Bariol-Regular.woff2'
import bariolRegularItalicWoff from '../../../../../fonts/Bariol-Regular-Italic.woff'
import bariolRegularItalicWoff2 from '../../../../../fonts/Bariol-Regular-Italic.woff2'

export const fonts: Font[] = [
  {
    fontFamily: 'dach-nutricia',
    fontWeight: 300,
    fontStyle: 'normal',
    woff: bariolLightWoff,
    woff2: bariolLightWoff2,
  },
  {
    fontFamily: 'dach-nutricia',
    fontWeight: 300,
    fontStyle: 'italic',
    woff: bariolLightItalicWoff,
    woff2: bariolLightItalicWoff2,
  },
  {
    fontFamily: 'dach-nutricia',
    fontWeight: 400,
    fontStyle: 'normal',
    woff: bariolRegularWoff,
    woff2: bariolRegularWoff2,
  },
  {
    fontFamily: 'dach-nutricia',
    fontWeight: 400,
    fontStyle: 'italic',
    woff: bariolRegularItalicWoff,
    woff2: bariolRegularItalicWoff2,
  },
  {
    fontFamily: 'dach-nutricia',
    fontWeight: 800,
    fontStyle: 'normal',
    woff: bariolBoldWoff,
    woff2: bariolBoldWoff2,
  },
  {
    fontFamily: 'dach-nutricia',
    fontWeight: 800,
    fontStyle: 'italic',
    woff: bariolBoldItalicWoff,
    woff2: bariolBoldItalicWoff2,
  },
]
