import { Theme } from '@danone-global/internal/react/core'
import { useTheme } from '@material-ui/styles'
import React from 'react'

import Icon, { Props } from '../icon'

export const AddToCartIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => {
  const theme: Theme = useTheme()
  return (
    <Icon viewBox="0 0 27 27" {...props}>
      <g fill="inherit" fillRule="evenodd">
        <path d="m5.955 23.668c.778 0 1.412.635 1.412 1.413 0 .779-.634 1.414-1.412 1.414-.78 0-1.413-.635-1.413-1.414 0-.778.633-1.413 1.413-1.413zm11.1 0c.778 0 1.413.635 1.413 1.413 0 .779-.635 1.414-1.413 1.414s-1.413-.635-1.413-1.414c0-.778.635-1.413 1.413-1.413zm-12.663-18.668c.469 0 .867.366.905.834l.166 2.038 10.1517672.00080241c.3010409.66806806.7038475 1.280399 1.1886674 1.81724053l-11.1914346-.00104294.513 6.295.626-.077c.036-.005.073-.007.111-.007h11.503l1.1002047-4.3730441c.5572101.2165507 1.1490185.3638055 1.7648429.4311823l-1.2750476 5.0718618c-.102.404-.464.687-.881.687h-12.808l.19 2.309h12.618c.5 0 .908.408.908.909s-.408.908-.908.908h-13.456c-.469 0-.867-.366-.905-.834l-1.158-14.193h-2.646c-.501 0-.909-.408-.909-.908 0-.501.408-.908.909-.908z" />
        <path d="m22 0c2.7614237 0 5 2.23857625 5 5s-2.2385763 5-5 5-5-2.23857625-5-5 2.2385763-5 5-5zm0 2c-.3550402 0-.6428571.28781695-.6428571.64285714l-.0001429 1.71414286-1.7141429.00014286c-.3550402 0-.6428571.28781694-.6428571.64285714s.2878169.64285714.6428571.64285714l1.7141429-.00085714.0001429 1.71514286c0 .35504019.2878169.64285714.6428571.64285714s.6428571-.28781695.6428571-.64285714l-.0008571-1.71514286 1.7151429.00085714c.3550402 0 .6428571-.28781694.6428571-.64285714s-.2878169-.64285714-.6428571-.64285714l-1.7151429-.00014286.0008571-1.71414286c0-.35504019-.2878169-.64285714-.6428571-.64285714z" />
      </g>
    </Icon>
  )
}

export default AddToCartIcon
