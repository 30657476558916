import { Typography } from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { Control, FieldPathValue, useController } from 'react-hook-form'
import { Validate } from 'react-hook-form/dist/types/validator'

import FieldsError from '../error'

export interface Props {
  name: string

  label?: string

  control: Control<any>

  required?: boolean

  className?: string

  defaultValue?: string

  validate?:
    | Validate<FieldPathValue<any, any>>
    | Record<string, Validate<FieldPathValue<any, any>>>

  options: Option[]
}

export interface Option {
  label: string

  value: string
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      marginBottom: theme.spacing(),

      '& label': {
        display: 'block',
        marginBottom: theme.spacing(),
      },
    },

    options: {
      display: 'flex',
      margin: theme.spacing(-0.5),
    },

    option: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius(),
      border: theme.utils.createStyle(
        theme.utils.getSize(1, { forceUnit: true }),
        'solid',
        '#eaeaea',
      ),
      padding: theme.spacing(1, 1.5),
      overflow: 'hidden',
      cursor: 'pointer',

      '& input': {
        width: 0,
        height: 0,
        display: 'none',
      },

      '& label': {
        marginLeft: 10,
        marginBottom: 0,
        lineHeight: theme.utils.getSize(18, { forceUnit: true }),
        cursor: 'pointer',
      },

      '& label::before, label::after': {
        display: 'none !important',
      },
    },

    active: {
      borderColor: theme.palette.primary.main,

      '& $icon': {
        borderColor: theme.palette.primary.main,

        '&::after': {
          position: 'absolute',
          content: '""',
          top: '50%',
          left: '50%',
          display: 'block',
          width: theme.utils.getSize(9),
          height: theme.utils.getSize(9),
          borderRadius: '100%',
          backgroundColor: theme.palette.primary.main,
          transform: 'translate(-50%, -50%)',
        },
      },
    },

    icon: {
      position: 'relative',
      content: '""',
      display: 'block',
      width: theme.utils.getSize(16),
      height: theme.utils.getSize(16),
      borderRadius: '100%',
      backgroundColor: theme.utils.hexToRgba(theme.palette.primary.main, 0.1),
      border: theme.utils.createStyle(
        theme.utils.getSize(1, { forceUnit: true }),
        'solid',
        theme.utils.hexToRgba(theme.palette.primary.main, 0.15),
      ),
      boxShadow: 'inset 1px 1px 1px 0px rgb(0 0 0 / 10%)',
    },
  }),
  { name: 'FieldsRadio' },
)

export const FieldsRadio: React.FC<Props> = ({
  name,
  label,
  control,
  required = false,
  className,
  defaultValue = '',
  validate,
  options,
}) => {
  const classes = useStyles()
  const { field: input, fieldState } = useController({
    name,
    control,
    rules: {
      required,
      validate,
    },
    defaultValue,
  })

  const handleOptionClick = React.useCallback(
    (option: Option) => () => {
      input.onChange(option.value)
    },
    [],
  )

  return (
    <div
      className={clsx(classes.root, className)}
      data-testid={`field-${name}`}
    >
      {label && (
        <label htmlFor={name}>
          <Typography component="span" variant="h5">
            {label}
            {required && <span> *</span>}
          </Typography>
        </label>
      )}

      <div className={classes.options}>
        {options.map((option, index) => (
          <div
            key={option.value}
            className={clsx(
              classes.option,
              option.value === input.value && classes.active,
            )}
            data-testid={`btn.option-${name}.${option.value}`}
            onClick={handleOptionClick(option)}
          >
            <span className={classes.icon} />

            <input
              checked={option.value === input.value}
              id={`${name}-${index}`}
              name={name}
              type="radio"
              value={option.value}
              readOnly
            />

            <Typography
              component="label"
              fontWeight="bold"
              htmlFor={`${name}-${index}`}
              variant="body2"
              noGutters
            >
              {option.label}
            </Typography>
          </div>
        ))}
      </div>

      {fieldState.error && (
        <FieldsError
          data-testid={`error-field-${name}.${fieldState.error.type}`}
          error={fieldState.error}
        />
      )}
    </div>
  )
}

export default FieldsRadio
