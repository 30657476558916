import { LineItemMode } from '@danone-global/ct/interfaces'
import { ArrowLeftIcon } from '@danone-global/internal/react/components/icons'
import LineItem from '@danone-global/internal/react/components/line-item'
import MyOrder from '@danone-global/internal/react/components/my-order'
import {
  Button,
  Container,
  Grid,
  Typography,
} from '@danone-global/internal/react/components/ui'
import {
  filterGiftLineItem,
  Theme,
  useCart,
  useCore,
} from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export interface Props {
  close: () => void

  editOrder: () => void
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: '100vh',
      zIndex: 1001,
      overflowY: 'scroll',
      backgroundColor: theme.palette.background.default,
    },

    icon: {
      marginRight: theme.spacing(),
    },

    container: {
      padding: theme.spacing(2),
    },

    titleContainer: {
      borderRadius: 0,
      paddingTop: theme.spacing(6),
      cursor: 'pointer',
    },

    actions: {},
  }),
  { name: 'CheckoutViewProducts' },
)

export const ViewProducts: React.FC<Props> = ({ close, editOrder }) => {
  const [cart] = useCart()
  const classes = useStyles()
  const { features } = useCore()

  React.useEffect(() => {
    // Make sure the body cant scroll
    document.body.classList.add('dtc-mobile-no-scroll')

    return () => {
      document.body.classList.remove('dtc-mobile-no-scroll')
    }
  }, [])

  return (
    <Grid className={classes.root} data-testid="widget-checkout">
      <Container
        alignItems="center"
        className={clsx(classes.container, classes.titleContainer)}
        data-testid="btn.back-to-checkout"
        onClick={close}
        xs={12}
        item
      >
        <ArrowLeftIcon className={classes.icon} color="primary" size={24} />

        <Typography variant="h2" noGutters>
          <FormattedMessage
            defaultMessage="My Order"
            id="widgets.checkout.view-products-title"
          />
        </Typography>
      </Container>

      <Grid
        className={clsx(classes.container, classes.actions)}
        spacing={16}
        container
      >
        <Grid xs={12} item>
          <Container>
            {cart?.lineItems
              .filter(filterGiftLineItem(features))
              .sort((l) =>
                l.lineItemMode === LineItemMode.GiftLineItem ? 0 : -1,
              )
              .map((lineItem) => (
                <LineItem
                  key={lineItem.id}
                  lineItem={lineItem}
                  variant="checkout-mobile"
                />
              ))}
          </Container>
        </Grid>

        <Grid xs={12} item>
          <MyOrder variant="checkout" />
        </Grid>

        <Grid xs={12} item>
          <Button
            data-testid="btn.mobile-edit-order"
            onClick={editOrder}
            fullWidth
          >
            <FormattedMessage
              defaultMessage="Edit order"
              id="btn.checkout.edit-order"
            />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ViewProducts
