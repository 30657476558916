import {
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  useMutation as useApolloMutation,
  useQuery,
} from '@apollo/client'
import type {
  CategoryCreated,
  LocalizedString,
  RawCustomField,
  Scalars,
} from '@danone-global/ct/interfaces'
import { useTheme } from '@material-ui/styles'
import { DocumentNode, OperationDefinitionNode } from 'graphql'
import { FieldNode } from 'graphql/language/ast'
import React from 'react'

import { GET_CATEGORY } from '../core.graphql'
import { Theme } from '../theme'
import { useCart } from './useCart'
import { useCore } from './useCore'
export * from './useCart'
export * from './useCore'
export * from './useLineItemErrors'
export * from './useMyOrders'
export * from './useScript'

/**
 * Somehow the local value is not updated inside the mutation so we keep track here
 * of the latest cart version
 */
let latestCartVersion, newCardId

/**
 * Helper to add cart id and version to all the updateCart mutations
 */
export const useMutation = <TData = never, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> => {
  const [originalExecuteMutation, result] = useApolloMutation<
    TData,
    TVariables
  >(mutation, options)
  const [cart, loading, refetch] = useCart()

  // if the mutation is for updateMyCart, we want to pass in version and cart ID by default
  const operationDefinition = mutation.definitions.find(
    (e) => e.kind === 'OperationDefinition',
  ) as OperationDefinitionNode

  const selectionSet = operationDefinition && operationDefinition.selectionSet
  const isUpdateMyCart =
    selectionSet &&
    selectionSet.selections.some(
      (selectionNode: FieldNode) => selectionNode.name.value === 'updateMyCart',
    )

  let executeMutation = originalExecuteMutation

  // If the call is updateMyCart then add id and version
  if (isUpdateMyCart) {
    executeMutation = async (
      options?: MutationFunctionOptions<TData, TVariables>,
    ): Promise<FetchResult<TData>> => {
      
      const { data } = await refetch()
      const newCart = data?.me?.carts?.results?.[0]
      latestCartVersion = newCart?.version
      newCardId = newCart?.id


      const opts = {
        ...options,
        variables: {
          id: newCardId,
          version: latestCartVersion,
          ...options?.variables,
        },
      }

      return originalExecuteMutation(opts).then((response) => {
        let event

        if (typeof Event === 'function') {
          event = new Event('__danone_open_source_cart_update')
        } else {
          event = document.createEvent('Event')
          event.initEvent('__danone_open_source_cart_update', false, false)
        }

        // Dispatch event so uikit will update it's cart
        window.dispatchEvent(event)

        return response
      })
    }
  }

  return [executeMutation, result]
}

export const useCategoryCustomFields = (categoryKey: string) => {
  return useQuery<CategoryCreated>(GET_CATEGORY, {
    variables: { key: categoryKey },
  })
}

export const useLocalisedString = (strings: LocalizedString[]): string => {
  const [cart] = useCart()

  return React.useMemo(() => {
    const string = strings?.find((string) => string.locale === cart?.locale)

    if (string) {
      return string.value
    }

    return ''
  }, [strings, cart?.locale])
}

/**
 * Gets a value from the custom fields
 */
export const useFromCustom = <T = Scalars['Json']>(
  rawCustomFields: RawCustomField[],
  attribute: string,
  fallback?: any,
  lastModifiedAt?: any,
): T => {
  return React.useMemo(() => {
    return (
      rawCustomFields.find(({ name }) => name === attribute)?.value || fallback
    )
  }, [rawCustomFields, attribute, fallback, lastModifiedAt])
}

/**
 * Checks if the current state is below a certain breakpoint
 */
export const useIsBelowBreakpoint = (breakpoint): boolean => {
  const theme = useTheme<Theme>()
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth <= theme.breakpoints.values[breakpoint],
  )

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth <= theme.breakpoints.values[breakpoint])
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return isMobile
}
