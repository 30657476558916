import React from 'react'
import { register } from '@danone-global/internal/react/utils/abode'

import log from './core.log'
import { Core, CoreProvider } from './core.provider'

export interface Widget {
  /**
   * Tags the widget will be registered on
   */
  tags: string[]

  /**
   * Props that will be passed to the component in development
   */
  developmentProps?: {
    [key: string]: unknown
  }

  /**
   * The component that will be rendered, lazy loaded
   */
  Component: React.LazyExoticComponent<any>

  /**
   * Callback fired when the widget is registered
   */
  onRegister?: () => void
}

export const registerWidgets = (widgets: Widget[], core: Core) => {
  for (const widget of widgets) {
    registerWidget(widget, core)
  }
}

export const registerWidget = (widget: Widget, core: Core) => {
  if (!widget.tags || widget.tags.length === 0) {
    throw new Error(
      "Widget needs tags! Give the widget a static property with it's tags!",
    )
  }

  if (!widget.developmentProps || process.env.NODE_ENV !== 'development') {
    // Clear the development props when we are not in development
    widget.developmentProps = {}
  }

  if (widget.onRegister) {
    widget.onRegister()
  }

  // Get the override props from config for this widget
  let overrideProps = {}
  widget.tags.forEach((tag) => {
    if (core.config?.overrides?.[tag]) {
      overrideProps = {
        ...core.config?.overrides?.[tag],
      }
    }
  })

  // Register the widget with adobe
  widget.tags.forEach((tag) => {
    log('Register %s', tag)

    register(tag, () =>
      // eslint-disable-next-line react/display-name
      React.memo((props) => (
        <CoreProvider core={core}>
          <React.Suspense fallback={<div />}>
            <widget.Component
              {...widget.developmentProps}
              {...overrideProps}
              {...props}
            />
          </React.Suspense>
        </CoreProvider>
      )),
    )
  })
}
