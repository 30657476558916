import { Typography } from './'

export const createTypography = (options: Typography): Typography => {
  const { fontFamily, ...rest } = options

  return {
    fontFamily,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    ...rest,
  }
}
