import { LineItem, Order } from '@danone-global/ct/interfaces'
import { Typography } from '@danone-global/internal/react/components/ui'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { getCustomValue } from './core.utils'

// Define the possible product CB subscription types (Key is the CB Price Plan ID last chars after the -)
const cbSubscriptionTypes: Record<string, ReactNode> = {
  Daily: (
    <FormattedMessage
      defaultMessage="Daily Subscription"
      id="chargebee.subscriptions.Daily"
    />
  ),
  Weekly: (
    <FormattedMessage
      defaultMessage="Weekly Subscription"
      id="chargebee.subscriptions.Weekly"
    />
  ),
  '2Weeks': (
    <FormattedMessage
      defaultMessage="Every 2 Weeks"
      id="chargebee.subscriptions.2Weeks"
    />
  ),
  '3Weeks': (
    <FormattedMessage
      defaultMessage="Every 3 Weeks"
      id="chargebee.subscriptions.3Weeks"
    />
  ),
  '5Weeks': (
    <FormattedMessage
      defaultMessage="Every 5 Weeks"
      id="chargebee.subscriptions.5Weeks"
    />
  ),
  Monthly: (
    <FormattedMessage
      defaultMessage="Monthly Subscription"
      id="chargebee.subscriptions.Monthly"
    />
  ),
}

export const LineItemSubscriptionLabel = ({
  lineItem,
  order,
  className,
  color,
  variant,
}: {
  lineItem: LineItem | undefined
  order?: Order
  className?: string
  color?: string
  variant?: string
}) => {
  // Check at order level (recurrent orders)
  const orderSubscription = order?.custom?.customFieldsRaw?.find(
    (field) => field.name === 'cbPricePlanId' && field?.value,
  )?.value

  // Check at line item level (initial orders that are dealt as single orders but including subscription in lineitem)
  const lineItemSubscription = lineItem?.custom?.customFieldsRaw?.find(
    (field) => field?.name === 'cbPricePlanId' && field?.value,
  )?.value

  const subscription = orderSubscription || lineItemSubscription

  // Get the key for translations, we set a pattern that all plans need to end with the frequency (e.g. -Monthly, -Weekly, -2Weeks, etc..)
  const subscriptionKey = subscription?.substring(
    subscription?.lastIndexOf('-') + 1,
  )

  if (!cbSubscriptionTypes?.[subscriptionKey]) {
    return null
  }

  const props: Record<string, string> = {
    ...(color && { color }),
    ...(variant && { variant }),
  }

  return (
    <Typography className={clsx(className)} {...props}>
      {cbSubscriptionTypes?.[subscriptionKey]}
    </Typography>
  )
}

/** Chargebee V3 migration feature */
export const LineItemChargebeeSubscriptionLabelV3 = ({ lineItem }) => {
  const isSubscription = getCustomValue(
    lineItem?.custom?.customFieldsRaw,
    'is_subscription',
  )
  const labelText = lineItem?.distributionChannel?.nameAllLocales[0]?.value

  return (
    isSubscription ?
      <Typography>
        {labelText}
      </Typography>
      : null
  )
}
