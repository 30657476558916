import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

import { Typography } from '../typography'

export interface Props {
  variant?: 'default' | 'global'

  message: string

  indicator?: 'default' | 'top' | 'top-left' | 'top-right'

  error?: boolean

  className?: string

  testId?: string
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
      border: theme.utils.createStyle(
        theme.utils.getSize(1, { forceUnit: true }),
        'solid',
        theme.palette.primary.main,
      ),
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },

    default: {
      width: 'fit-content',
    },

    global: {
      width: '100%',
    },

    indicator: {
      '&::before': {
        position: 'absolute',
        top: theme.utils.getSize(-10, { forceUnit: true }),
        content: '""',
        border: theme.utils.createStyle('solid', theme.palette.primary.main),
        borderWidth: theme.utils.createStyle(
          theme.utils.getSize(1, { forceUnit: true }),
          theme.utils.getSize(1, { forceUnit: true }),
          0,
          0,
        ),
        width: theme.utils.getSize(10, { forceUnit: true }),
        height: theme.utils.getSize(10, { forceUnit: true }),
        transform: 'rotate(-45deg) translate(-50%)',
        backgroundColor: theme.palette.primary.light,
        borderRadius: theme.utils.getSize(3),
      },
    },

    topIndicator: {
      '&::before': {
        left: '50%',
      },
    },

    topLeftIndicator: {
      '&::before': {
        left: theme.utils.getSize(30, { forceUnit: true }),
      },
    },

    topRightIndicator: {
      '&::before': {
        right: theme.utils.getSize(30, { forceUnit: true }),
      },
    },
  }),
  { name: 'Notification' },
)

export const Notification: React.FC<Props> = ({
  variant = 'default',
  message,
  indicator = 'default',
  error = false,
  className,
  testId = null,
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.root,
        classes[variant],
        indicator !== 'default' && classes.indicator,
        indicator === 'top' && classes.topIndicator,
        indicator === 'top-left' && classes.topLeftIndicator,
        indicator === 'top-right' && classes.topRightIndicator,
        className,
      )}
      data-testid={testId || `${variant}-${error ? 'error-' : ''}notification`}
    >
      <Typography color="inherit" fontWeight="medium" noGutters>
        {message}
      </Typography>
    </div>
  )
}

export default Notification
