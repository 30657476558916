import { useCart, useCore } from '@danone-global/internal/react/core'
import React from 'react'
import { useIntl } from 'react-intl'

export interface OpenCheckoutOptions {
  hostedPage: () => void
}

declare global {
  interface Window {
    Chargebee: {
      init: (options: { site: string }) => void
      getInstance: () => {
        createChargebeePortal: () => {
          open: (options: any) => void
        }
        openCheckout: (options: OpenCheckoutOptions) => void
      }
    }
  }
}

let loadedChargebee = false

export const useChargebee = (): boolean => {
  const { config } = useCore()
  const [loaded, setLoaded] = React.useState(loadedChargebee)

  React.useEffect(() => {
    let script: HTMLScriptElement | null = null

    // Check if Chargebee is not loaded yet and make sure we have a site configured
    if (!loadedChargebee && config?.chargebee?.site) {
      loadedChargebee = true

      script = document.createElement('script')

      script.src = 'https://js.chargebee.com/v2/chargebee.js'
      script.async = true
      script.onload = () => {
        window.Chargebee.init({
          site: config.chargebee.site,
        })

        setLoaded(true)
      }

      document.body.appendChild(script)
    }

    return () => {
      if (loadedChargebee && script) {
        loadedChargebee = false

        if (document.body.contains(script)) {
          document.body.removeChild(script)
        }
      }
    }
  }, [])

  return loaded
}

export const useAvailableSubscriptions = () => {
  const intl = useIntl()

  return React.useMemo(() => {
    return [
      {
        id: null,
        label: intl.formatMessage({
          id: 'chargebee.subscriptions.none',
          defaultMessage: 'No Subscription',
        }),
      },
      {
        id: '3-weeks',
        label: intl.formatMessage({
          id: 'chargebee.subscriptions.3-weeks',
          defaultMessage: 'Every 3 weeks',
        }),
      },
      {
        id: '4-weeks',
        label: intl.formatMessage({
          id: 'chargebee.subscriptions.4-weeks',
          defaultMessage: 'Every 4 weeks',
        }),
      },
      {
        id: '5-weeks',
        label: intl.formatMessage({
          id: 'chargebee.subscriptions.5-weeks',
          defaultMessage: 'Every 5 weeks',
        }),
      },
    ]
  }, [intl])
}

export const useSelectedPlanId = () => {
  const [cart] = useCart()

  return React.useMemo(() => {
    const subscriptionSelected = cart?.custom?.customFieldsRaw?.find(
      ({ name }) => name === 'subscriptionChargebeePlanId',
    )

    if (subscriptionSelected) {
      return subscriptionSelected.value
    }

    return null
  }, [cart?.custom])
}

export const useSelectedPlanName = () => {
  const planId = useSelectedPlanId()
  const options = useAvailableSubscriptions()

  if (!planId) {
    return ''
  }

  return options.find(({ id }) => id === planId)?.label
}

export default useChargebee
