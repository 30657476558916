import { makeStyles } from '@material-ui/styles'
import React from 'react'

import { Theme } from './theme'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    '@global': {
      html: {
        WebkitFontSmoothing: 'antialiased', // Antialiasing.
        MozOsxFontSmoothing: 'grayscale', // Antialiasing.
        // Change from `box-sizing: content-box` so that `width`
        // is not affected by `padding` or `border`.
        boxSizing: 'border-box',
        // Fix font resize problem in iOS
        WebkitTextSizeAdjust: '100%',
      },

      '*, *::before, *::after': {
        boxSizing: 'inherit',
      },

      'strong, b': {
        fontWeight: theme.typography.fontWeightBold,
      },

      body: {
        margin: 0, // Remove the margin in all browsers.
        color: theme.palette.text.primary,
        // ...theme.typography.body1,
        backgroundColor: theme.palette.background.default,
        // Add support for document.body.requestFullScreen().
        // Other elements, if background transparent, are not supported.
        '&::backdrop': {
          backgroundColor: theme.palette.background.default,
        },

        // Global available to prevent the body to scroll on mobile
        '&.dtc-mobile-no-scroll': {
          [theme.breakpoints.mobile()]: {
            overflowY: 'hidden',
          },
        },
      },

      '@font-face': theme.typography.fonts.map((font) => ({
        fontFamily: font.fontFamily,
        fontStyle: font.fontStyle,
        fontWeight: font.fontWeight,
        src:
          `url(${font.woff}) format("woff")` +
          (font.woff2 ? `, url(${font.woff2}) format("woff2")` : ''),
      })),
    },
  }),
  { name: 'CssBaseline' },
)

export const useExampleSiteStyles = makeStyles(
  (theme: Theme) => {
    if (theme.exampleSiteStyles) {
      return theme.exampleSiteStyles
    }

    return {}
  },
  { name: 'ExampleSiteStyles' },
)

export const CssBaseline = React.memo(function CssBaseLine() {
  useStyles()
  useExampleSiteStyles()

  return null
})
