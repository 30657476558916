import {
  Cart,
  DiscountCodeInfo,
  DiscountedLineItemPrice,
  LineItem,
  LineItemMode,
  Order,
} from '@danone-global/ct/interfaces'
import { isValue, sum, uniqueBy, useCore } from '@danone-global/internal/react/core'

import { DiscountInterface } from './'

export const getDiscounts = (orderOrCart: Order | Cart) =>
  getDiscountObjectsForLineItems(
    orderOrCart.lineItems.filter(
      (l) => l.lineItemMode !== LineItemMode.GiftLineItem,
    ),
    orderOrCart.discountCodes,
  )
    .filter(uniqueBy((e) => e.id))
    .map(
      (discount): DiscountInterface => ({
        ...discount,
        centAmount: getDiscountCentAmountForLineItems(
          orderOrCart.lineItems,
          discount,
        ),
      }),
    )
    .filter((discount) => discount.centAmount !== 0)

const getDiscountObjectsForLineItems = (
  lineItems: LineItem[],
  discountCodes: DiscountCodeInfo[],
) =>
  lineItems.flatMap((lineItem) =>
    lineItem.discountedPricePerQuantity.flatMap((discountPerQuant) =>
      getDiscountObjectFromDiscountedPrice(
        discountPerQuant.discountedPrice,
        discountCodes,
      ),
    ),
  )

const getDiscountCentAmountForLineItems = (
  lineItems: LineItem[],
  discount: DiscountInterface,
): number =>
  sum(
    lineItems.flatMap((l) => l.discountedPricePerQuantity),
    (discountedPricePerQuantity) =>
      discountedPricePerQuantity.quantity *
      getDiscountCentAmountFromDiscountedPrice(
        discountedPricePerQuantity.discountedPrice,
        discount,
      ),
  )

const getDiscountObjectFromDiscountedPrice = (
  discountedPrice: DiscountedLineItemPrice,
  discountCodes: DiscountCodeInfo[],
): DiscountInterface[] => {
  const {localeConfig} = useCore()
  if (!discountedPrice) {
    return []
  }

  return discountedPrice.includedDiscounts
    .filter(({ discount }) => discount && discount.isActive)
    .map(({ discount }) => {
      const id = discount?.id

      const discountCode = discountCodes.find((discountCode) =>
        discountCode.discountCode?.cartDiscounts?.some((i) => i.id === id),
      )

      return {
        id,
        nameAllLocales: discountCode ? discountCode?.discountCode?.nameAllLocales : discount?.nameAllLocales.filter((i) => i.locale === localeConfig.locale),
        centAmount: 0,
        discountCodeId: discountCode?.discountCode?.id,
        sortOrder: discount?.sortOrder,
        isDiscountRemoveBtn: !discountCode ? true : false, // if discountCode is not found, it means it's not matching
      } as DiscountInterface
    })
    .filter(isValue)
}

const getDiscountCentAmountFromDiscountedPrice = (
  discountedPrice: DiscountedLineItemPrice,
  discount: DiscountInterface,
) =>
  sum(
    discountedPrice.includedDiscounts.filter(
      (includedDiscount) => includedDiscount.discount?.id === discount.id,
    ),
    (discount) => discount.discountedAmount.centAmount,
  )

export const getDiscountsNotMatching = (cart?: Cart) =>
  cart?.discountCodes.filter((i) => i.state === 'DoesNotMatchCart')
