import { useQuery } from '@apollo/client'
import { ShippingMethodsByCartInterface } from '@danone-global/ct/interfaces'
import {
  DeliveryDate,
  Grid,
  Hr,
  Typography,
} from '@danone-global/internal/react/components/ui'
import {
  Theme,
  UPDATE_ACTIVE_CART,
  useCart,
  useCore,
  useFromCustom,
  useMutation,
} from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import DeliveryMethod from './delivery-method'
import { GET_SHIPPING_METHODS } from './delivery-methods.graphql'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& hr': {
        margin: theme.spacing(4, 0),
      },
    },

    deliveryDate: {
      '& h5': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
      },

      '& .deliveryDate > div': {
        borderColor: '#DDD',
        borderWidth: 1,
        borderStyle: 'solid',
      },
    },
  }),
  { name: 'DeliveryMethods' },
)

export const Delivery: React.FC = () => {
  const [updateCart] = useMutation(UPDATE_ACTIVE_CART)
  const [cart] = useCart()
  const { config, localeConfig } = useCore()
  const deliveryCategory = config.deliveryCategory
  const deliveryDate = useFromCustom<string>(
    cart?.custom?.customFieldsRaw ?? [],
    'deliveryDate',
  )
  const { data } = useQuery<ShippingMethodsByCartInterface>(
    GET_SHIPPING_METHODS,
    {
      variables: {
        cartId: cart?.id,
        locale: localeConfig.locale,
      },
    },
  )
  const classes = useStyles()

  // Makes sure that the first shipping method is always selected (this fixes the issue with
  // the delivery method not being selected when switching between subscription or non-subscription
  useEffect(() => {
    if (
      cart?.shippingInfo &&
      data &&
      data.shippingMethodsByCart &&
      data.shippingMethodsByCart.length > 0 &&
      !data.shippingMethodsByCart.some(
        (method) => method.id === cart?.shippingInfo?.shippingMethod?.id,
      )
    ) {
      updateCart({
        variables: {
          actions: [
            {
              setShippingMethod: {
                shippingMethod: {
                  id: data.shippingMethodsByCart[0].id,
                },
              },
            },
          ],
        },
      })
    }
  }, [data, cart?.shippingInfo])

  if (!data || data?.shippingMethodsByCart?.length < 1) {
    return null
  }

  return (
    <Grid className={classes.root}>
      <Hr dashed />

      <Grid flex>
        <Typography variant="h5" noGutters>
          <FormattedMessage
            defaultMessage="Choose delivery option"
            id="widgets.checkout.step-2.choose-delivery"
          />
        </Typography>
      </Grid>

      {data?.shippingMethodsByCart?.map((shippingMethod) => (
        <DeliveryMethod key={shippingMethod.id} {...shippingMethod} />
      ))}

      {deliveryCategory && deliveryDate && (
        <div className={classes.deliveryDate}>
          <Typography variant="h5" noGutters>
            <FormattedMessage
              defaultMessage="Delivery date"
              id="delivery-date.title"
            />
          </Typography>
          <DeliveryDate
            date={deliveryDate}
            deliveryCategory={deliveryCategory}
          />
        </div>
      )}
    </Grid>
  )
}

export default Delivery
