import { Mixins, MixinsGetSizeOptions, MixinsWithHelpers, Typography } from './'

export const createMixins = (
  options: Mixins,
  typography: Typography,
  unit,
): MixinsWithHelpers => {
  const { mobileMultiplier = 1, pxToRem: pxToRem2 } = options

  const { fontSize = 14, htmlFontSize = 16 } = typography

  const coef = fontSize / 14
  const pxToRem = pxToRem2 || ((size) => `${(size / htmlFontSize) * coef}rem`)

  const getSize = (
    px,
    { isMobile = false, forceUnit = false }: MixinsGetSizeOptions = {},
  ): string | number => {
    let usePx = px

    if (isMobile && unit === 'rem') {
      usePx = px * mobileMultiplier
    }

    if (unit === 'px') {
      if (forceUnit) {
        return `${usePx}px`
      }

      return usePx
    }

    return pxToRem(usePx)
  }

  return {
    getFontSize: (
      px,
      { isMobile = false, forceUnit = false }: MixinsGetSizeOptions = {},
    ): string | number => {
      return getSize(px * coef, { isMobile, forceUnit })
    },
    getSize,
    createStyle: (...args) => args.join(' '),
    hexToRgba: (hex: string, alpha = 1): string => {
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        let rgba
        rgba = hex.substring(1).split('')
        if (rgba.length === 3) {
          rgba = [rgba[0], rgba[0], rgba[1], rgba[1], rgba[2], rgba[2]]
        }

        rgba = '0x' + rgba.join('')
        return `rgba(${[(rgba >> 16) & 255, (rgba >> 8) & 255, rgba & 255].join(
          ',',
        )},${alpha})`
      }

      return hex
    },
  }
}
