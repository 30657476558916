import { gql } from '@apollo/client'

export const addressFragment = gql`
  fragment address on Address {
    externalId
    title
    firstName
    lastName
    streetName
    streetNumber
    additionalStreetInfo
    city
    postalCode
    country
    phone
    mobile
    email
    state
  }
`
