/**
 * Check if string contains illegal characters.
 * Used for checking form text input fields.
 */
const REGEX_CHINESE =
  /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u

const REGEX_NOT_ALLOWED_CHARS = /[!"§$%=?]/

const REGEX_EMOJI =
  /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]+/u

export const containsIllegalCharacters = {
  illegalCharacters: (value: string): boolean => {
    return (
      !value ||
      (!REGEX_CHINESE.test(value) &&
        !REGEX_NOT_ALLOWED_CHARS.test(value) &&
        !REGEX_EMOJI.test(value))
    )
  },
}

export const containsEmoji = {
  illegalCharacters: (value: string): boolean => {
    return !value || !REGEX_EMOJI.test(value)
  },
}
