import { useLazyQuery } from '@apollo/client'
import { Order, Query } from '@danone-global/ct/interfaces'
import { useEffect, useState } from 'react'

import { GET_MY_ORDERS } from '..'
import { uniqueBy } from '../core.utils'
import { useCore } from '.'

/**
 * Get orders of current user
 */
export interface IUseMyOrders {
  orders: Order[]
  total: number
  loading: boolean
}

export const useMyOrders = ({
  itemsPerPage,
  currentPage,
  where,
}: {
  itemsPerPage: number
  currentPage: number
  where?: string
}): IUseMyOrders => {
  const [orders, setOrders] = useState([])
  const { localeConfig, config } = useCore()

  const [getMyOrders, { data: ordersData, loading }] = useLazyQuery<Query>(
    GET_MY_ORDERS,
    {
      variables: { where: `store(key = "${config.ctStoreKey}")` },
    },
  )

  useEffect(() => {
    getMyOrders({
      variables: {
        locale: localeConfig.locale,
        offset: currentPage * itemsPerPage,
        limit: itemsPerPage,
        sorting: 'createdAt desc',
        currency: localeConfig.currency,
        country: localeConfig.country,
        store: 'AM'
      },
    })
  }, [
    getMyOrders,
    localeConfig.locale,
    itemsPerPage,
    currentPage,
    localeConfig,
  ])

  useEffect(() => {
    setOrders([...orders, ...(ordersData?.me?.orders.results || [])])
    // Do not listen to 'orders', this would create a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersData])

  const filteredOrders = orders.filter(uniqueBy((o) => o.id))

  return {
    orders: filteredOrders,
    total: ordersData?.me?.orders.total || 0,
    loading: loading || false,
  }
}
