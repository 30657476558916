import { createTheme } from '@danone-global/internal/react/core'

import { fonts } from './theme.fonts'

/**
 * This theme uses a generate function for theme and overrides.
 * That way we can make small overrides (in dach-nutricia and dach-loprofin).
 */

const fontFallback = 'Arial, Helvetica, sans-serif'

const nutriciaStyle = {
  gradient: 'linear-gradient(90deg, rgb(43, 60, 144) 0%, rgb(81, 51, 139) 70%)',
  colors: {
    primary: {
      light: '#EDEAF3',
      main: '#51338b',
      dark: '#2B3C90',
    },
    text: {
      light: '#45484B',
      primary: '#313183',
    },
    ui: {
      black: '#16181C',
      white: '#F4F4F4',
      orange: '#FE6600',
    },
    status: {
      success: '#5AA423',
    },
  },
  border: {
    primary: '2px solid #51338b',
    default: '1px solid #DDD',
    radius: '12px',
    color: '#DDD',
  },
  boxShadow: {
    inner: 'inset 2px 2px 3px 0 rgba(0,0,0,0.1)',
  },
}

const generateTheme = (style: typeof nutriciaStyle) =>
  createTheme({
    direction: 'ltr',

    unit: 'px',

    typography: {
      fonts,
      fontFamily: `dach-nutricia, Calibri, ${fontFallback}`,
      secondaryFontFamily: `dach-nutricia, Calibri, ${fontFallback}`,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 400,
      fontWeightBold: 800,
    },

    palette: {
      text: {
        primary: style.colors.ui.black,
        secondary: style.colors.ui.white,
        disabled: '#8C8C8C',
        heading: style.colors.primary.main,
      },

      primary: {
        light: style.colors.primary.light,
        main: style.colors.primary.main,
        dark: style.colors.primary.dark,
      },

      secondary: {
        light: '',
        main: '#AC98C5',
        dark: '#7E9CD0',
      },

      warning: {
        light: '#F8EAEC',
        main: '#E03247',
        dark: '',
      },

      success: {
        light: '#E8F2E6',
        main: '#078900',
        dark: '',
      },
    },

    breakpoints: {
      values: {
        md: 816,
        lg: 976,
      },
    },
  })

const generateOverrides = (style: typeof nutriciaStyle) => ({
  Button: {
    root: {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 32px',
      [theme.breakpoints.mobile()]: {
        padding: '8px',
      },
    },

    default: {
      borderWidth: '2px',
    },

    reversed: {
      borderWidth: '2px',
    },

    brand: {},
  },

  Typography: {
    body: {
      fontWeight: theme.typography.fontWeightRegular,
      color: style.colors.text.light,
    },

    h1: {
      color: style.colors.text.primary,
    },

    h2: {
      color: style.colors.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      [theme.breakpoints.mobile()]: {
        fontSize: '24px',
      },
    },

    h3: {
      fontWeight: theme.typography.fontWeightRegular,
    },

    h4: {
      color: style.colors.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '20px',
      [theme.breakpoints.mobile()]: {
        fontSize: '18px',
      },
    },

    h5: {
      color: style.colors.text.primary,
    },
  },

  Selector: {
    selector: {
      '& > span': {
        color: style.colors.text.primary,
      },
    },
  },

  VariantSelector: {
    variant: {
      '& $status': {
        color: style.colors.status.success,
      },

      '& $outOfStock, & $notAvailable': {
        color: '#ff0018',
      },

      '& $lowStock': {
        color: style.colors.ui.orange,
      },
    },
  },

  MyOrder: {
    root: {
      borderRadius: style.border.radius,
      backgroundColor: style.colors.primary.light,

      '& > div > p, & > div > div > p, & div > span': {
        fontWeight: theme.typography.fontWeightBold,
        color: style.colors.text.primary,
      },

      '& hr': {
        borderTop: `1px solid ${style.colors.text.primary}`,
      },
    },
  },

  CartContent: {
    bottomButtons: {
      '& a > span': {
        width: '100%',
        textAlign: 'center',
      },

      [theme.breakpoints.down('md')]: {
        '& a:not(:last-child)': {
          marginBottom: 20,
        },
      },
    },

    relatedProducts: {
      '& > div': {
        [theme.breakpoints.down('sm')]: {
          border: style.border.default,
        },
      },
    },
  },

  AddDiscountCode: {
    root: {
      margin: '20px 0',
    },
    addButton: {
      fontWeight: 600,
      padding: '7px 15px',
    },
    addingLabel: {
      color: style.colors.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
    },
    input: {
      padding: '5px 16px',
      boxShadow: style.boxShadow.inner,
      border: style.border.default,
    },
  },

  LineItems: {
    root: {
      borderRadius: style.border.radius,
      border: style.border.default,
    },
  },

  LineItem: {
    root: {
      '& $quantityContainer > p': {
        color: style.colors.text.primary,
      },
    },

    imageContainer: {
      flex: '0 0 auto',
      width: 80,
      maxHeight: 80,

      '& img': {
        width: '100% !important',
        height: 'auto !important',
        maxWidth: '100%',
        maxHeight: '100%',
      },

      '$quick-view &, $checkout &': {
        width: 64,
        height: 64,
        minHeight: 64,
        maxHeight: 64,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 0,
        marginRight: 15,
      },
    },

    viewItemLink: {
      textDecoration: 'none',
    },

    nameContainer: {
      '$default &': {
        [theme.breakpoints.mobile()]: {
          paddingRight: 0,
        },
      },

      '& > div > p': {
        color: style.colors.text.primary,

        [theme.breakpoints.down('sm')]: {
          marginRight: '30px',
        },
      },
    },

    removeContainer: {
      [theme.breakpoints.mobile()]: {
        right: -8,
      },

      '& svg': {
        fill: style.colors.primary.main,
      },
    },

    label: {
      backgroundColor: style.colors.primary.dark,

      '& p': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
      },
    },

    checkout: {
      [theme.breakpoints.down('sm')]: {
        flexBasis: 'auto',
        maxWidth: 'unset',
        '&:not(:last-child)': {
          marginRight: 8,
        },
      },
    },

    free: {
      color: `${nutriciaStyle.colors.status.success} !important`,
    },
  },

  LineItemControls: {
    control: {
      background: 'transparent',
      border: style.border.primary,
      '& svg': {
        fill: style.colors.primary.main,
      },

      '&:hover': {
        background: style.colors.primary.main,
        '& svg': {
          fill: theme.palette.common.white,
        },
      },
    },

    label: {
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  RelatedProducts: {
    hr: {
      borderTopColor: style.border.color,
    },

    'quick-view': {
      '& > p': {
        color: style.colors.text.primary,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  RelatedProduct: {
    default: {
      border: style.border.default,

      [theme.breakpoints.down('sm')]: {
        border: 'none',
        padding: 0,
      },
    },

    container: {
      '& > div > div > div': {
        alignSelf: 'flex-end',
      },
    },

    imageContainer: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: 20,
        minHeight: 80,

        '& > img': {
          width: '100% !important',
          height: '100% !important',
          maxWidth: '100%',
          maxHeight: '100%',
        },
      },

      '$quick-view &': {
        width: 64,
        height: 64,
        minHeight: 64,
        maxHeight: 64,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 0,
        marginRight: 15,
      },
    },

    subContainer: {
      marginTop: 10,

      '& button': {
        width: 64,
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
          width: 56,
        },
      },

      '& > div:first-of-type': {
        minWidth: 80,
      },
    },
  },

  Price: {
    root: {
      color: style.colors.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
    },
  },

  Notification: {
    root: {
      '& p': {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  QuickView: {
    root: {
      paddingTop: 20,
      border: style.border.default,

      '&:first-child div > p': {
        color: style.colors.text.primary,
      },
    },

    hr: {
      margin: '20px -24px',
    },

    addedIcon: {
      backgroundColor: style.colors.primary.dark,
    },
  },

  SubscriptionSelector: {
    root: {
      border: style.border.default,

      '& p': {
        color: style.colors.text.primary,
      },
    },

    option: {
      color: style.colors.text.primary,
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  Checkout: {
    icon: {
      fill: style.colors.primary.main,
    },

    container: {
      '& p': {
        color: style.colors.text.primary,
      },
    },

    securePayment: {
      'p&': {
        color: theme.palette.text.heading,
      },
    },

    summary: {
      '& hr': {
        borderTopColor: style.colors.primary.main,
        opacity: 0.2,
      },
    },
  },

  CheckoutOrder: {
    root: {
      border: style.border.default,
      backgroundColor: style.colors.primary.light,

      [theme.breakpoints.down('sm')]: {
        padding: 24,
      },

      '& div > p': {
        color: style.colors.primary.main,
      },
    },

    value: {
      '& span': {
        color: style.colors.primary.main,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  CheckoutSteps: {
    actions: {
      marginTop: 23,

      [theme.breakpoints.down('sm')]: {
        '& button': {
          width: '100%',
          justifyContent: 'center',
        },
      },
    },
  },

  StepContainer: {
    root: {
      border: style.border.default,

      '& h4': {
        color: style.colors.text.primary,
      },

      [theme.breakpoints.down('sm')]: {
        padding: 24,
      },
    },

    completeContainer: {
      '& .billing-icon': {
        display: 'inline-block !important',
        fill: style.colors.ui.orange,
        marginRight: 10,
      },
    },

    container: {
      '& h4': {
        color: style.colors.text.primary,
      },
    },

    stepCompleteIcon: {
      backgroundColor: style.colors.primary.main,
    },
  },

  FieldsInput: {
    input: {
      fontWeight: theme.typography.fontWeightMedium,
      color: style.colors.text.primary,
      boxShadow: 'inset 2px 2px 3px 0 rgba(0,0,0,0.1)',
      height: 48,
    },
  },

  FieldsAutoComplete: {
    input: {
      fontWeight: theme.typography.fontWeightMedium,
      color: style.colors.text.primary,
      boxShadow: 'inset 2px 2px 3px 0 rgba(0,0,0,0.1)',
      height: 48,
    },

    list: {
      '& li': {
        fontWeight: theme.typography.fontWeightMedium,
        color: style.colors.text.primary,
      },
    },
  },

  FieldsCheckbox: {
    checked: {
      backgroundColor: style.colors.text.primary,
    },
    label: {
      color: style.colors.text.primary,
      paddingTop: 3,
      fontSize: 14,
      lineHeight: '20px',
    },
  },

  FieldsRadio: {
    root: {
      '& label': {
        color: style.colors.text.primary,
      },
    },
  },

  FieldsStepper: {
    label: {
      color: style.colors.primary.main,
    },

    control: {
      backgroundColor: theme.palette.common.white,
      fill: style.colors.primary.main,
      border: theme.utils.createStyle(
        style.colors.primary.main,
        '2px',
        'solid',
      ),

      '&:hover': {
        backgroundColor: style.colors.primary.main,
      },
    },
  },

  AdyenDropIn: {
    root: {
      ['& .adyen-checkout__payment-method, label > span, ' +
      '.adyen-checkout__payment-method__header, ' +
      '.adyen-checkout__payment-method__name--selected, ']: {
        color: theme.utils.createStyle(style.colors.text.primary, '!important'),
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
      },

      '& .adyen-checkout__payment-method__name, .adyen-checkout__payment-method label':
        {
          color: theme.utils.createStyle(
            style.colors.text.primary,
            '!important',
          ),
        },

      '& label > span': {
        color: style.colors.text.primary,
      },
    },
  },

  EmptyCartContent: {
    actions: {
      '& a': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },

  DeliveryDate: {
    calendarIcon: {
      fill: style.colors.text.primary,
    },

    dateDisplay: {
      color: style.colors.text.primary,
    },
  },

  AddToCart: {
    notifyMeOutOfStock: {
      fontWeight: 600,
      color: '#4C4C4C',
    },
  },

  NotifyMeModal: {
    notification: {
      background: '#F4F4F4',
    },
  },

  WidgetReviews: {
    sorting: {
      '& .reviews-sorting': {
        '&__control': {
          borderRadius: '6px',
          backgroundColor: '#ECECEC',
          borderColor: '#DDDDDD',
        },

        '&__placeholder, &__single-value, &__option': {
          color: '#646464',
        },

        '&__option--is-focused': {
          backgroundColor: 'white',
        },

        '&__option:active': {
          backgroundColor: theme.utils.hexToRgba(
            style.colors.primary.main,
            0.1,
          ),
        },

        '&__indicator > svg[width]': {
          fill: '#646464',
        },
      },
    },
  },

  Review: {
    root: {
      backgroundColor: 'white',
    },

    action: {
      backgroundColor: style.colors.primary.light,
      color: style.colors.primary.main,

      '&:hover': {
        backgroundColor: style.colors.primary.light,
        color: style.colors.primary.main,
      },
    },

    careLineContent: {
      backgroundColor: style.colors.primary.light,
    },
  },

  CreateReview: {
    commentField: {
      '& label span span:last-child': {
        color: '#45484B',
      },
    },
  },

  VariantEditorVariant: {
    title: {
      color: style.colors.text.primary,
    },
  },

  OrderHistoryList: {
    order: {
      border: '1px solid #DDDDDD',
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.04)',
    },
  },

  OrderHistoryDetail: {
    root: {
      border: '1px solid #DDDDDD',
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.04)',
    },
  },
})

const theme = generateTheme(nutriciaStyle)
theme.overrides = generateOverrides(nutriciaStyle)

export { generateOverrides, generateTheme, nutriciaStyle as style, theme }
