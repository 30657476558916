import { gql } from '@apollo/client'

export const lineItemVariantFragment = gql`
  fragment lineItemVariant on ProductVariant {
    sku
    key   
    attributesRaw {
      name
      value
      referencedResourceSet {
        ... on Product {
          skus
          id
          masterData {
            current {
              allVariants {
                sku
                images {
                  url
                }
              }
              nameAllLocales {
                locale
                value
              }
            }
          }
        }
      }
    }
    images {
      url
    }
    assets {
      tags
      id
      nameAllLocales {
        value
      }
      sources {
        uri
      }
      custom {
        customFieldsRaw(
          includeNames: ["imagePosition"]
        ) {
          name
          value
        }
      }
    }
  }
`
