import { Palette } from './'

export const createPalette = (options: Partial<Palette>): Palette => {
  return {
    primary: options.primary,
    secondary: options.secondary,
    text: options.text,
    error: {
      light: options?.error?.light || '',
      main: options?.error?.main || '#eb3d03',
      dark: options?.error?.dark || '',
    },
    common: {
      black: '#000',
      white: '#fff',
      transparent: 'transparent',
    },
    background: {
      default: options?.background?.default || '#fff',
      secondary: options?.background?.secondary || '#fff',
      disabled: options?.background?.disabled || 'rgb(211, 211, 211)',
    },
    success: {
      light: options?.success?.light || '',
      dark: options?.success?.dark || '',
      main: options?.success?.main || '',
    },
    warning: {
      light: options?.warning?.light || '',
      dark: options?.warning?.dark || '',
      main: options?.warning?.main || '',
    },
  }
}
