import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

export interface Props {
  className?: string
  dashed?: boolean
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      border: 0,
      borderTop: '1px solid #dddddd',
      width: '100%',
    },

    dashed: {
      borderStyle: 'dashed',
    },
  }),
  { name: 'Hr' },
)

export const Hr: React.FC<Props> = ({ className, dashed }) => {
  const classes = useStyles()

  return (
    <hr className={clsx(classes.root, dashed && classes.dashed, className)} />
  )
}

export default Hr
