import { LineItem as CtLineItem } from '@danone-global/ct/interfaces'
import { FieldsStepper } from '@danone-global/internal/react/components/fields'
import {
  getProductsAvailability,
  useCart,
  useCore,
} from '@danone-global/internal/react/core'
import React from 'react'

import { useUpdateQuantity } from '../line-item.hooks'

interface Props extends CtLineItem {
  withAnalytics: boolean
  setCartError: (error: string) => void
}

const LineItemControls: React.FC<Props> = ({
  withAnalytics,
  setCartError,
  ...lineItem
}) => {
  const updateLineItem = useUpdateQuantity({
    lineItem,
    withAnalytics,
    setCartError,
  })

  const [cart] = useCart()
  const core = useCore()
  const {
    config: { siteName },
  } = useCore()
  // Sites that excludes the outOfStock scenarios
  const sites = ['uk-medical-uk', 'uk-am', 'uk-medical-ie', 'uk-cg']
  const siteNotAvailable = sites.includes(siteName)

  /* istanbul ignore next */  
  const productIds =
    cart?.lineItems.map((lineItem) => lineItem?.productId) || []

  /* istanbul ignore next */  
  const handleAdd = React.useCallback(async () => {
    await updateLineItem(1)
    if (!siteNotAvailable) {
      await getProductsAvailability(core, false, productIds, true)
    }
  }, [updateLineItem])

  /* istanbul ignore next */
  const handleMinus = React.useCallback(async () => {
    await updateLineItem(-1)
    if (!siteNotAvailable) {
      await getProductsAvailability(core, false, productIds, true)
    }
  }, [updateLineItem])

  return (
    <FieldsStepper
      data-testid={`line-item-controls-${lineItem.variant.sku}`}
      onDecrease={handleMinus}
      onIncrease={handleAdd}
      value={lineItem.quantity}
    />
  )
}

export default LineItemControls
