import { defineMessages } from 'react-intl'

export default defineMessages({
  agreeTerms: {
    id: 'checkout.checkbox.agreeTerms',
    defaultMessage:
      'You agree with our \'<a\' href="{privacyUrl}" target="_blank">privacy policy\'</a>\' and \'<a\' href="{termsUrl}" target="_blank">terms and conditions\'</a>\'.',
  },

  complianceStatementUk: {
    id: 'checkout.checkbox.complianceStatementUk',
    defaultMessage:
    'I wish Nutricia to support my continuing professional development and clinical practice by providing product samples or product for professional evaluation (PPE). I understand that products are supplied to me or my patients at my request for the sole purpose of my professional evaluation, education and information. I understand this support is offered without any obligation to recommend any product or service. Nutricia reserves the right to review the request to ensure it is in line with our ethical commitments. Provision of product is limited to quantities necessary to achieve its purpose, at no charge, not for personal use and should not be sold. The carer/patient is provided with safe preparation instructions and individual reviewed as appropriate. You have to agree with the statement above if you want to proceed the order.',
  },

  agreeDataSecurity: {
    id: 'checkout.checkbox.agreeDataSecurity',
    defaultMessage:
      'I agree with \'<a\' href="{dataCollectionUrl}" target="_blank">the data security\'</a>\'',
  },

  agreeTermsAndDataCollection: {
    id: 'checkout.checkbox.agreeTermsAndDataCollection',
    defaultMessage:
      'You agree with our \'<a\' href="{privacyUrl}" target="_blank">privacy policy\'</a>\', \'<a\' href="{dataCollectionUrl}" target="_blank">data security\'</a>\' and \'<a\' href="{termsUrl}" target="_blank">terms and conditions\'</a>\'.',
  },

  isMarketingOptedIn: {
    id: 'checkout.checkbox.isMarketingOptIn',
    defaultMessage:
      'I like to receive advice, offers and the latest info on {storeName} products ({storeName} products excluding first infant milks and food for special medical purposes).',
  },

  isMarketingOptedInGanmai: {
    id: 'checkout.checkbox.isMarketingOptInGanmai',
    defaultMessage:
      'I like to receive advice, offers and the latest info on {storeName} products ({storeName} products excluding first infant milks and food for special medical purposes).',
  },

  isAdviceOptedIn: {
    id: 'checkout.checkbox.isAdviceOptedIn',
    defaultMessage:
      'I like to receive infant feeding advice and information from {storeName}.',
  },

  isOnlineStoreOptedIn: {
    id: 'checkout.checkbox.isOnlineStoreOptedIn',
    defaultMessage:
      'I agree to become an {storeName} member to purchase products in the online store.',
  },
})
