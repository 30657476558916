import { gql } from '@apollo/client'

export const GET_PRODUCT_VARIANTS = gql`
  query getProductVariants($skus: [String!], $channelIds: [String!]) {
    products(skus: $skus) {
      results {
        id
        masterData {
          current {
            masterVariant {
              sku

              images {
                url
                label
              }

              availability {
                channels(includeChannelIds: $channelIds) {
                  results {
                    channel {
                      id
                    }
                    availability {
                      availableQuantity
                      isOnStock
                    }
                  }
                }
              }

              attributesRaw(includeNames: ["productTitle"]) {
                name
                value
              }
            }

            variants(skus: $skus) {
              sku

              images {
                url
                label
              }

              availability {
                channels(includeChannelIds: $channelIds) {
                  results {
                    channel {
                      id
                    }
                    availability {
                      availableQuantity
                      isOnStock
                    }
                  }
                }
              }

              attributesRaw(includeNames: ["productTitle"]) {
                name
                value
              }
            }
          }
        }
      }
    }
  }
`
