import React from 'react'

import Icon, { Props } from '../icon'

export const CloseIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M14.121 12l6.718 6.718a1.5 1.5 0 0 1-2.121 2.12L12 14.122 5.282 20.84a1.5 1.5 0 0 1-2.12-2.121L9.878 12 3.16 5.282a1.5 1.5 0 1 1 2.121-2.12L12 9.878l6.718-6.718a1.5 1.5 0 0 1 2.12 2.121L14.122 12z" />
  </Icon>
)

export default CloseIcon
