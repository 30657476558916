import React from 'react'

import Icon, { Props } from '../icon'

export const InfoIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 16 17" {...props}>
    <g fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        fill="inherit"
        fillRule="nonzero"
        transform="translate(-406.000000, -778.000000)"
      >
        <g transform="translate(150.000000, 736.000000)">
          <path d="M264.000009,58.2857158 C268.258943,58.2857158 271.714304,54.8303546 271.714304,50.5714209 C271.714304,46.3124873 268.258943,42.857126 264.000009,42.857126 C259.741076,42.857126 256.285714,46.3124873 256.285714,50.5714209 C256.285714,54.8303546 259.741076,58.2857158 264.000009,58.2857158 Z M264.964296,46.7142735 L263.035722,46.7142735 C262.854919,46.7142735 262.714293,46.5736483 262.714293,46.3928445 L262.714293,44.7856997 C262.714293,44.6048959 262.854919,44.4642708 263.035722,44.4642708 L264.964296,44.4642708 C265.1451,44.4642708 265.285725,44.6048959 265.285725,44.7856997 L265.285725,46.3928445 C265.285725,46.5736483 265.1451,46.7142735 264.964296,46.7142735 Z M266.250012,55.7142842 L261.750007,55.7142842 C261.569203,55.7142842 261.428578,55.573659 261.428578,55.3928552 L261.428578,53.7857105 C261.428578,53.6049067 261.569203,53.4642815 261.750007,53.4642815 L262.714293,53.4642815 L262.714293,50.249992 L261.750007,50.249992 C261.569203,50.249992 261.428578,50.1093668 261.428578,49.928563 L261.428578,48.3214182 C261.428578,48.1406144 261.569203,47.9999893 261.750007,47.9999893 L264.964296,47.9999893 C265.1451,47.9999893 265.285725,48.1406144 265.285725,48.3214182 L265.285725,53.4642815 L266.250012,53.4642815 C266.430816,53.4642815 266.571441,53.6049067 266.571441,53.7857105 L266.571441,55.3928552 C266.571441,55.573659 266.430816,55.7142842 266.250012,55.7142842 Z" />
        </g>
      </g>
    </g>
  </Icon>
)

export default InfoIcon
