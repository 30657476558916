import React from 'react'

import Icon, { Props } from '../icon'

export const EnvelopeIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => {
  return (
    <Icon viewBox="0 0 21 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        id="Notify-me"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fill="currentColor"
          id="Aptaclub---Notify-Me---POP---Desktop"
          transform="translate(-1186.000000, -831.000000)"
        >
          <g id="button-large" transform="translate(1028.000000, 819.000000)">
            <path
              d="M177.294118,12 L159.411765,12 C158.632069,12 158,12.6320686 158,13.4117647 L158,26.5882353 C158,27.3679314 158.632069,28 159.411765,28 L177.294118,28 C178.073814,28 178.705882,27.3679314 178.705882,26.5882353 L178.705882,13.4117647 C178.705882,12.6320686 178.073814,12 177.294118,12 Z M177.294118,25.5905882 L173.557647,21.8541176 C173.27952,21.5949564 172.846108,21.6026034 172.577297,21.8714148 C172.308486,22.1402261 172.300839,22.5736379 172.56,22.8517647 L176.296471,26.5882353 L160.409412,26.5882353 L164.145882,22.8517647 C164.405044,22.5736379 164.397397,22.1402261 164.128585,21.8714148 C163.859774,21.6026034 163.426362,21.5949564 163.148235,21.8541176 L159.411765,25.5905882 L159.411765,16.5317647 L167.882353,22.9176471 C168.133333,23.1058824 168.478431,23.1058824 168.729412,22.9176471 L177.284706,16.5270588 L177.294118,25.5905882 Z M177.294118,13.4117647 L177.294118,14.7670588 L168.32,21.4729412 L159.411765,14.7670588 L159.411765,13.4117647 L177.294118,13.4117647 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </Icon>
  )
}

export default EnvelopeIcon
