import { Cart as coreDanoneApi } from '@danone-global/ct/interfaces'
import { CtTokens } from '@danone-global/internal/graphql'

import { Core } from './core.provider'
import * as Sentry from '@sentry/react'

export const getCustomerToken = (
  cart: coreDanoneApi,
  core: Core,
  provider: string,
  externalCustomerId: string,
  mergeCarts: boolean,
) =>
  fetch(
    core.features.useIdentityManagementV2
      ? `https://api.${core.config.ctProjectKey}.danone-dtc.net/identity_management/v2/auth/${provider}/swap`
      : `https://api.${core.config.ctProjectKey}.danone-dtc.net/identity_management/auth/${provider}/swap`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: externalCustomerId,
        storeKey: core.features.useIdentityManagementV2
          ? core.config.ctStoreKey
          : undefined,
        ...(mergeCarts && cart?.anonymousId
          ? {
              anonymousCartId: cart.id,
            }
          : undefined),
        preferredLanguage: core.features.useIdentityManagementV2
          ? core.localeConfig.locale.substring(0, 2)
          : undefined,
      }),
    },
  )
    .then<CtTokens>((res) => {
      return res.json()
    })
    .then((r) => {
      if ((r as any).errors?.length)        
        throw new Error(JSON.stringify((r as any).errors))
        Sentry.captureException(new Error(JSON.stringify((r as any).errors)))
        return r
    })
