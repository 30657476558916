import { Features } from '@danone-global/internal/react/core'

import { environment } from './environment'

export const features: Features = {
  enableNotifyMe: environment.isDevelopment,
  hidePrices: true,
  hideTrackParcelBtn: true,
  hideReorderBtn: true,
}
