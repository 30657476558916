export default class DataLayer {
  listeners: any[]
  events: any[]
  constructor() {
    this.events = []
    this.listeners = []
  }

  push(data) {
    this.events.push(data)
    this.broadcast(data)
  }

  listen(cb) {
    this.listeners.push(cb)
    // push current events to new listener
    for (const event of this.events) {
      try {
        cb(event)
      } catch (err) {
        this.handleError(err)
      }
    }
  }

  broadcast(data: Record<string, unknown>) {
    for (const cb of this.listeners) {
      try {
        cb(data)
      } catch (err) {
        this.handleError(err)
      }
    }
  }

  handleError(err) {
    console.log(err)
  }
}
