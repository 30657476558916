import SdkAuth from '@commercetools/sdk-auth'
import * as Sentry from '@sentry/browser'
import Cookies from 'universal-cookie'

import { Config } from './apollo-client.config.interface'
import log from './apollo-client.log'

export interface CtTokens {
  access_token: string
  refresh_token: string
  expires_in: number
}

let authClient = null
let authClientForProject = null

export const COOKIE_DTC_LOGIN = '__dtc_login'
export const COOKIE_ACCESS_TOKEN = 'ctp_access_token'
export const COOKIE_REFRESH_TOKEN = 'ctp_refresh_token'
export const COOKIE_FOR_SITE = 'dtc_tokens_are_for_site'
export const COOKIE_PREFILL_DATA = 'dtc_prefill_data'
export const COOKIE_DATALAYER_CHECKOUT_BUTTON = 'dtc_datalayer_checkout_btn'
export const COOKIE_TRUSTED_SHOP_DATA = 'dtc_trusted_shop_data'
export const COOKIE_DTC_SAP_EXTERNAL_ID = 'dtc_sap_external_id'

export const buildScopes = (config: Config) => [
  `create_anonymous_token:${config.ctProjectKey}`,
  `manage_my_orders:${config.ctProjectKey}:${config.ctStoreKey}`,
  `manage_my_orders:${config.ctProjectKey}`,
  `manage_my_shopping_lists:${config.ctProjectKey}`,
  `view_products:${config.ctProjectKey}`,
  `manage_my_profile:${config.ctProjectKey}`,
  `manage_my_payments:${config.ctProjectKey}`,
  `view_states:${config.ctProjectKey}`,
]

export const getAuthClient = (config: Config) => {
  if (authClient === null || authClientForProject !== config.ctProjectKey) {
    authClient = new SdkAuth({
      host: config.ctAuthHost,
      projectKey: config.ctProjectKey,
      disableRefreshToken: false,
      credentials: {
        clientId: config.ctClientId,
        clientSecret: config.ctClientSecret,
      },
      scopes: buildScopes(config),
      fetch: window.fetch,
    })

    // This makes sure that when switching theme in Storybook a new client is created
    authClientForProject = config.ctProjectKey
  }

  return authClient
}

export const getCommercetoolsToken = async (
  config: Config,
): Promise<string> => {
  const accessToken = await getExistingAccessToken(config)

  if (accessToken) {
    return accessToken
  }

  log('Getting new token')
  const response = await getAuthClient(config).anonymousFlow()

  return storeTokens(response, config)
}

export const getAccessTokenCookie = (): string => {
  return new Cookies().get(COOKIE_ACCESS_TOKEN)
}

export const getExistingAccessToken = async (config: Config) => {
  const accessToken = getAccessTokenCookie()
  const cookies = new Cookies()
  const refreshToken = cookies.get(COOKIE_REFRESH_TOKEN)
  const site = cookies.get(COOKIE_FOR_SITE)

  if (site && site !== config.ctProjectKey) {
    cookies.remove(COOKIE_ACCESS_TOKEN, { path: '/' })
    cookies.remove(COOKIE_REFRESH_TOKEN, { path: '/' })
    cookies.remove(COOKIE_FOR_SITE, { path: '/' })

    return ''
  }

  if (accessToken) {
    return accessToken
  }

  // Check if the current tokens are expired
  if (refreshToken) {
    log('Refresh CT token')

    const client = getAuthClient(config)
    const newTokens: CtTokens | undefined = await client
      .refreshTokenFlow(refreshToken)
      .catch((e) => {
        // An error occured, the token may have expired: remove the cookie
        cookies.remove(COOKIE_REFRESH_TOKEN, { path: '/' })
        Sentry.captureException(e)
      })

    if (newTokens?.access_token) {
      storeTokens(
        {
          // Pass the old refresh token so that if the new response
          // does not have it we update the expires at
          refresh_token: refreshToken,
          ...newTokens,
        },
        config,
        true,
      )

      return newTokens.access_token
    }
  }

  return ''
}

/**
 * Stores the tokens in cookies
 *
 * When the accesstoken is expired and gone, and there is a refreshtoken set, it will fetch a new accesstoken.
 */
export const storeTokens = (
  tokens: CtTokens,
  config: Config,
  forceNew = false,
): string => {
  const cookies = new Cookies()

  const accessToken = cookies.get(COOKIE_ACCESS_TOKEN)
  const refreshToken = cookies.get(COOKIE_REFRESH_TOKEN)

  if (tokens.refresh_token && (!refreshToken || forceNew)) {
    cookies.set(COOKIE_REFRESH_TOKEN, tokens.refresh_token, {
      maxAge: 17_280_000, // default Commercetools expire time is 200 days
      path: '/',
    })

    cookies.set(COOKIE_FOR_SITE, config.ctProjectKey, {
      maxAge: tokens.expires_in - 60,
      path: '/',
    })
  }

  // Double check if we still dont have a access token cookie
  if (!accessToken || forceNew) {
    cookies.set(COOKIE_ACCESS_TOKEN, tokens.access_token, {
      maxAge: tokens.expires_in - 60,
      path: '/',
    })

    return tokens.access_token
  }

  return accessToken
}

export const removeTokens = () => {
  const cookies = new Cookies()

  cookies.remove(COOKIE_ACCESS_TOKEN, { path: '/' })
  cookies.remove(COOKIE_REFRESH_TOKEN, { path: '/' })
  cookies.remove(COOKIE_DTC_LOGIN, { path: '/' })
  cookies.remove(COOKIE_DTC_SAP_EXTERNAL_ID, { path: '/' })
}
