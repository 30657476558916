import { Maybe, ShippingInfo } from '@danone-global/ct/interfaces'
import { useCorrectDeliveryName } from '@danone-global/internal/react/components/checkout/delivery-methods'
import Price, {
  formatMoney,
} from '@danone-global/internal/react/components/price'
import { Grid, Typography } from '@danone-global/internal/react/components/ui'
import {
  checkIsShippingFree,
  useCore,
} from '@danone-global/internal/react/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export interface Props {
  valueClassName: string
  shipping?: Maybe<ShippingInfo>
}

export const ShippingPrice: React.FC<Props> = ({
  valueClassName,
  shipping,
}) => {
  const { localeConfig, features, config: {siteName} } = useCore()
  const isShippingFree = checkIsShippingFree(shipping)
  const name = useCorrectDeliveryName(shipping?.shippingMethodName)

  if (!shipping) {
    return null
  }

  return (
    <>
      <Grid direction="row" gutters="top" flex>
        <Grid flex>
          <Typography color="primary-dark" variant="body2" noGutters>
            <FormattedMessage
              defaultMessage="Shipping"
              id="my-order.shipping"
            />
          </Typography>
        </Grid>

        <Grid className={valueClassName}>
          <Typography
            color="primary-dark"
            component="span"
            variant="body2"
            noGutters
          >
            <Price
              testId="my-order.shipping-price"
              inherit
              showFreeLabel
              {...(isShippingFree && shipping.taxedPrice
                ? shipping.taxedPrice.totalGross
                : shipping.price)}
            />
          </Typography>
        </Grid>
      </Grid>
      {!features.disableShippingFreeFromInMyOrder &&
        shipping.shippingRate?.freeAbove &&
        shipping.shippingRate.freeAbove.centAmount > 0 &&
        !isShippingFree && (
          <Grid direction="row" gutters="top" flex>
            <Grid flex>
              <Typography
                className="free-shipping"
                color="secondary"
                variant="body2"
                noGutters
              >
                {siteName !== 'hk-ganmai' ?
                <FormattedMessage
                  defaultMessage="To get free delivery for delivery method {methodName} order a minimum of {amount}"
                  id="my-order.shipping.free-above"
                  values={{
                    amount: formatMoney(
                      localeConfig.locale,
                      shipping.shippingRate.freeAbove.currencyCode,
                      shipping.shippingRate.freeAbove.centAmount,
                      shipping.shippingRate.freeAbove.fractionDigits,
                    ),
                    methodName: name,
                  }}
                />
                : 
                <FormattedMessage
                  defaultMessage="order two products or above could enjoy free shipping"
                  id="my-order.shipping.free-above-ganmai"
                  values={{
                    amount: formatMoney(
                      localeConfig.locale,
                      shipping.shippingRate.freeAbove.currencyCode,
                      shipping.shippingRate.freeAbove.centAmount,
                      shipping.shippingRate.freeAbove.fractionDigits,
                    ),
                    methodName: name,
                  }}
                />
                }
              </Typography>
            </Grid>
          </Grid>
        )}
    </>
  )
}

export default ShippingPrice
