import { LineItem, ProductData } from '@danone-global/ct/interfaces'

/* istanbul ignore next */
export const localisedProductName = (
  item: LineItem | ProductData,
  locale: string,
): string => {
  const productName = item?.variant?.attributesRaw?.find(
    (attribute) => attribute.name === 'productTitle',
  )

  if (productName) {
    return productName.value
  }

  const localisedProductName = item?.nameAllLocales?.find(
    (name) => name.locale === locale,
  )

  if (localisedProductName) {
    return localisedProductName.value
  }

  if (item?.nameAllLocales?.length > 0) {
    return item.nameAllLocales[0].value
  }

  return item?.name ?? ''
}
