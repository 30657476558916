import { AssetSource } from '@danone-global/ct/interfaces'
import React from 'react'

export interface Props extends Omit<Partial<AssetSource>, 'dimensions'> {
  width?: number | string

  height?: number | string

  maxHeight?: number | string

}

export const CtAsset: React.FC<Props> = ({
  width = 56,
  height = 'auto',
  maxHeight = 56,
  uri,
  key,
}) => (
  <img
    alt={key}
    src={uri ? `${uri}?wid=65`: uri}
    style={{
      width,
      height,
      minWidth: width,
      minHeight: height,
      maxHeight,
      objectFit: 'contain',
    }}
  />
)

export default CtAsset
