import { BaseConfig } from '@danone-global/internal/react/core'
import { messages } from '@danone-global/internal/react/modules/checkout'
import React from 'react'

export const baseEnvironment: BaseConfig = {
  storeName: 'Medical IE',
  siteName: 'uk-medical-ie',
  siteSlug: 'uk-medical-ie',
  defaultLocale: 'en-GB',
  localeConfigs: [
    {
      country: 'IE',
      currency: 'EUR',
      locale: 'en-GB',
      initMessages: () =>
        import('../translations/en_GB.json').then((r) => r.default),
      urls: {
        privacy: '/hcp/privacy-policy.html',
      },
    },
  ],
  providerId: 'danis_eu',
  ctHost: 'https://api.europe-west1.gcp.commercetools.com',
  ctAuthHost: 'https://auth.europe-west1.gcp.commercetools.com',
  ctStoreKey: 'IE',
  analytics: {
    tracker: 'aem',
    language: 'en-GB',
    defaultCategory: 'Baby food',
  },

  checkout: {
    emailOptional: true,
    stepAddress: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/checkout/uk-medical-step-address'),
    ),

    payment: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/payment-providers/adyen-drop-in'),
    ),

    checkboxes: [
      {
        name: 'complianceStatementUk',
        required: true,
        store: false,
        label: messages.complianceStatementUk,
      },
    ],
  },
}
