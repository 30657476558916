import { CalendarIcon } from '@danone-global/internal/react/components/icons'
import {
  customFieldValue,
  Theme,
  useCategoryCustomFields,
  useCore,
} from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Grid } from '../grid'
import { Typography } from '../typography'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& > $deliveryInfo': {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightRegular,
        color: '#45484B',
        lineHeight: '20px',
      },
    },

    dateDisplay: {
      alignItems: 'center',
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius(0.75),
      padding: '14px 16px',

      '& > p': {
        display: 'flex',
        alignItems: 'center',
        verticalAlign: 'middle',
        margin: 0,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        position: 'relative',
        top: 2,
      },
    },

    calendarIcon: {
      fill: theme.palette.primary.main,
      marginRight: theme.spacing(1),
    },

    deliveryInfo: {},
  }),
  { name: 'DeliveryDate' },
)

interface Props {
  date: string
  deliveryCategory: string
}

const formatDate = (date: string, locale?: string) => {
  const d = new Date(date)
  return `${d.getDate()} ${d.toLocaleDateString(locale, {
    month: 'long',
  })} ${d.getFullYear()}`
}

export const DeliveryDate: React.FC<Props> = ({ deliveryCategory, date }) => {
  const classes = useStyles()
  const deliveryData = useCategoryCustomFields(deliveryCategory)
  const { localeConfig } = useCore()

  if (deliveryData.loading) return null

  return (
    <div className={clsx([classes.root, 'deliveryDate'])}>
      <Grid className={classes.dateDisplay} flex>
        <CalendarIcon className={classes.calendarIcon} size={18} />
        <Typography>{formatDate(date, localeConfig.locale)}</Typography>
      </Grid>
      <Typography className={classes.deliveryInfo}>
        <FormattedMessage
          defaultMessage="Since we bake fresh for you by hand and with a lot of love, we will send your complete shipment after the next baking day. Orders for fresh baked goods by {deliveryCycleEndTime} on {deliveryCycleEndDay} will be dispatched on the following {deliveryDay}."
          id="delivery-date.delivery-info"
          values={{
            deliveryCycleEndTime: customFieldValue<string>(
              deliveryData.data.category,
              'deliveryCycleEndTime',
            ),
            deliveryCycleEndDay: getWeekdayName(
              customFieldValue<string>(
                deliveryData.data.category,
                'deliveryCycleEndDay',
              ),
              localeConfig.locale,
            ),
            deliveryDay: getWeekdayName(
              customFieldValue<string>(
                deliveryData.data.category,
                'deliveryDay',
              ),
              localeConfig.locale,
            ),
            weekNumber: [],
          }}
        />
      </Typography>
    </div>
  )
}

const getWeekdayName = (englishValue: string, locale: string) =>
  weekdayValues.includes(englishValue)
    ? new Date(2001, 0, 1 + weekdayValues.indexOf(englishValue)).toLocaleString(
        locale,
        { weekday: 'long' },
      )
    : undefined

const weekdayValues = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]
