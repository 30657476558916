import { LineItem } from '@danone-global/ct/interfaces'
import { GET_PRODUCT_CATEGORIES } from '@danone-global/internal/graphql'

import { Config } from '../core.config.interface'
import { getGraphqlClient } from '../core.graphql'
import { limitArrayCharacters } from '../core.utils'
import { ANALYTICS_CATEGORY_LIMIT } from './core.analytics-events'

export const productsMap = async ({
  lineItems,
  config,
  locale,
}: {
  lineItems: LineItem[]
  config: Config
  locale?: string
}) => {
  const productCategoryData = await getProductCategories(
    config,
    lineItems,
    locale,
  )

  return lineItems?.map((lineItem) => {
    const categories =
      productCategoryData?.products?.results
        ?.find((i) => i.id === lineItem.productId)
        ?.masterData?.current?.categories.map((i) => i.name) || []

    const categoriesString = limitArrayCharacters(
      categories.join(',') || '',
      ANALYTICS_CATEGORY_LIMIT,
    )

    return {
      item_id: lineItem.variant.sku,
      item_name: lineItem.nameAllLocales?.find((name) => name.locale === locale)
        ?.value,
      price: Number(lineItem.price?.value?.centAmount) / 100,
      quantity: lineItem.quantity,
      item_category: categoriesString,
      discount: Number(lineItem.price?.discounted?.value?.centAmount) / 100 || 0,
      affiliation: config?.storeName,
      item_variant: lineItem?.variant?.key,
    }
  })
}

const getProductCategories = async (
  config: Config,
  lineItems: LineItem[],
  locale: string,
  acceptCache = true,
) => {
  const apolloClient = getGraphqlClient(config)

  if (!lineItems?.length) {
    return Promise.resolve({})
  }

  const { data: productCategoryData } = await apolloClient.query({
    query: GET_PRODUCT_CATEGORIES,
    variables: {
      skus: lineItems?.map((i) => i.variant?.sku),
      locale,
    },
    // If cache is accepted then use the default
    fetchPolicy: acceptCache ? undefined : 'network-only',
  })

  return productCategoryData
}
