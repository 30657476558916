import { useCart } from '@danone-global/internal/react/core'

export const useCorrectDeliveryName = (name: string | undefined): string => {
  const [cart] = useCart()
  if (!name) return ''

  if (name.startsWith('{')) {
    try {
      const nameJSON = JSON.parse(name)
      return nameJSON[cart?.locale]
    } catch (e) {
      return name
    }
  }

  return name
}
