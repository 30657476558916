import packageJson from '@danone-global/package-json'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { getScriptProps, populate, setComponentSelector } from '@danone-global/internal/react/utils/abode'

import { analytics } from './analytics'
import { Config } from './core.config.interface'
import { login, logout } from './core.dtc-auth'
import { Features } from './core.features.interface'
import { createCart, getActiveCart } from './core.graphql'
import log from './core.log'
import { Core } from './core.provider'
import { registerWidgets } from './core.register-widget'
import { transformScriptProps } from './core.utils'
import { Theme } from './theme'

// Get properties off <script> element
let scriptProps: { [key: string]: string } = {}
setComponentSelector('data-dtc-component')
try {
  scriptProps = transformScriptProps(getScriptProps() || {})
} catch (error) {
  //
}

/**
 * Init the correct configs for the site
 */
export const init = async ({
  config,
  features,
  siteSlug = null,
  props = scriptProps,
  widgets,
  theme,
}: {
  config: Config
  features: Features
  siteSlug?: any
  theme: Theme
  widgets: any[]
  /** This property is set magically but can be overwritten for tests. */
  props?: { [key: string]: string }
}) => {
  Sentry.init({
    dsn: config.sentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: config.isProduction
      ? `${siteSlug || config.siteSlug}-prd`
      : `${siteSlug || config.siteSlug}-test`,
    allowUrls: [/.danone-dtc\.net/],
    release: packageJson.version,
    tracesSampleRate: config.isProduction
      ? // 3.33% of the requests
        0.033
      : // 10% of the requests
        0.1,
  })

  const core = await initCore(
    config,
    features,
    theme,
    props.locale,
    props.country,
  )

  initializeBackwardCompatibilityWithUikit(core)

  initializeDtcAuthFunctions(core)

  if (process.env.NODE_ENV !== 'test') {
    const cart = await ensureCart(core)
    analytics.init(config.analytics.tracker, {
      cartId: cart.id,
      store: config?.analytics?.storeName || config.storeName,
      storeKey: config.ctStoreKey,
    })
  }

  registerWidgets(widgets, core)

  await populate({ attributes: { class: 'danone-dtc' } })
}

export const initCore = async (
  config: Config,
  features: Features,
  theme: Theme,
  locale?: string,
  country?: string,
): Promise<Core> => {
  // Match on locale string
  let currentLocale = locale

  // Fallback on country
  if (!currentLocale && country) {
    currentLocale = config.localeConfigs.find(
      (l) => l.country === country,
    )?.locale
  }

  // Fallback on defaultConfig
  const localeConfig = config.localeConfigs.find(
    (l) => l.locale === (currentLocale || config.defaultLocale),
  )

  return {
    theme,
    config,
    apiUrl: `https://api.${config.ctProjectKey}.danone-dtc.net`,
    features,
    localeConfig: {
      country: localeConfig.country,
      currency: localeConfig.currency,
      locale: localeConfig.locale,
      urls: localeConfig.urls,
      messages: await localeConfig.initMessages(),
    },
  }
}

/**
 * Adds a listener for changes from the uikit
 */
export const initializeBackwardCompatibilityWithUikit = (core: Core): void => {
  // We create a event listener
  window.addEventListener('__danone_uikit_cart_update', async () => {
    log('Got update cart event')
    const newCart = await getActiveCart(core, false)

    log('Cart updated, new cart', newCart)
  })
}

/* istanbul ignore next */
async function ensureCart(core: Core) {
  let activeCart = await getActiveCart(core)

  if (!activeCart) {
    log('Initialize new cart')

    activeCart = await createCart(core)
  }

  log('Using cart', activeCart)
  return activeCart
}

function initializeDtcAuthFunctions(core: Core) {
  Object.assign(window, {
    dtcAuth: { login: login(core), logout: logout(core) },
  })

  // Make sure event is dispatched to both window and document
  const event = new Event('dtcAuthLoaded')
  document.dispatchEvent(event)
  window.dispatchEvent(event)
}
