import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { InputHTMLAttributes } from 'react'

import { Grid } from '../grid'
import { Typography } from '../typography'

interface Props extends InputHTMLAttributes<any> {
  label?: string

  inputClassName?: string

  placeholder?: string
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    input: {
      position: 'relative',
      width: '100%',
      height: 'auto',
      minWidth: 'auto',
      border: theme.utils.createStyle(
        theme.utils.getSize(1, { forceUnit: true }),
        'solid',
        'rgba(151, 151, 152, 0.25)',
      ),
      borderRadius: theme.shape.borderRadius(),
      backgroundColor: theme.palette.common.white,
      fontSize: theme.utils.getSize(18),
      fontWeight: theme.typography.fontWeightLight,
      lineHeight: theme.utils.getSize(28, { forceUnit: true }),
      padding: theme.spacing(1, 2),
      outline: 'none',

      [theme.breakpoints.down('md')]: {
        fontSize: theme.utils.getSize(18, { isMobile: true }),
        lineHeight: theme.utils.getSize(28, {
          isMobile: true,
          forceUnit: true,
        }),
      },

      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
  }),
  { name: 'Input' },
)

export const Input: React.FC<Props> = ({
  label,
  className,
  inputClassName,
  placeholder = '',
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid className={className} flex>
      {label && <Typography>label</Typography>}

      <input
        className={clsx(classes.input, inputClassName)}
        placeholder={placeholder}
        {...rest}
      />
    </Grid>
  )
}

export default Input
