import {
  Cart,
  LineItem as CtLineItem,
  LineItemMode,
} from '@danone-global/ct/interfaces'
import { COOKIE_DTC_LOGIN } from '@danone-global/internal/graphql'
import Cookies from 'universal-cookie'

import { Features } from '../core.features.interface'

type ObjectOfStrings = {
  [key: string]: string
}

// Recursive partial
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export const PACKAGE_PRODUCT_KEY = 'package-product'

export const GOODIES_PRODUCT_TYPE = 'goodies-pack-shell'
export const GOODIES_AVAILABLE_PRODUCTS = 'goodiesAvailableProducts'
export const GOODIES_MAX_TOTAL_QUANTUTY = 'goodiesMaxTotalQuantity'
export const GOODIES_LAYER_TITLE = 'goodiesLayerTitle'

// UserType, a GET parameter
export type UserType = 'new' | 'anonymous' | null

/**
 * Utility to filter out double items in an array by some measure.
 *
 * @example
 * ```ts
 * const products = [
 * {id: 'a', price: 1},
 * {id: 'a', price: 1},
 * {id: 'b', price: 1}
 * ]
 * products.filter(uniqueBy(p => p.id))
 *
 * // results in
 * [{id: 'a', price: 1}, {id: 'b', price: 1}]
 * ```
 */
export const uniqueBy =
  <T, S>(getValue: (item: T) => S) =>
  (v: T, i: number, s: T[]) =>
    s.findIndex((e) => getValue(e) === getValue(v)) === i

/**
 * Utility for lists to ensure there are no empty values.
 *
 * @example
 * ```ts
 * const products = [
 * {id: 'a', price: {centAmount: 20}},
 * {id: 'b', price: null},
 * {id: 'c', price: {centAmount: 30}}
 * ]
 * products.map(x => price).filter(isValue).map(p => p.centAmount)
 *
 * // results in
 * [20, 30]
 * ```
 */
export const isValue = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined

/**
 * Sums the values of a list of objects calculated by a callback.
 *
 * @example
 * ```js
 * sum([{price: 1}, {price: 2}], x => x.price) // 3
 * ```
 */
export const sum = <T>(items: T[], getValue: (i: T) => number) =>
  items.reduce((p, c) => p + getValue(c), 0)

/**
 *
 * Get custom value from an array like customFields
 */
export const getCustomValue = <T>(
  values: { name: string; value: T }[],
  name: string,
) => values?.find((v) => v.name === name)?.value as T | undefined

/**
 * Limits on characters and removes last (uncomplete) item
 *
 * For example:
 *  limitArrayCharacters('a,b,c,dddddd', 8);    // 'a,b,c'
 *
 * @param text an array as a comma seperated string ('a,b')
 * @param limit
 * @returns
 */

export const limitArrayCharacters = (text: string, limit: number) => {
  if (!text) {
    return ''
  }

  return text.length > limit
    ? text.slice(0, limit).split(',').slice(0, -1).join(',')
    : text
}

/*
 *
 * Filter away giftline items
 * Unless the featureflag is enabled AND visibility is set (on variant)
 */
export const filterGiftLineItem = (features: Features) => (l: CtLineItem) =>
  l.lineItemMode !== LineItemMode.GiftLineItem ||
  (features.showGiftLineItems &&
    l.variant.attributesRaw?.find((i) => i.name === 'visibleAsGiftLineItem')
      ?.value === true)

export const filterParentLineItem = () => (l: CtLineItem) => {
  console.log('custom', l?.custom?.customFieldsRaw)
  return l?.custom?.customFieldsRaw.filter((i) => i.name === 'parentLineItem')
}

/**
 *
 * Remove entry "__typename" from an object
 */
export const removeTypeName = <T extends { __typename?: string }>({
  __typename,
  ...rest
}: T) => rest

/**
 * Compare the shipping address to the billing address, ignoring the externalId
 */
export const billingAddressIsSameAsShipping = (cart?: Cart) => {
  if (!cart) return undefined

  const [shipping, billing] = [cart.shippingAddress, cart.billingAddress].map(
    (i) =>
      Object.fromEntries(
        Object.entries({ ...i, externalId: '' }).map((i) => [
          i[0],
          i[1] || '', // convert null to empty strings
        ]),
      ),
  )

  return JSON.stringify(shipping) === JSON.stringify(billing)
}

/**
 * Determine during checkout if a new user should be created
 * If the user is logged in but doesn't have an email set, do not create account (but use anonymous step)
 */
export const showStepCreateUser = (loginUrl?: string, cart?: Cart) => {
  const params = new URLSearchParams(window.location.search)
  const userType = params.get('user-type') as UserType

  return Boolean(
    loginUrl && (userType === 'new' || (cart?.customerEmail && isLoggedIn())),
  )
}

export const transformScriptProps = (
  props: ObjectOfStrings = {},
): ObjectOfStrings => {
  const transform = Object.entries(props).map(([key, value]) => {
    if (key === 'locale') {
      const [languageCode, countryCode] = value.replace('_', '-').split('-')

      return [key, languageCode.toLowerCase() + '-' + countryCode.toUpperCase()]
    }

    if (key === 'country') {
      return [key, value.toUpperCase()]
    }

    return [key, value]
  })

  return Object.fromEntries(transform)
}

/**
 * Capitalizes the first letter
 */
export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1)

/** Checks the cookie if current user is logged in. */
export const isLoggedIn = () => {
  const cookies = new Cookies()
  return cookies.get(COOKIE_DTC_LOGIN) === 'true'
}

/** Checks if we expect the user to be logged in */
export const shouldBeLoggedIn = (loginUrl?: string) => {
  const params = new URLSearchParams(window.location.search)
  const userType = params.get('user-type') as UserType

  return Boolean(loginUrl && userType === null)
}

/** Get country name in local language, by ISO code */
export const getCountryNameLocale = (iso: string) =>
  ({
    GB: 'Great Britain',
    DE: 'Deutschland',
    HK: '香港',
    TR: 'Türkiye',
    IE: 'Ireland',
    AT: 'Österreich',
    US: 'United States',
  }[iso])

/** A list of all the states in the USA */
export const USAStates = [
  { value: 'AK', text: 'Alaska' },
  { value: 'AL', text: 'Alabama' },
  { value: 'AR', text: 'Arkansas' },
  { value: 'AZ', text: 'Arizona' },
  { value: 'CA', text: 'California' },
  { value: 'CO', text: 'Colorado' },
  { value: 'CT', text: 'Connecticut' },
  { value: 'DC', text: 'District of Columbia' },
  { value: 'DE', text: 'Delaware' },
  { value: 'FL', text: 'Florida' },
  { value: 'GA', text: 'Georgia' },
  { value: 'IA', text: 'Iowa' },
  { value: 'ID', text: 'Idaho' },
  { value: 'IL', text: 'Illinois' },
  { value: 'IN', text: 'Indiana' },
  { value: 'KS', text: 'Kansas' },
  { value: 'KY', text: 'Kentucky' },
  { value: 'LA', text: 'Louisiana' },
  { value: 'MA', text: 'Massachusetts' },
  { value: 'MD', text: 'Maryland' },
  { value: 'ME', text: 'Maine' },
  { value: 'MI', text: 'Michigan' },
  { value: 'MN', text: 'Minnesota' },
  { value: 'MO', text: 'Missouri' },
  { value: 'MS', text: 'Mississippi' },
  { value: 'MT', text: 'Montana' },
  { value: 'NC', text: 'North Carolina' },
  { value: 'ND', text: 'North Dakota' },
  { value: 'NE', text: 'Nebraska' },
  { value: 'NH', text: 'New Hampshire' },
  { value: 'NJ', text: 'New Jersey' },
  { value: 'NM', text: 'New Mexico' },
  { value: 'NV', text: 'Nevada' },
  { value: 'NY', text: 'New York' },
  { value: 'OH', text: 'Ohio' },
  { value: 'OK', text: 'Oklahoma' },
  { value: 'OR', text: 'Oregon' },
  { value: 'PA', text: 'Pennsylvania' },
  { value: 'RI', text: 'Rhode Island' },
  { value: 'SC', text: 'South Carolina' },
  { value: 'SD', text: 'South Dakota' },
  { value: 'TN', text: 'Tennessee' },
  { value: 'TX', text: 'Texas' },
  { value: 'UT', text: 'Utah' },
  { value: 'VA', text: 'Virginia' },
  { value: 'VT', text: 'Vermont' },
  { value: 'WA', text: 'Washington' },
  { value: 'WI', text: 'Wisconsin' },
  { value: 'WV', text: 'West Virginia' },
  { value: 'WY', text: 'Wyoming' },
]

/**
 *
 * Determine if the step "phone verification" should be shown or not.
 *
 * This also influences the checkout widget, since it should only
 * show taxes after the users has submitted theirs address.
 */
export const showStepPhoneVerification = (
  features: Features,
  loginUrl?: string,
  cart?: Cart,
) => {
  return Boolean(
    features.enablePhoneVerification && showStepCreateUser(loginUrl, cart),
  )
}

/**
 * Split a telephone number from Hong Kong into two parts: country prefix + the rest of the number
 */
export const hongKongCountryPrefixes = ['+852', '+853', '+86']

export const splitHongKongPhoneNumber = (input = '') => {
  // Remove any characters that are not a plus sign or a digit. Replace leading zeroes with a + sign.
  const cleanPhoneNumber = input.replace(/[^+\d]/g, '').replace(/^00/, '+')

  // Create RegExp: [(prefix)(only digits)]
  const regex = new RegExp(
    `^(${hongKongCountryPrefixes.map((i) => '\\' + i).join('|')})(\\d*)`,
  )
  const split = regex.exec(cleanPhoneNumber)

  return {
    countryPrefix: split?.[1] || '',
    phoneNumber: typeof split?.[2] === 'string' ? split?.[2] : cleanPhoneNumber,
  }
}
