import {
  Typography,
  TypographyProps,
} from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'

export interface Props extends TypographyProps {
  error: FieldError
  maxLength?: number
  minLength?: number
}

export const FieldsError: React.FC<Props> = ({
  error,
  maxLength,
  minLength,
  className,
  ...rest
}) => {
  const intl = useIntl()
  const classes = useStyles()

  const renderError = () => {
    switch (error.type) {
      case 'required':
        return intl.formatMessage({
          defaultMessage: 'This field is required',
          id: 'fields.error.required',
        })

      case 'minLength':
        return intl.formatMessage(
          {
            defaultMessage: 'The minimum allowed length is {minLength}',
            id: 'fields.error.minLength',
          },
          { minLength },
        )

      case 'maxLength':
        return intl.formatMessage(
          {
            defaultMessage: 'The maximum allowed length is {maxLength}',
            id: 'fields.error.maxLength',
          },
          { maxLength },
        )

      case 'email':
        return intl.formatMessage({
          defaultMessage: 'This email address is not valid',
          id: 'fields.error.email',
        })

      case 'phoneNumber':
        return intl.formatMessage({
          defaultMessage: 'This is not a valid phone number',
          id: 'fields.error.phoneNumber',
        })

      case 'postalCode':
        return intl.formatMessage({
          id: 'fields.error.postalCode',
          defaultMessage: 'This is not a valid postal code',
        })

      case 'illegalCharacters':
        return intl.formatMessage({
          defaultMessage: 'This field has characters that are not allowed',
          id: 'fields.error.illegalCharacters',
        })

      case 'password':
        return intl.formatMessage({
          defaultMessage: 'This password does not meet the requirements',
          id: 'fields.error.password',
        })

      default:
        return error.message || ''
    }
  }

  return (
    <Typography
      className={clsx(className, classes.root)}
      color="error"
      dangerouslySetInnerHTML={{
        __html: renderError(),
      }}
      fontWeight="bold"
      variant="caption"
      {...rest}
    />
  )
}

export default FieldsError

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& a': {
        color: 'inherit',
        textDecoration: 'underline',
      },
      '& a:hover': {
        textDecoration: 'none',
      },
    },
  }),
  { name: 'FieldsErrorComponent' },
)
