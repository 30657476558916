import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

import { Button, ButtonProps } from '../button'

export interface Props extends Omit<ButtonProps, 'icon'> {
  color?: 'default' | 'primary' | 'primary-main' | 'white' | 'black'
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      lineHeight: 0,
      padding: theme.spacing(),
    },

    'color-default': {},

    'color-white': {
      color: theme.palette.common.white,
      fill: theme.palette.common.white,

      '&:hover': {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
      },
    },

    'color-black': {
      color: theme.palette.common.black,
      fill: theme.palette.common.black,

      '&:hover': {
        color: theme.palette.common.black,
        fill: theme.palette.common.black,
      },
    },

    'color-primary': {
      color: theme.palette.text.primary,
      fill: theme.palette.text.primary,

      '&:hover': {
        color: theme.palette.text.primary,
        fill: theme.palette.text.primary,
      },
    },

    'color-brand-primary': {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,

      '&:hover': {
        color: theme.palette.text.primary,
        fill: theme.palette.text.primary,
      },
    },

    'color-brand-dark': {
      color: theme.palette.primary.dark,
      fill: theme.palette.primary.dark,

      '&:hover': {
        color: theme.palette.text.primary,
        fill: theme.palette.text.primary,
      },
    },
  }),
  { name: 'IconButton' },
)

export const IconButton: React.FC<Props> = ({
  children,
  className,
  color = 'default',
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Button
      className={clsx(
        classes.root,
        {
          [classes[`color-${color}`]]: color !== 'default',
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default IconButton
