import { Cart, LineItemMode } from '@danone-global/ct/interfaces'
import LineItem from '@danone-global/internal/react/components/line-item'
import { Grid } from '@danone-global/internal/react/components/ui'
import {
  filterGiftLineItem,
  Theme,
  useCore,
} from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',

        // Hide everything except the first 4 elements
        '& div:nth-child(n+5)': {
          display: 'none',
        },
      },
    },
  }),
  { name: 'CheckoutLineItems' },
)

/* istanbul ignore next */
export const CheckoutLineItems = ({ cart }: { cart: Cart }) => {
  const { features } = useCore()
  const classes = useStyles()

  return (
    <Grid className={classes.root} direction="column" container>
      {cart?.lineItems
        .filter(filterGiftLineItem(features))
        .sort((l) => (l.lineItemMode === LineItemMode.GiftLineItem ? 0 : -1))
        .map((lineItem) => (
          <LineItem key={lineItem.id} lineItem={lineItem} variant="checkout" />
        ))}
    </Grid>
  )
}

export default CheckoutLineItems
