import log from './order-deepink.log'

export const TAG = 'order-deeplink'

const renderDeeplinkWidget = ({
  skusString = undefined,
  orderCompleted = undefined,
  redirecturl = undefined,
}: {
  skusString?: string
  orderCompleted?: boolean
  redirecturl?: string
}) => {
  const div = document.createElement('div')
  div.setAttribute('data-dtc-component', TAG)

  if (skusString) {
    div.setAttribute('data-prop-skus', skusString)
  }
  if (redirecturl) {
    div.setAttribute('data-prop-redirecturl', redirecturl)
  }

  if (orderCompleted) {
    div.setAttribute('data-prop-completed', 'true')
  }

  document.body.appendChild(div)
}

/**
 * Adds the correct dtc-component to the body when deep-linking skus are found
 */
export const onRegister = () => {
  const skusString = new URLSearchParams(window.location.search).get('dtcSkus')
  const redirecturl =
    new URLSearchParams(window.location.search).get('redirecturl') || ''
  const idcOrder = new URLSearchParams(window.location.search).get('idc')

  if (sessionStorage.getItem('redirecturl') === null || redirecturl !== '') {
    sessionStorage.setItem('redirecturl', redirecturl)
  } 
  if (skusString) {
    log(`Found "${skusString}" skus for deeplink`)

    // Render the widget
    renderDeeplinkWidget({
      skusString,
    })
  } else if (idcOrder === '1') {
    log('Found idc, rendering thank you screen')

    // Render the widget
    renderDeeplinkWidget({
      orderCompleted: true,
    })
  }
}
