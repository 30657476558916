import type { Widget } from '@danone-global/internal/react/core'
import React from 'react'

import { onRegister, TAG } from './order-deeplink.on-register'

export default {
  tags: [TAG],

  Component: React.lazy(() => import('./order-deeplink.widget')),

  onRegister,
} as Widget
