import type { Widget } from '@danone-global/internal/react/core'
import React from 'react'

export default {
  tags: ['variant-editor'],
  developmentProps: {
    sku: '53141,79754,79771,79749,FOM01',
    channel: 'uk',
  },

  Component: React.lazy(() => import('./variant-editor.widget')),
} as Widget
