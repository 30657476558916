import { Typography } from '@danone-global/internal/react/components/ui'
import { Theme } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { Control, FieldPathValue, useController } from 'react-hook-form'
import { Validate } from 'react-hook-form/dist/types/validator'

import FieldsError from '../error'

export interface Props extends Partial<Pick<HTMLInputElement, 'type'>> {
  name: string

  label?: string

  control: Control<any>

  required?: boolean

  placeholder?: string

  className?: string

  defaultValue?: string

  minLength?: number

  maxLength?: number

  rows?: number

  autoComplete?: string

  validate?:
    | Validate<FieldPathValue<any, any>>
    | Record<string, Validate<FieldPathValue<any, any>>>

  hidden?: boolean

  disabled?: boolean

  showCounter?: boolean
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      marginBottom: theme.spacing(),

      '& label': {
        display: 'block',
        marginBottom: theme.spacing(),
      },
    },

    labelText: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    input: {
      position: 'relative',
      width: '100%',
      height: 'auto',
      minWidth: 'auto',
      border: '1px solid rgba(151, 151, 152, 0.25)',
      padding: theme.spacing(),
      fontSize: theme.utils.getSize(16),
      fontWeight: theme.typography.fontWeightLight,
      fontFamily: theme.typography.fontFamily,
      outline: 'none',
      transition: 'all 200ms ease-out',
      borderRadius: theme.shape.borderRadius(),
      boxShadow: 'inset 1px 1px 1px 0px rgb(0 0 0 / 10%)',

      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },

    invalid: {
      borderColor: theme.palette.error.main,
    },

    valid: {},

    hidden: {
      display: 'none',
    },

    counter: {
      margin: 0,
      color: theme.palette.text.primary,
    },
  }),
  { name: 'FieldsInput' },
)

export const FieldsInput: React.FC<Props> = ({
  name,
  label,
  control,
  required = false,
  placeholder,
  className,
  defaultValue = '',
  minLength,
  maxLength,
  rows = 1,
  validate,
  hidden,
  showCounter = false,
  ...inputProps
}) => {
  const classes = useStyles()
  const { field: input, fieldState } = useController({
    name,
    control,
    rules: {
      required,
      minLength,
      maxLength,
      validate,
    },
    defaultValue,
  })

  const Component = rows > 1 ? 'textarea' : 'input'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    input.onChange({ ...e, target: { ...e.target, value: e.target.value } });
  };

  return (
    <div
      className={clsx(classes.root, hidden && classes.hidden, className)}
      data-testid={`field-${name}`}
    >
      {label ? (
        <label htmlFor={name}>
          <Typography
            className={classes.labelText}
            component="span"
            variant="h5"
          >
            <span>
              {label}
              {required && <span> *</span>}
            </span>

            {showCounter && maxLength ? (
              <span className={classes.counter}>
                {input?.value?.length || 0}/{maxLength}
              </span>
            ) : undefined}
          </Typography>
        </label>
      ) : undefined}

      <div>
        <Component
          {...input}
          {...inputProps}
          className={clsx(
            classes.input,
            fieldState.error && classes.invalid,
            fieldState.isTouched && !fieldState.invalid && classes.valid,
          )}
          data-testid={`input-${name}`}
          id={name}
          name={name}
          placeholder={placeholder}
          rows={rows}
          style={rows > 0 ? { resize: 'vertical' } : {}}
          onChange={handleChange} 
          value={input.value || ''}
        />
      </div>

      {fieldState.error && (
        <FieldsError
          data-testid={`error-field-${name}.${fieldState.error.type}`}
          error={fieldState.error}
          maxLength={maxLength}
          minLength={minLength}
        />
      )}
    </div>
  )
}

export default FieldsInput
