import { useQuery } from '@apollo/client'
import {
  InventoryEntry,
  LineItem as CtLineItem,
  LineItemMaster,
  LineItemMode,
  Maybe,
  Product,
  ProductVariantAvailabilityWithChannel,
} from '@danone-global/ct/interfaces'
import LineItem, {
  isProductAvailableForSubscription,
} from '@danone-global/internal/react/components/line-item'
import {
  Button,
  Container,
  DeliveryDate,
  Grid,
  Hr,
  Typography,
} from '@danone-global/internal/react/components/ui'
import {
  CHECK_INVENTORY,
  filterGiftLineItem,
  GET_PRODUCTS_AVAILABILITY,
  PACKAGE_PRODUCT_KEY,
  Theme,
  useCart,
  useCore,
  useFromCustom,
} from '@danone-global/internal/react/core'
import {
  ChannelAvailabilityWithPublish,
  getProductChannelsAvailability,
  useChannelId,
} from '@danone-global/internal/react/modules/stock'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import AddDiscountCode from './add-discount-code'
import DiscountCodePrice from './discount-code-price'
import { DiscountInterface } from './discount-interface'
import { getDiscounts, getDiscountsNotMatching } from './my-order.hooks'
import ShippingPrice from './shipping-price'
import SubtotalPrice from './subtotal-price'
import TaxDisclaimer from './tax-disclaimer'
import TaxPortion from './tax-portion'
import TotalPrice from './total-price'
export interface Props {
  goToCheckout?: () => void
  variant?: 'default' | 'checkout'
}

export const useStyles = makeStyles<
  Theme,
  { hidePrices: boolean; variant: string }
>(
  (theme: Theme) => ({
    root: {
      marginBottom: theme.spacing(2),
      border: 'none',
      backgroundColor: theme.palette.background.secondary,
      wordBreak: 'break-word',

      [theme.breakpoints.down('md')]: {
        backgroundColor: ({ hidePrices, variant }) =>
          hidePrices && variant === 'default' ? 'transparent' : null,
      },

      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 0, 2, 0),
      },
    },

    value: {
      paddingRight: theme.spacing(2),
    },

    actions: {
      marginTop: ({ hidePrices }) => (hidePrices ? 0 : theme.spacing(4)),

      '& button': {
        flex: 1,
        justifyContent: 'center',
      },

      [theme.breakpoints.down('md')]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        width: '100%',
        zIndex: 1001,
        boxShadow: '0px -8px 16px 0px rgba(0,0,0,0.1)',
      },
    },

    actionsHidePrices: {
      marginTop: 0,
    },

    deliveryDate: {
      marginBottom: theme.spacing(1),
      '& h4': {
        marginBottom: 20,
      },

      '& .deliveryDate': {
        marginBottom: theme.spacing(2),
      },
    },

    checkoutDisabled: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'MyOrder' },
)

export const MyOrder: React.FC<Props> = ({
  goToCheckout,
  variant = 'default',
}) => {
  const {
    features,
    config: { siteName, deliveryCategory },
    localeConfig
  } = useCore()
  const [cart] = useCart()

  /* istanbul ignore next */
  const lineItems = [...(cart?.lineItems || [])]
    .filter(filterGiftLineItem(features))
    .sort((l) => (l.lineItemMode === LineItemMode.GiftLineItem ? 0 : -1))
    .sort((l) => (!isProductAvailableForSubscription(l) ? 1 : 0))

  const deliveryDate = useFromCustom<string>(
    cart?.custom?.customFieldsRaw ?? [],
    'deliveryDate',
  )
  const discountCodes = cart ? getDiscounts(cart) : []

  // Discount codes can be valid, but not applicable to the cart. They have to be removed before the user can continue.
  const discountsNotMatching = getDiscountsNotMatching(cart)

  const classes = useStyles({ variant, hidePrices: !!features.hidePrices })

  // check line item availability
  // check if any skus with package product type
  const skus =
    cart?.lineItems
      .filter((lineItem) => lineItem?.productType?.key !== PACKAGE_PRODUCT_KEY)
      .map((lineItem) => lineItem?.variant?.sku) || []

  const productIds =
    cart?.lineItems.map((lineItem) => lineItem?.productId) || []

  // Sites that excludes the outOfStock scenarios
  const sites = ['uk-medical-uk', 'uk-am', 'uk-medical-ie', 'uk-cg']
  const siteNotAvailable = sites.includes(siteName)

  const { data: productInventory } = useQuery(CHECK_INVENTORY, {
    variables: { where: `sku in (${skus.map((i) => `"${i}"`).join(', ')})` },
    skip: skus.length <= 0,
  })

  const { data: productsAvailableData } = useQuery(GET_PRODUCTS_AVAILABILITY, {
    variables: {
      where: `(id in (${productIds
        .map((productId) => `"${productId}"`)
        .join(',')}))`,
      limit: 50,
      locale: localeConfig.locale
    },
    skip: productIds.length <= 0,
  })

  const availabilities = productsAvailableData?.products?.results || []

  let productList: ChannelAvailabilityWithPublish

  const productChannelListArray: ChannelAvailabilityWithPublish[] = []

  let isPackageProduct

  const unAvailableItems: boolean[] = []
  const channelId = useChannelId()
  {
    lineItems.map((lineItem) => {
      isPackageProduct =
        availabilities?.find(
          (i: Product) =>
            i.masterData?.current?.masterVariant?.sku === lineItem.variant?.sku,
        )?.productType?.key === PACKAGE_PRODUCT_KEY
      const qty: number = lineItem?.quantity
      if (!isPackageProduct) {
        productList = getProductChannelsAvailability(
          availabilities,
          lineItem,
          channelId,
        )
        if (productList?.availableQuantity < qty) {
          unAvailableItems.push(true)
        }
        productChannelListArray.push(productList)
      }
    })
  }

  const unPublished = productChannelListArray?.some(
    (item) => item?.isPublished === false,
  )

  const OOS =
    !siteNotAvailable &&
    productChannelListArray?.some((item) => item?.isOnStock === false)

  const unAvailableQuantity = !siteNotAvailable && unAvailableItems.length > 0

  const outOfStockSouvenaid =
    siteName === 'us-souvenaid' &&
    productInventory?.inventoryEntries?.results?.findIndex(
      (item: InventoryEntry) => item.availableQuantity === 0,
    ) >= 0

  // Without a checkout button and price summary, there is nothing to show
  if (features.hidePrices && !goToCheckout) {
    return null
  }

  return (
    <Container className={classes.root} direction="column">
      {features.hidePrices ? undefined : (
        <>
          {deliveryCategory && deliveryDate && (
            <div className={classes.deliveryDate}>
              <Typography variant="h4" noGutters>
                <FormattedMessage
                  defaultMessage="Delivery date"
                  id="delivery-date.title"
                />
              </Typography>
              <DeliveryDate
                date={deliveryDate}
                deliveryCategory={deliveryCategory}
              />
            </div>
          )}

          {variant !== 'checkout' && (
            <Typography variant="h4" noGutters>
              <FormattedMessage defaultMessage="My Order" id="my-order.title" />
            </Typography>
          )}

          {variant !== 'checkout' && <AddDiscountCode />}

          <SubtotalPrice valueClassName={classes.value} />

          {discountCodes.map((discountCode) => (
            <DiscountCodePrice
              key={discountCode.id}
              currencyCode={cart?.totalPrice.currencyCode}
              fractionDigits={cart?.totalPrice.fractionDigits ?? 2}
              valueClassName={classes.value}
              {...discountCode}
            />
          ))}

          {discountsNotMatching?.map((discountCodeInfo, index) => (
            <DiscountCodePrice
              key={index}
              currencyCode={cart?.totalPrice.currencyCode}
              fractionDigits={cart?.totalPrice.fractionDigits ?? 2}
              valueClassName={classes.value}
              {...(discountCodeInfo.discountCode as unknown as DiscountInterface)}
              centAmount={0}
              discountCodeId={discountCodeInfo?.discountCode?.id || ''}
              notMatching={true}
            />
          ))}

          <ShippingPrice
            shipping={cart?.shippingInfo}
            valueClassName={classes.value}
          />

          <Hr />

          {features.showTaxDuringCheckout ? (
            <Grid direction="row" flex>
              <Grid flex>
                <Typography color="primary-dark" variant="body2" noGutters>
                  <FormattedMessage defaultMessage="Tax" id="my-order.tax" />
                </Typography>
              </Grid>

              <Grid className={classes.value}>
                <Typography
                  color="primary-dark"
                  component="span"
                  variant="body2"
                  noGutters
                >
                  <FormattedMessage defaultMessage="TBD" id="my-order.tbd" />
                </Typography>
              </Grid>
            </Grid>
          ) : undefined}

          {cart && (
            <TotalPrice
              price={
                cart.taxedPrice ? cart.taxedPrice.totalGross : cart.totalPrice
              }
              valueClassName={classes.value}
            />
          )}

          {!features.disableTaxInMyOrder &&
            cart?.taxedPrice?.taxPortions.map((taxPortion) => (
              <TaxPortion
                key={taxPortion.name}
                amount={taxPortion.amount}
                name={taxPortion.name}
                rate={taxPortion.rate}
              />
            ))}

          <TaxDisclaimer />
        </>
      )}
      {goToCheckout ? (
        <Grid className={classes.actions} direction="column" flex>
          {outOfStockSouvenaid ? (
            <Typography
              className={classes.checkoutDisabled}
              variant="h4"
              noGutters
            >
              <FormattedMessage
                defaultMessage="One or more of the products in your cart is out of stock so we are not able to proceed to the checkout"
                id="widgets.shopping-cart-container.cart-container.out-of-stock-message"
              />
            </Typography>
          ) : undefined}
          <Button
            data-testid="btn.go-to-checkout"
            disabled={
              outOfStockSouvenaid ||
              !!discountsNotMatching?.length ||
              unPublished ||
              OOS ||
              unAvailableQuantity
            }
            onClick={goToCheckout}
            variant="brand"
          >
            <FormattedMessage
              defaultMessage="Proceed to checkout"
              id="widgets.shopping-cart-container.cart-container.continue-checkout"
            />
          </Button>
        </Grid>
      ) : undefined}
    </Container>
  )
}

export default MyOrder
