import Price from '@danone-global/internal/react/components/price'
import { Grid, Typography } from '@danone-global/internal/react/components/ui'
import { calculateSubTotal, useCart } from '@danone-global/internal/react/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export interface Props {
  valueClassName: string
}

export const SubtotalPrice: React.FC<Props> = ({ valueClassName }) => {
  const [cart] = useCart()

  const subtotal = cart ? calculateSubTotal(cart) : undefined

  return (
    <Grid direction="row" flex>
      <Grid flex>
        <Typography color="primary-dark" variant="body2" noGutters>
          <FormattedMessage defaultMessage="Subtotal" id="my-order.subtotal" />
        </Typography>
      </Grid>

      <Grid className={valueClassName}>
        <Typography
          color="primary-dark"
          component="span"
          variant="body2"
          noGutters
        >
          {subtotal && (
            <Price
              centAmount={subtotal.centAmount}
              currencyCode={subtotal.currencyCode}
              fractionDigits={subtotal.fractionDigits}
              testId="my-order.subtotal-price"
              inherit
            />
          )}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default SubtotalPrice
