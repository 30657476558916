import {
  LineItem as CtLineItem,
  LineItemMaster,
  ProductVariantAvailabilityWithChannel,
} from '@danone-global/ct/interfaces'

export interface ChannelAvailability {
  id: string

  availableQuantity: number

  available: boolean

  isOnStock: boolean
}

export interface ChannelAvailabilityWithPublish {
  id: string

  availableQuantity: number

  available: boolean

  isOnStock: boolean

  isPublished: boolean
}

/**
 * Returns one channel availability, if preferred channel is defined then that one is returned
 */
export const getChannelAvailability = (
  channels: ProductVariantAvailabilityWithChannel[],
  preferredChannel: string = null,
): ChannelAvailability => {
  return channels.reduce(
    (
      resultChannel: ChannelAvailability,
      { channel, availability }: ProductVariantAvailabilityWithChannel,
    ): ChannelAvailability => {
      if (!preferredChannel || preferredChannel === channel.id) {
        if (
          resultChannel.available ||
          resultChannel.isOnStock ||
          (preferredChannel && preferredChannel !== channel.id)
        ) {
          return resultChannel
        }

        return {
          id: channel.id,
          available: availability.availableQuantity > 0,
          availableQuantity: availability.availableQuantity,
          isOnStock: availability.isOnStock,
        }
      }

      return resultChannel
    },
    {
      id: null,
      availableQuantity: 0,
      available: false,
      isOnStock: false,
    },
  )
}

/**
 * Returns channel availability for each lineItems
 */
export const getProductChannelsAvailability = (
  availabilities,
  lineItem: CtLineItem,
  channelId: string,
) => {
  let channelAvailibility = {
    id: '',
    availableQuantity: 0,
    available: false,
    isOnStock: false,
    isPublished: false,
  }
  availabilities?.map((item: LineItemMaster) => {
    const isMasterSku =
      item?.masterData?.current?.masterVariant.sku === lineItem.variant?.sku
    let channelResults: ProductVariantAvailabilityWithChannel[] = []

    if (!isMasterSku) {
      item?.masterData?.current?.variants.map((variant) => {
        if (variant.sku === lineItem.variant?.sku) {
          channelResults = variant?.availability?.channels?.results
        }
      })
    } else {
      channelResults =
        item?.masterData?.current?.masterVariant?.availability?.channels
          ?.results
    }
    if (channelResults.length > 0) {
      channelAvailibility = {
        ...getChannelAvailability(channelResults, channelId),
        isPublished: item?.masterData?.published,
      }
    }
  })
  
  return channelAvailibility
}
