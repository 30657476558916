import type { Widget } from '@danone-global/internal/react/core'
import React from 'react'

export default {
  tags: ['price-channel-selector-v2'],

  developmentProps: {
    sku: 'FOM01',
  },

  Component: React.lazy(() => import('./price-channel-selector.widget')),
} as Widget
