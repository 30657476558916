import React from 'react'

import Icon, { Props } from '../icon'

export const MinIcon: React.FC<Omit<Props, 'viewBox'>> = (props) => (
  <Icon viewBox="0 0 15 15" {...props}>
    <path d="M1.5 6h12c.8 0 1.5.7 1.5 1.5S14.3 9 13.5 9h-12C.7 9 0 8.3 0 7.5S.7 6 1.5 6z" />
  </Icon>
)

export default MinIcon
