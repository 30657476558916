import * as Sentry from '@sentry/react'

export const getCurrencySymbol = (
  locale: string,
  currencyCode: string,
): string => {
  try {
    return (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim()
  } catch (err) {
    Sentry.captureException(err)
  }

  return currencyCode
}
